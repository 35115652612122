import { ResourceConfig } from '../../../types';

export const PERSONAL_INFO_RESOURCE_NAME = 'personal-info';

export interface Address {
  readonly line_1: string;
  readonly line_2: string;
  readonly postal_code: string;
  readonly city: string;
  readonly country: string;
  readonly state: string;
}

export interface PersonResponse {
  id: string;
  salutation: string;
  first_name: string;
  last_name: string;
  email: string;
  address?: Address;
  personId: string;
}

export interface PersonNumber {
  number: string;
  id: string;
  verified?: boolean;
}

const config: ResourceConfig = {
  resourceName: PERSONAL_INFO_RESOURCE_NAME,
  endpoints: {
    create: {
      path: '/v1/persons',
      httpMethod: 'post',
    },
    updatePhoneNumber: {
      path: '/v1/persons/{person_id}/mobile_number',
      httpMethod: 'post',
    },
  },
};

export default config;
