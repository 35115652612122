import * as React from 'react';

class UnloadHandler extends React.Component<{}, {}> {
  componentDidMount() {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  handleBeforeUnload = (e: BeforeUnloadEvent) => {
    if (process.env.NODE_ENV === 'development') {
      console.warn('before unload warning message');
      return e;
    }
    const confirmationMessage = '-';
    (e || window.event).returnValue = confirmationMessage;
    return confirmationMessage;
  };

  public render(): null {
    return null;
  }
}

export default UnloadHandler;
