import { put, take, select, call } from 'redux-saga/effects';
import { arrayPush, reset } from 'redux-form';
import { SET_SHAREHOLDER_ACTION } from '../../modalShareholder';
import { navigateTo } from '../../../sagas/navigate';
import { selectIsLegalRepsBeneficialOwner, selectPersons } from '../../../selectors';
import { randomId } from '../../../lib/utils';

export function* checkAnyBeneficialOwnersSelected(hasShareholder: boolean) {
  const anyBeneficialOwnersSelected = yield select(selectIsLegalRepsBeneficialOwner);

  if (!anyBeneficialOwnersSelected) {
    yield put(reset('beneficialOwner'));
    if (!hasShareholder) {
      const persons = yield select(selectPersons) || {};
      const personsIds = Object.keys(persons);
      for (let i = 0; i < personsIds.length; i++) {
        yield put(
          arrayPush('beneficialOwner', 'otherBeneficialOwners', {
            referenced: personsIds[i],
          }),
        );
      }
    } else {
      yield put(
        arrayPush('beneficialOwner', 'otherBeneficialOwners', {
          id: randomId(),
        }),
      );
    }
  }
  yield call(navigateTo, 'beneficial-owner');
}

export function* watchSetShareholderAction() {
  while (true) {
    const { payload } = yield take(SET_SHAREHOLDER_ACTION);
    yield call(checkAnyBeneficialOwnersSelected, payload.hasShareholder);
  }
}
