import * as React from 'react';
import * as classnames from 'classnames';
import './decorated-header.css';

type HeaderType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export interface DecoratedHeaderProps {
  level: HeaderType;
  className?: string;
  children?: React.ReactNode;
}

const DecoratedHeader: React.StatelessComponent<DecoratedHeaderProps> = ({ className, level, children }) => {
  const Element = level;
  return <Element className={classnames('decorated-header', className)}>{children}</Element>;
};

export default DecoratedHeader;
