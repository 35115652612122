import moment, { Moment } from 'moment';
import { ResourceConfig } from '../../../types';
export const BENEFICIAL_OWNER_RESOURCE_NAME = 'beneficial-owner';
export const BENEFICIAL_OWNER_FORM = 'beneficialOwner';
export const BENEFICIAL_OWNER_FIELD_ARRAY = 'otherBeneficialOwners';

export interface BeneficialOwnerCreate {
  businessId: string;
  personId: string;
  votingShare: string;
}

export interface BeneficialOwner {
  readonly id: string;
  readonly beneficial_owner_id: string;
  readonly person_id: string;
  readonly valid_until: string;
  readonly voting_share: string;
  readonly partner_id: string;
}

export const minBirthDate: Moment = moment('01.01.1900', 'DD.MM.YYYY');
export const maxBirthDate: Moment = moment().subtract(18, 'years');

const beneficialOwnerResourceConfig: ResourceConfig = {
  resourceName: BENEFICIAL_OWNER_RESOURCE_NAME,
  endpoints: {
    create: {
      path: '/v1/businesses/{businessId}/beneficial_owners',
      httpMethod: 'post',
    },
  },
};

export default beneficialOwnerResourceConfig;
