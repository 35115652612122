import { ResourceConfig } from '../../../types';
export const LEGAL_REPRESENTATIVE_RESOURCE_NAME = 'legal-representative';
export const LEGAL_REPRESENTATIVE_FORM = 'legalRepresentative';
export const LEGAL_REPRESENTATIVE_ARRAY_FIELD = 'otherLegalRepresentatives';

export interface LegalRepresentative {
  readonly id: string;
  readonly legal_representative_id: string;
  readonly legal_representative_type: string;
  readonly valid_until: string;
  readonly business_id?: string;
  readonly account_id?: string;
}

export interface LegalRepresentativeCreate {
  readonly businessId: string;
  readonly legal_representative_id: string;
  readonly legal_representative_type?: string;
}

export interface LegalRepresentativePresenter extends LegalRepresentative {
  name: string;
}

const legalrepresentativeForResourceConfig: ResourceConfig = {
  resourceName: LEGAL_REPRESENTATIVE_RESOURCE_NAME,
  endpoints: {
    create: {
      path: '/v1/businesses/{id}/legal_representatives',
      httpMethod: 'post',
    },
  },
};

export default legalrepresentativeForResourceConfig;
