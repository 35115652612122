import * as React from 'react';
import { Field, FormProps, reduxForm } from 'redux-form';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { ReviewFormProps } from '../../../types';
import FormInputWrap from '../../habitats/form-input';
import Checkbox from '../../atoms/checkbox';
import { requiredValidation } from '../../../lib/validation';
import { REVIEW_FORM_NAME } from '../../../modules/review/models/review';

interface ReviewPageProps extends FormProps<ReviewFormProps, {}, {}> {}

class ReviewForm extends React.PureComponent<ReviewPageProps & InjectedIntlProps, {}> {
  public render(): JSX.Element {
    const {
      intl: { formatMessage },
    } = this.props;
    return (
      <div>
        <FormInputWrap>
          <Field
            name="agreement"
            label={formatMessage({ id: 'pages.review.form.agreement' })}
            component={Checkbox}
            validate={requiredValidation}
          />
        </FormInputWrap>
      </div>
    );
  }
}

export default reduxForm({
  form: REVIEW_FORM_NAME,
  destroyOnUnmount: false,
  onSubmit: () => {
    return;
  },
})(injectIntl(ReviewForm));
