import { connect } from 'react-redux';
import { submit, formValueSelector } from 'redux-form';
import { AppState } from '../../../types';
import BusinessLegalRepresentativePersons from '../components/business-legal-representative-persons';
import { selectClientPerson, selectHasBusinessAsLegalRepresentative } from '../../../selectors';

const businessFormSelector = formValueSelector('businessInfo');

const mapStateToProps = (state: AppState) => ({
  client: selectClientPerson(state),
  hasBusinessAsLegalRep: selectHasBusinessAsLegalRepresentative(state),
  business_name: businessFormSelector(state, 'name'),
});

export const mapDispatchToProps = {
  submit,
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessLegalRepresentativePersons);
