import * as React from 'react';
import * as classnames from 'classnames';

import './form-input.css';

interface FormInputHabitatProps {
  vertical?: boolean;
  nospace?: boolean;
  short?: boolean;
}

const formInputHabitat: React.StatelessComponent<FormInputHabitatProps> = ({ children, vertical, nospace, short }) => {
  return (
    <div
      className={classnames('form-input', {
        'form-input--inline': !vertical,
        'form-input--vertical': vertical,
        'form-input--nospace': nospace,
        'form-input--short': short,
      })}
    >
      {children}
    </div>
  );
};

export default formInputHabitat;
