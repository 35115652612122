import * as React from 'react';
import * as classnames from 'classnames';

export interface DisplayInputProps {
  label: string;
  value?: string;
  short?: boolean;
}

const DisplayInput: React.StatelessComponent<DisplayInputProps> = ({ label, value, short }) => (
  <div
    className={classnames('field field--valid field--disabled', {
      'field--short': short,
    })}
  >
    <label className="field__label">{label}</label>
    <div className="field__control">
      <div className="field__wrap">
        <div className="input">{value}</div>
      </div>
    </div>
  </div>
);

export default DisplayInput;
