import { ModalAction, ModalActionParams, UI_SHOW_MODAL_ACTION, UI_HIDE_MODAL_ACTION } from './modals-actions';

export interface ModalState {
  readonly type?: string;
  readonly props: {};
}

const DEFAULT_STATE: ModalState = {
  type: undefined,
  props: {},
};

export const modals = (state: ModalState = DEFAULT_STATE, action: ModalAction<ModalActionParams>): ModalState => {
  switch (action.type) {
    case UI_SHOW_MODAL_ACTION:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        type: action.payload.type,
        props: action.payload.props,
      };

    case UI_HIDE_MODAL_ACTION:
      return DEFAULT_STATE;

    default:
      return state;
  }
};
