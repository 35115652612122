import * as React from 'react';
import PageHabitat from '../../patterns/habitats/page';
import HeaderHabitat from '../../patterns/habitats/header';
import ContainerHabitat from '../../patterns/habitats/container';
import ContentHabitat from '../../patterns/habitats/content';
import FooterHabitat from '../../patterns/habitats/footer';
import Footer from '../../patterns/molecules/footer';

export interface PageProps {
  header?: JSX.Element;
  startAgain?: boolean;
}

class Page extends React.Component<PageProps, {}> {
  static defaultProps = {
    startAgain: true,
  };
  public render(): JSX.Element {
    const { children, header, startAgain } = this.props;
    return (
      <PageHabitat>
        <HeaderHabitat>
          <ContainerHabitat>{header}</ContainerHabitat>
        </HeaderHabitat>
        <ContainerHabitat>
          <ContentHabitat>{children}</ContentHabitat>
        </ContainerHabitat>
        <FooterHabitat>
          <ContainerHabitat>
            <Footer startAgain={startAgain} />
          </ContainerHabitat>
        </FooterHabitat>
      </PageHabitat>
    );
  }
}

export default Page;
