import * as React from 'react';
import './spinner.css';

export interface SpinnerProps {}

const Spinner: React.StatelessComponent<SpinnerProps> = () => (
  <div className="spinner">
    <div className="spinner__double-bounce1" />
    <div className="spinner__double-bounce2" />
  </div>
);

export default Spinner;
