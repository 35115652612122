import * as React from 'react';
import * as classnames from 'classnames';
import './radio-tabs.css';

interface RadioTabsProps {
  tabs: Array<string>;
  active: number;
  onClick: (index: number) => void;
}

const RadioTabs: React.StatelessComponent<RadioTabsProps> = ({ tabs, active, onClick }) => {
  return (
    <div className="radio-tabs">
      {tabs.map((t, i) => (
        <div
          className={classnames('radio-tabs__tab', {
            'radio-tabs__tab--active': active === i,
          })}
          onClick={() => onClick(i)}
          key={t}
        >
          {t}
        </div>
      ))}
    </div>
  );
};

export default RadioTabs;
