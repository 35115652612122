import { ModuleConfig } from './../../models/module';
import ReviewPageContainer from './containers/review-page-container';
import { REVIEW_RESOURCE_NAME } from './models/review';

import { watchReviewSubmit } from './sagas/review';

export const config: ModuleConfig = {
  moduleName: REVIEW_RESOURCE_NAME,
  requires: [],
  redux: {},
  listenerSagas: [watchReviewSubmit],
  routes: {
    review: {
      path: 'review',
      component: ReviewPageContainer,
    },
  },
};
