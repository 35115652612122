import { Dictionary } from './../../types';
import { ModuleConfig } from './../../models/module';
import { StandardAction } from '../../types';
import { RoutesConfig } from '../../routes-config';

export const MODULES_SET_ROUTES_ACTION = 'modules/SET-ROUTES';

export interface ModuleActionParams {
  modules: Dictionary<ModuleConfig>;
  routes: RoutesConfig;
}

export type ModuleAction<T> = StandardAction<T>;

export interface RoutesSetAction extends ModuleAction<ModuleActionParams> {
  readonly type: typeof MODULES_SET_ROUTES_ACTION;
}

export function setRoutes(params: ModuleActionParams): RoutesSetAction {
  return {
    type: MODULES_SET_ROUTES_ACTION,
    payload: {
      modules: params.modules,
      routes: params.routes,
    },
  };
}
