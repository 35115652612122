// tslint:disable:max-line-length
const pages = {
  'pages.next_step': 'Next step',
  'pages.start_again': 'Start again',
  'pages.edit': 'Edit',
  'pages.back': 'Back',
  'pages.remove': 'Remove',
  'pages.finish': 'Finish',
  'pages.please_select': '- Please Select -',
  'pages.see_example': 'see example',
  'pages.hide_example': 'hide example',
  'pages.your_business.title': 'The business',
  'pages.your_business.content':
    'The legal entity which eventually becomes our contractual partner. Please submit the information as in your official register.',
  'pages.form_failure.title': 'Something went wrong',
  'pages.form_failure.subtitle': 'We couldn’t verify your identity.',
  'pages.form_failure.content': 'Please try again or contact us at ',
  'pages.form_failure.try_again': 'Try again',
  'pages.form_success.title': 'Congratulations!',
  'pages.form_success.subtitle': 'You’ve finished your personal identification successfully.',
  'pages.form_success.content': 'Your contact at solarisBank will reach out to your soon to discuss next steps.',
  'pages.form_success.cta': 'Visit solarisBank.com',
  'pages.legal_representative.title': 'Legal Representatives',
  'pages.legal_representative.content':
    'A "Managing Director", "CEO" or "Executive" is someone who is legally responsible of the business and acts on its behalf.',
  'pages.legal_representative.point1':
    'Please add all your Legal Representatives - regardless whether the person(s) has sole and joint representation rights',
  'pages.legal_representative.point2': 'Please enter the data as your official documents states',
  'pages.legal_representative.point3':
    'Persons who have power of attorney ("Prokura" or similar titles) don\'t belong in here',
  'pages.legal_representative.point4': 'This person may or may not be a shareholder',
  'pages.legal_representative.legal_representatives_faq': 'FAQ | Legal Representatives',
  'pages.personal_info.title': 'You',
  'pages.personal_info.content': 'As a legal representative you will be our main contact.',
  'pages.review.title': 'Overview',
  'pages.review.content':
    'You see all the data you have entered during the process so far. Please bear in mind that we need your information as your official documents state them (yours and business’ as well as all legal representatives and beneficial owners).',
  'pages.review.form.title': 'Before launching the video identification, please review and confirm your answers',
  'pages.review.form.agreement':
    'I hereby confirm that the information below is complete and correct. If this information changes, I will inform solarisBank immediately thereof and, if necessary, provide solarisBank with supporting documentation.',
  'pages.review.form.your_business': 'Your business',
  'pages.review.form.founded_on': 'founded on {date}',
  'pages.review.form.industry': 'Industry',
  'pages.review.form.registration_number': 'Registration number',
  'pages.review.form.industry_key': 'Industry key',
  'pages.review.form.registration_issuer': 'Registration issuer',
  'pages.review.form.business_purpose': 'Business purpose',
  'pages.review.form.you': 'You',
  'pages.review.form.legal_representatives': 'Legal representatives',
  'pages.review.form.beneficial_owners': 'Beneficial owners',
  'pages.review.form.percentage_of_shares': 'Percentage of shares',
  'pages.review.form.date_of_birth': 'Date of birth',
  'pages.welcome.title': 'Welcome to Your Identification Process',
  'pages.welcome.subtitle':
    'This process guides you through the so called “Know-Your-Customer” requirement. It is a mandatory step of onboarding. Before we start, please make sure that you:',
  'pages.welcome.checks1': 'Are a representative of the business and have its register information',
  'pages.welcome.checks2': 'Have your ID or passport by the hand as well as a device with a camera',
  'pages.welcome.checks3': 'Have a mobile phone',
  'pages.welcome.sidebar.title': 'Continue at any time',
  'pages.welcome.sidebar.content':
    'You can stop this process at any point and come back later. All data will be saved for you.',
  'pages.beneficial_owner.form_title': '{first_name}, tell us about Beneficial Owners of your business',
  'pages.beneficial_owner.has_shareholder':
    'The company have at least one shareholder that owns more than 25% of the company.',
  'pages.beneficial_owner.has_no_shareholder':
    'The company doesn’t have single shareholder that owns more than 25% of the company.',
  'pages.beneficial_owner.legal_representative_set_as_beneficial_owner':
    'Legal Representative was indicated as Beneficial Owner because there is no single shareholder that owns more than 25% of the company',
  'pages.beneficial_owner.change_answer': 'Change answer',
  'pages.beneficial_owner.sidebar.title': 'Beneficial owners',
  'pages.beneficial_owner.sidebar.content1':
    '"Beneficial Owner" is the person who owns directly or indirectly more than 25% of the company. Stated by law, this is always the eventual natural person - so it can\'t be another legal entity.',
  'pages.beneficial_owner.sidebar.content2':
    'If there is no party at all, which owns directly or indirectly more than 25% of the company, you would be asked to submit the Legal Representatives instead.',
  'pages.beneficial_owner.sidebar.point1': 'Please enter all current persons who qualify as beneficial owners',
  'pages.beneficial_owner.sidebar.point2': 'In case of different share-types, please refer to the voting shares',
  'pages.beneficial_owner.sidebar.point3':
    "If a company would qualify as beneficial owner you would need to submit the information of this companies' owner. If there is another holding company you would need to go up the ladder until you find one.",
  'pages.beneficial_owner.sidebar.point4':
    'Listed or public companies can be treated differently, so please get in contact with us.',
  'pages.beneficial_owner.sidebar.read_full_definition': 'Legal Definition | Beneficial Owners',
  'pages.beneficial_owner.sidebar.faq_link': 'FAQ | Beneficial Owners',
  'pages.preloader.heading': 'Your data is being processed',
  'pages.preloader.text':
    'Please do not close this tab until the process finishes. This can take up to 2 minutes, so please be patient.',
  'pages.identification.header_text':
    'Once you have completed the video identification, you may close this window. You will receive an e-mail with further instructions if necessary.',
  'pages.business_legal_representative.alert':
    'You said, your business is a GmbH & Co. KG. Therefore, please provide here the information of the GmbH (Legal representative of the KG).',
  'pages.business_legal_representative.sidebar.title': 'Legal representation of {business_name}',
  'pages.business_legal_representative.sidebar.content1':
    'Your business is a GmbH & Co. KG. This means, that instead of natural persons (humans) another legal person is legally representing the company (a GmbH as “Komplementär”). Therefore we need here also the information on the GmbH.',
  'pages.business_legal_representative.sidebar.content2':
    'Please provide the information on the GmbH as in the company register provided.',
  'pages.business_legal_representative.sidebar.content3':
    'The legal representatives (natural persons) of the GmbH will be collected hereafter.',
  'pages.business_legal_representative_persons.sidebar.title': 'Legal representation of {business_name}',
  'pages.business_legal_representative_persons.sidebar.content':
    'Your business is a GmbH & Co. KG. This means, that instead of natural persons (humans) another legal person is legally representing the company (a GmbH as “Komplementär”). Therefore we need here also the information on the GmbH’s legal representatives.',
  'pages.business_legal_representative_persons.sidebar.point1':
    'Please add all your Legal Representatives – regardless whether the person(s) has sole or joint representation rights',
  'pages.business_legal_representative_persons.sidebar.point2':
    'Please enter the data as your official documents states',
  'pages.business_legal_representative_persons.sidebar.point3':
    'Persons who have power of attorneys (“Prokura” or similar titles) don’t belong in here',
  'pages.business_legal_representative_persons.sidebar.point4': 'This person may or may not be a shareholder',
  'pages.shareholder_modal.title':
    'Does the company have at least one shareholder who owns more than 25% of the company?',
  'pages.shareholder_modal.button': 'Save',
  'pages.shareholder_modal.option_yes': 'Yes, it does',
  'pages.shareholder_modal.option_no': "No, it doesn't",
  'pages.footer.imprint': 'Imprint',
  'pages.footer.imprint_url': 'https://www.solarisbank.com/en/imprint/',
  'pages.footer.terms': 'Terms of Use',
  'pages.footer.terms_url': 'https://www.solarisbank.com/en/terms-of-use/',
  'pages.footer.privacy_policy': 'Privacy Policy',
  'pages.footer.privacy_policy_url': 'https://www.solarisbank.com/en/privacy-policy/',
  'pages.footer.redo': 'Start Again',
  'pages.footer.need_help': 'Need help? Reach us at: ',
};

export default pages;
