import * as React from 'react';
import { InjectedIntlProps, injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col, Grid } from 'react-flexbox-grid';
import Page from '../../../../components/page';
import Button from '../../../../patterns/atoms/button';
import Header from '../../../../patterns/molecules/header';
import TickList from '../../../../patterns/molecules/tick-list';
import DecoratedHeader from '../../../../patterns/atoms/decorated-header';
import LoginForm from '../../../../patterns/molecules/login-form/login-form';
import { ServerErrorResponse } from '../../../../types';

interface WelcomeProps {
  onLoginSubmit: () => void;
  errors?: ServerErrorResponse;
  loggedIn?: boolean;
}

const WelcomePage: React.FunctionComponent<WelcomeProps & InjectedIntlProps> = props => {
  const {
    errors,
    loggedIn,
    onLoginSubmit,
    intl: { formatMessage },
  } = props;

  return (
    <Page
      header={
        <Header
          sidebar={
            loggedIn && (
              <Button variant="primary" fullWidth={true} href="personal-info">
                <FormattedMessage id="pages.next_step" />
              </Button>
            )
          }
        />
      }
      startAgain={false}
    >
      <Grid fluid={true}>
        <Row>
          <Col md={2} />
          <Col md={6}>
            <DecoratedHeader level="h1">
              <FormattedMessage id="pages.welcome.title" />
            </DecoratedHeader>
            {loggedIn ? (
              <>
                <h3>
                  <FormattedMessage id="pages.welcome.subtitle" />
                </h3>
                <TickList
                  items={[
                    formatMessage({ id: 'pages.welcome.checks1' }),
                    formatMessage({ id: 'pages.welcome.checks2' }),
                    formatMessage({ id: 'pages.welcome.checks3' }),
                  ]}
                />
              </>
            ) : (
              <LoginForm onSubmit={onLoginSubmit} errors={errors} />
            )}
          </Col>
        </Row>
      </Grid>
    </Page>
  );
};

export default injectIntl(WelcomePage);
