/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { hashHistory } from 'react-router';
import ModalHeaderText from '../../atoms/modal-header-text';
import { ReactComponent as CloseIcon } from '../../atoms/icons/close.svg';
import { Row, Col } from 'react-flexbox-grid';
import './modal-header.css';

interface ModalHeaderProps {
  title: string;
  subtitle?: string;
}

const ModalHeader: React.StatelessComponent<ModalHeaderProps> = ({ title, subtitle }) => {
  return (
    <Row middle="xs" className="modal-header">
      <Col xs={6} xsOffset={2}>
        <ModalHeaderText title={title} subtitle={subtitle} />
      </Col>
      <Col xs={3} xsOffset={1} className="modal-header__aside">
        <div className="modal-header__close">
          <a onClick={hashHistory.goBack}>
            <CloseIcon />
            Close
          </a>
        </div>
      </Col>
    </Row>
  );
};

export default ModalHeader;
