import * as React from 'react';
import PageHabitat from '../../patterns/habitats/page';
import HeaderHabitat from '../../patterns/habitats/header';
import ContainerHabitat from '../../patterns/habitats/container';

export interface FrameProps {
  header?: JSX.Element;
}

class Frame extends React.PureComponent<FrameProps, {}> {
  public render(): JSX.Element {
    const { children } = this.props;
    return (
      <PageHabitat>
        <HeaderHabitat>
          <ContainerHabitat>{this.props.header}</ContainerHabitat>
        </HeaderHabitat>
        {children}
      </PageHabitat>
    );
  }
}

export default Frame;
