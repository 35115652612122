import { ModuleConfig } from './../../models/module';
import Identification from './containers/identification';
import { IDENTIFICATION_RESOURCE_NAME } from './models/identification';

export const config: ModuleConfig = {
  moduleName: IDENTIFICATION_RESOURCE_NAME,
  requires: [],
  redux: {},
  listenerSagas: [],
  routes: {
    identification: {
      path: 'identification',
      component: Identification,
    },
  },
};
