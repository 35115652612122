import { ServerErrorResponse } from '../../../types';
import { LOGIN_FAILED, LOGIN_SUCCEEDED, LoginFailedAction, LoginSuceededAction } from './login-actions';

export interface LoginState {
  errors?: ServerErrorResponse;
  loggedIn?: boolean;
}

export const loginDefaultState: LoginState = {
  errors: undefined,
  loggedIn: undefined,
};

export const login = (state = loginDefaultState, action: LoginFailedAction | LoginSuceededAction) => {
  switch (action.type) {
    case LOGIN_FAILED:
      return {
        ...state,
        errors: action.payload,
      };
    case LOGIN_SUCCEEDED:
      return {
        ...state,
        loggedIn: true,
      };
    default:
      return state;
  }
};
