import * as React from 'react';
import * as cx from 'classnames';
import { InjectedIntlProps, injectIntl, FormattedMessage } from 'react-intl';
import './steps.css';
import { ReactComponent as TickIcon } from '../../atoms/icons/done.svg';
import { ReactComponent as TimesIcon } from '../../atoms/icons/fail.svg';

interface StepsProps {
  steps?: Array<string>;
  active: number;
  failed?: number;
}

const Steps: React.StatelessComponent<StepsProps & InjectedIntlProps> = ({ steps = [], active, failed }) => {
  return (
    <div className="steps">
      {steps.map((name: string, i: number) => (
        <div
          key={name}
          className={cx('step', {
            'step--active': i === active,
            'step--done': i < active,
            'step--failed': failed === i,
          })}
        >
          <div className="step__number">{i + 1}</div>
          <div className="step__indicator">
            {i < active && failed !== i && <TickIcon />}
            {failed === i && <TimesIcon />}
          </div>
          <div className="step__label">
            <FormattedMessage id={name} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default injectIntl(Steps);
