import { StandardAction, ResourceResponse, ServerErrorResponse, BusinessInfoFormProps } from '../../../types';
import { Business } from '../models/business-info';

export const CREATE_BUSINESS_ACTION = 'business/CREATE_BUSINESS_ACTION';
export const CREATE_BUSINESS_SUCCESS_ACTION = 'business/CREATE_BUSINESS_SUCCESS_ACTION';

export const BUSINESS_CREATE_FAILURE_ACTION = 'business/BUSINESS_CREATE_FAILURE_ACTION';

export type CreateBusinessActionType = StandardAction<BusinessInfoFormProps>;
export type CreateBusinessSuccess = StandardAction<ResourceResponse<Business>>;
export type BusinessFailure = StandardAction<ServerErrorResponse>;

export type BusinessCreateResponse = ResourceResponse<Business>;

export type BusinessActionParams = CreateBusinessSuccess | StandardAction<ServerErrorResponse>;

export function createBusiness(payload: BusinessInfoFormProps): CreateBusinessActionType {
  return {
    type: CREATE_BUSINESS_ACTION,
    payload,
  };
}

export function createBusinessSuccess(payload: ResourceResponse<Business>): StandardAction<BusinessCreateResponse> {
  return {
    type: CREATE_BUSINESS_SUCCESS_ACTION,
    payload,
  };
}

export function businessCreateRequestFailure(resource: ServerErrorResponse): StandardAction<ServerErrorResponse> {
  return {
    type: BUSINESS_CREATE_FAILURE_ACTION,
    payload: resource,
  };
}
