import * as React from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import moment from 'moment';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import BaseField from '../baseField';
import 'react-datepicker/dist/react-datepicker.module.css';
import './datePicker.css';

interface DatePickerProps extends ReactDatePickerProps {
  placeholder: string;
  input: WrappedFieldInputProps & {
    onChange: (date: moment.Moment) => moment.Moment;
    onBlur: (date: moment.Moment | null) => moment.Moment;
  };
  meta: WrappedFieldMetaProps<{}>;
}

const DatePicker: React.FunctionComponent<DatePickerProps> = props => {
  const {
    placeholder,
    input: { onChange, onBlur, name, value },
  } = props;

  return (
    <BaseField {...props}>
      <div className="datepicker-wrap">
        <ReactDatePicker
          name={name}
          placeholderText={placeholder}
          className="control active"
          selected={value ? value : null}
          onChange={onChange}
          onBlur={() => onBlur(value ? moment(value).toDate() : null)}
          {...props}
        />
      </div>
    </BaseField>
  );
};

export default DatePicker;
