import { StandardAction, ResourceResponse, ServerErrorResponse } from '../../../types';
import { BeneficialOwner, BeneficialOwnerCreate } from '../models/beneficial-owner';

export const CREATE_BENEFICIAL_OWNER_ACTION = 'beneficialOwner/CREATE_BENEFICIAL_OWNER_ACTION';
export const CREATE_BENEFICIAL_OWNER_SUCCESS_ACTION = 'beneficialOwner/CREATE_BENEFICIAL_OWNER_SUCCESS_ACTION';

export const CREATE_BENEFICIAL_OWNER_FAILURE_ACTION = 'beneficialOwner/CREATE_BENEFICIAL_OWNER_FAILURE_ACTION';

export type CreateBeneficialOwnerActionType = StandardAction<BeneficialOwnerCreate>;
export type CreateBeneficialOwnerSuccess = StandardAction<ResourceResponse<BeneficialOwner>>;
export type BusinessFailure = StandardAction<ServerErrorResponse>;

export type BeneficialOwnerCreateResponse = ResourceResponse<BeneficialOwner>;

export type BeneficialOwnerParams = CreateBeneficialOwnerSuccess | StandardAction<ServerErrorResponse>;

export function createBeneficialOwner(payload: BeneficialOwnerCreate): CreateBeneficialOwnerActionType {
  return {
    type: CREATE_BENEFICIAL_OWNER_ACTION,
    payload,
  };
}

export function createBeneficialOwnerSuccess(
  payload: BeneficialOwnerCreateResponse,
): StandardAction<BeneficialOwnerCreateResponse> {
  return {
    type: CREATE_BENEFICIAL_OWNER_SUCCESS_ACTION,
    payload,
  };
}

export function createBeneficialOwnerFailure(resource: ServerErrorResponse): StandardAction<ServerErrorResponse> {
  return {
    type: CREATE_BENEFICIAL_OWNER_FAILURE_ACTION,
    payload: resource,
  };
}
