import * as React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import PageHabitat from '../../patterns/habitats/page';
import HeaderHabitat from '../../patterns/habitats/header';
import ContainerHabitat from '../../patterns/habitats/container';
import ContentHabitat from '../../patterns/habitats/content';
import ModalHeader from '../../patterns/molecules/modal-header';
import ModalContentHabitat from '../../patterns/habitats/modal-content';

export interface FaqPageProps {
  title: string;
  subtitle?: string;
}

class FaqPage extends React.Component<FaqPageProps, {}> {
  public render(): JSX.Element {
    const { children, title, subtitle } = this.props;
    return (
      <PageHabitat>
        <HeaderHabitat>
          <ContainerHabitat>
            <ModalHeader title={title} subtitle={subtitle} />
          </ContainerHabitat>
        </HeaderHabitat>
        <ContainerHabitat>
          <ContentHabitat>
            <Row>
              <Col xs={6} xsOffset={2}>
                <ModalContentHabitat>{children}</ModalContentHabitat>
              </Col>
            </Row>
          </ContentHabitat>
        </ContainerHabitat>
      </PageHabitat>
    );
  }
}

export default FaqPage;
