import * as React from 'react';
import { InjectedIntlProps, injectIntl, FormattedMessage } from 'react-intl';

import FaqPage from '../../../../components/faq-page';
import UnloadHandler from '../../../../components/unload-handler';
import DecoratedHeader from '../../../../patterns/atoms/decorated-header';

interface InfoFaqLegalRepresentativeProps extends InjectedIntlProps {}

class InfoFaqLegalRepresentative extends React.PureComponent<InfoFaqLegalRepresentativeProps, {}> {
  public render(): JSX.Element {
    const { formatMessage } = this.props.intl;
    return (
      <FaqPage
        title={formatMessage({ id: 'faq_legal_representative.page_title' })}
        subtitle={formatMessage({
          id: 'faq_legal_representative.page_subtitle',
        })}
      >
        <DecoratedHeader level="h3">
          <FormattedMessage id="faq_legal_representative.section1.title" />
        </DecoratedHeader>
        <p>
          <FormattedMessage id="faq_legal_representative.section1.p1" />
        </p>
        <p>
          <FormattedMessage id="faq_legal_representative.section1.p2" />
        </p>
        <p>
          <FormattedMessage id="faq_legal_representative.section1.p3" />
        </p>
        <DecoratedHeader level="h3">
          <FormattedMessage id="faq_legal_representative.section2.title" />
        </DecoratedHeader>
        <p>
          <FormattedMessage id="faq_legal_representative.section2.p1" />
        </p>
        <DecoratedHeader level="h3">
          <FormattedMessage id="faq_legal_representative.section3.title" />
        </DecoratedHeader>
        <p>
          <FormattedMessage id="faq_legal_representative.section3.p1" />
        </p>
        <DecoratedHeader level="h3">
          <FormattedMessage id="faq_legal_representative.section5.title" />
        </DecoratedHeader>
        <p>
          <FormattedMessage id="faq_legal_representative.section5.p1" />
        </p>
        <DecoratedHeader level="h3">
          <FormattedMessage id="faq_legal_representative.section6.title" />
        </DecoratedHeader>
        <p>
          <FormattedMessage id="faq_legal_representative.section6.p1" />
        </p>
        <UnloadHandler />
      </FaqPage>
    );
  }
}

export default injectIntl(InfoFaqLegalRepresentative);
