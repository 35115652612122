import { arrayPush, arrayRemove, arrayInsert } from 'redux-form';
import { put, take, select, call } from 'redux-saga/effects';
import {
  selectPersons,
  selectLegalRepresentativesIds,
  selectBeneficialOwnersIds,
  selectHasShareholder,
} from '../../../selectors';
import { LEGAL_REPRESENTATIVE_FORM } from '../models/legal-representative';
import { BENEFICIAL_OWNER_FORM, BENEFICIAL_OWNER_FIELD_ARRAY } from '../../beneficialOnwer/models/beneficial-owner';
import { MODAL_TYPE_SHAREHOLDER } from '../../modalShareholder';
import { setShareholder } from '../../modalShareholder/redux/shareholder-actions';
import { showModal } from '../../modals';
import { navigateTo } from '../../../sagas/navigate';

export function* handleLegalRepresentativeSubmit(form: string) {
  if (form !== LEGAL_REPRESENTATIVE_FORM) {
    return;
  }
  const legalRepsAsBeneficialOwners = yield select(selectLegalRepresentativesIds);

  if (legalRepsAsBeneficialOwners.length > 0) {
    yield put(setShareholder(null));
  }

  const hasShareholder = yield select(selectHasShareholder);
  const currentBO = yield select(selectBeneficialOwnersIds);

  // if there is no single shareholder all LR should be indicated as Beneficial Owners
  const currentLR = hasShareholder === false ? Object.keys(yield select(selectPersons)) : legalRepsAsBeneficialOwners;

  const toRemove = currentBO.filter((ref: string) => !currentLR.includes(ref));
  const toCreate = currentLR.filter((ref: string) => !currentBO.includes(ref));

  for (let i = 0; i < toRemove.length; i++) {
    const id = toRemove[i];
    const indexToRemove = (yield select(selectBeneficialOwnersIds)).indexOf(id);
    yield put(arrayRemove(BENEFICIAL_OWNER_FORM, BENEFICIAL_OWNER_FIELD_ARRAY, indexToRemove));
  }

  for (let i = 0; i < toCreate.length; i++) {
    toCreate[i] === 'client'
      ? yield put(
          arrayInsert(BENEFICIAL_OWNER_FORM, BENEFICIAL_OWNER_FIELD_ARRAY, 0, {
            referenced: toCreate[i],
          }),
        )
      : yield put(
          arrayPush(BENEFICIAL_OWNER_FORM, BENEFICIAL_OWNER_FIELD_ARRAY, {
            referenced: toCreate[i],
          }),
        );
  }

  if (legalRepsAsBeneficialOwners.length === 0) {
    yield put(showModal(MODAL_TYPE_SHAREHOLDER, {}));
  } else {
    yield call(navigateTo, 'beneficial-owner');
  }
}

export function* watchSuccessfulFormSubmit() {
  while (true) {
    const {
      meta: { form },
    } = yield take('@@redux-form/SET_SUBMIT_SUCCEEDED');
    yield call(handleLegalRepresentativeSubmit, form);
  }
}
