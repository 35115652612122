import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { AppState } from '../../../types';
import LegalRepresentative from '../components/legal-representative';
import { selectClientPerson } from '../../../selectors';

const mapStateToProps = (state: AppState) => ({
  client: selectClientPerson(state),
});

export const mapDispatchToProps = {
  submit,
};

export default connect(mapStateToProps, mapDispatchToProps)(LegalRepresentative);
