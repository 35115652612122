import { ShareholderAction, ShareholderActionParams, SET_SHAREHOLDER_ACTION } from './shareholder-actions';

export interface ShareholderState {
  readonly hasShareholder: boolean | null;
}

const DEFAULT_STATE: ShareholderState = {
  hasShareholder: null,
};

export const shareholder = (
  state: ShareholderState = DEFAULT_STATE,
  action: ShareholderAction<ShareholderActionParams>,
): ShareholderState => {
  switch (action.type) {
    case SET_SHAREHOLDER_ACTION:
      return {
        hasShareholder: action.payload!.hasShareholder,
      };
    default:
      return state;
  }
};
