import FormFailure from '../components/form-failure';
import { connect } from 'react-redux';
import { AppState } from '../../../types';
import defaultSteps from '../../../models/steps';

export const mapDispatchToProps = {};
export const mapStateToProps = (state: AppState) => ({
  steps: defaultSteps,
  step: 5,
});

export default connect(mapStateToProps, mapDispatchToProps)(FormFailure);
