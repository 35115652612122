import { StandardAction } from '../../../types';

export const SET_SHAREHOLDER_ACTION = 'SET_SHAREHOLDER';

export type ShareholderActionParams = { hasShareholder: boolean | null };
export type ShareholderAction<T> = StandardAction<T>;

export interface SetShareholderAction extends ShareholderAction<ShareholderActionParams> {
  readonly type: typeof SET_SHAREHOLDER_ACTION;
}

export function setShareholder(hasShareholder: boolean | null): SetShareholderAction {
  return {
    type: SET_SHAREHOLDER_ACTION,
    payload: {
      hasShareholder,
    },
  };
}
