import { ModuleConfig } from '../../models/module';
import { LOGIN_RESOURCE_NAME } from './models/login';
import { login } from './redux/login-reducer';
import { listenToLogin } from './sagas/login';

export const config: ModuleConfig = {
  moduleName: LOGIN_RESOURCE_NAME,
  requires: [],
  redux: { reducer: { login } },
  listenerSagas: [listenToLogin],
  routes: {},
};
