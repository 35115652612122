import {
  CREATE_LEGAL_REPRESENTATIVE_ACTION,
  CREATE_LEGAL_REPRESENTATIVE_FAILURE_ACTION,
  CREATE_LEGAL_REPRESENTATIVE_SUCCESS_ACTION,
  CreateLegalRepresentativeSuccess,
  LegalRepresentativeParams,
} from './legal-representative-actions';

export const legalRepresentativeDefaultState = {
  legalRepresentatives: [],
};

export const legalRepresentative = (state = legalRepresentativeDefaultState, action: LegalRepresentativeParams) => {
  if (!action.payload) {
    return state;
  }
  switch (action.type) {
    case CREATE_LEGAL_REPRESENTATIVE_ACTION:
      return state;
    case CREATE_LEGAL_REPRESENTATIVE_SUCCESS_ACTION:
      action = action as CreateLegalRepresentativeSuccess;
      if (!action.payload!.data) {
        return state;
      }

      return {
        ...state,
        legalRepresentatives: [...(state.legalRepresentatives || []), action.payload!.data],
      };
    case CREATE_LEGAL_REPRESENTATIVE_FAILURE_ACTION:
      return {
        ...state,
        errors: action.payload,
      };
    default:
      return state;
  }
};
