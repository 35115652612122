import * as React from 'react';
import * as classnames from 'classnames';
import { WrappedFieldProps, WrappedFieldInputProps } from 'redux-form';
import SelectComponent, {
  FilterOptionHandler,
  OnInputChangeHandler,
  OptionRendererHandler,
  ValueRendererHandler,
} from 'react-select';

import 'react-select/dist/react-select.css';
import './select.css';

interface SelectProps extends WrappedFieldProps<{}> {
  name: string;
  className?: string;
  label?: string;
  id: string;
  placeholder?: string;
  theme?: string;
  size?: string;
  options: Array<{
    value: string;
  }>;
  valueKey?: string;
  labelKey?: string;
  onInputChange?: OnInputChangeHandler;
  filterOption?: FilterOptionHandler<string>;
  searchable?: boolean;
  selectedValue?: string;
  optionRenderer?: OptionRendererHandler<string>;
  valueRenderer?: ValueRendererHandler<string>;
  noForm?: boolean;
  selectOuterStyle?: string;
  clearable?: boolean;
  closeOnSelect?: boolean;
  optionClassName?: string;
  wide?: boolean;
  input: WrappedFieldInputProps;
}

export class Select extends React.PureComponent<SelectProps> {
  render() {
    const {
      className,
      label,
      id,
      placeholder,
      input: { onChange, onBlur, value, name },
      options = [],
      valueKey,
      labelKey,
      onInputChange,
      filterOption,
      searchable,
      optionRenderer,
      valueRenderer,
      meta: { error, warning, touched },
      selectOuterStyle,
      closeOnSelect,
      clearable,
      wide,
    } = this.props;
    return (
      <div
        className={classnames('select', className, {
          'select--wide': wide,
        })}
        data-name={name}
      >
        {label && (
          <label className="select__label" htmlFor={id}>
            {label}
          </label>
        )}

        <SelectComponent
          name={name}
          onChange={onChange}
          onBlur={() => onBlur(value)}
          className={classnames(selectOuterStyle, {
            'select__outer--error': touched && error,
          })}
          options={options}
          value={value}
          placeholder={placeholder}
          valueKey={valueKey}
          labelKey={labelKey}
          filterOption={filterOption}
          onInputChange={onInputChange}
          searchable={searchable}
          closeOnSelect={closeOnSelect}
          clearable={!!clearable}
          autosize={false}
          optionRenderer={optionRenderer}
          valueRenderer={valueRenderer}
        />

        {touched && (
          <div className="select__feedback">
            {error && <div className="select__error">{error}</div>}
            {warning && <div className="select__warning">{warning}</div>}
          </div>
        )}
      </div>
    );
  }
}

export default Select;
