import { ModuleConfig } from './../../models/module';
import BeneficialOwnerContainer from './containers/beneficial-owner-container';
import { BENEFICIAL_OWNER_RESOURCE_NAME } from './models/beneficial-owner';
import { watchCreateBeneficialOwner } from './sagas/beneficial-owner';
import { BeneficialOwner } from './redux/beneficial-owner-reducer';

export const config: ModuleConfig = {
  moduleName: BENEFICIAL_OWNER_RESOURCE_NAME,
  requires: [],
  redux: {
    reducer: {
      beneficialOwner: BeneficialOwner,
    },
  },
  listenerSagas: [watchCreateBeneficialOwner],
  routes: {
    'beneficial-owner': {
      path: 'beneficial-owner',
      component: BeneficialOwnerContainer,
    },
  },
};
