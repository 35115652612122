import { formValueSelector } from 'redux-form';
import { AppState, LegalRepresentativeProps } from '../../../types';
import { LEGAL_REPRESENTATIVE_FORM, LEGAL_REPRESENTATIVE_ARRAY_FIELD } from '../models/legal-representative';

const legalFormSelector = formValueSelector(LEGAL_REPRESENTATIVE_FORM);

export const selectLegalRepresentativesIds = (state: AppState) => {
  const clientIsABeneficialOwner = legalFormSelector(state, 'alsoBeneficialOwner');
  const legalRepresentatives = legalFormSelector(state, LEGAL_REPRESENTATIVE_ARRAY_FIELD) || [];

  const result = [
    ...legalRepresentatives
      .filter((lr: LegalRepresentativeProps) => lr.alsoBeneficialOwner)
      .map((lr: LegalRepresentativeProps) => lr.id),
  ];

  if (clientIsABeneficialOwner) {
    result.push('client');
  }

  return result;
};

export const selectIsLegalRepsBeneficialOwner = (state: AppState) => {
  return selectLegalRepresentativesIds(state).length > 0;
};
