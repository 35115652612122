import * as React from 'react';
import * as classnames from 'classnames';
import './sidebar.css';

interface SidebarProps {
  title?: string;
  content?: string;
  icon?: React.ReactNode;
  center?: boolean;
  children?: React.ReactNode;
}

const Sidebar: React.StatelessComponent<SidebarProps> = ({ title, content, children, icon, center }) => {
  return (
    <div className={classnames('sidebar', { 'sidebar--center': center })}>
      <div className="sidebar__icon">{icon}</div>
      <h4 className="sidebar__header">{title}</h4>
      <p className="sidebar__content">{content}</p>
      {children}
    </div>
  );
};

Sidebar.defaultProps = {
  center: false,
};

export default Sidebar;
