import { CreateBusinessSuccess } from '../../businessInfo/redux/business-info-actions';
import {
  CREATE_BUSINESS_LR_SUCCESS_ACTION,
  BUSINESS_LR_CREATE_FAILURE_ACTION,
  BusinessLegalRepActionParams,
} from '../redux/business-legal-representative-actions';

export const businessDefaultState = {};

export const business = (state = businessDefaultState, action: BusinessLegalRepActionParams) => {
  if (!action.payload) {
    return state;
  }
  switch (action.type) {
    case CREATE_BUSINESS_LR_SUCCESS_ACTION:
      action = action as CreateBusinessSuccess;
      if (!action.payload || !action.payload.data) {
        return state;
      }

      return {
        ...state,
        ...action.payload.data,
      };
    case BUSINESS_LR_CREATE_FAILURE_ACTION:
      return {
        errors: action.payload,
      };
    default:
      return state;
  }
};
