import { AppState } from '../../../types';
import { PersonIdentification } from '../../businessIdentification/models/businessIdentification';

import { selectPersonApiIds } from '../../../selectors';

export const selectIdNowUrl = (state: AppState) => {
  const apiIds = selectPersonApiIds(state);
  const clientId = 'client';
  const clientApiId = apiIds[clientId];
  const {
    businessIdentification: { legal_representatives },
  } = state;

  const identification = legal_representatives!.find((lr: PersonIdentification) => lr.person_id === clientApiId);

  return identification ? identification.identifications[0].url : '';
};
