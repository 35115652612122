import { StandardAction, ResourceResponse, ServerErrorResponse } from '../../../types';
import { LegalRepresentative, LegalRepresentativeCreate } from '../models/legal-representative';

export const CREATE_LEGAL_REPRESENTATIVE_ACTION = 'legalRepresentatative/CREATE_LEGAL_REPRESENTATIVE_ACTION';
export const CREATE_LEGAL_REPRESENTATIVE_SUCCESS_ACTION =
  'legalRepresentatative/CREATE_LEGAL_REPRESENTATIVE_SUCCESS_ACTION';

export const CREATE_LEGAL_REPRESENTATIVE_FAILURE_ACTION =
  'legalRepresentatative/CREATE_LEGAL_REPRESENTATIVE_FAILURE_ACTION';

export type CreateLegalRepresentativeActionType = StandardAction<LegalRepresentativeCreate>;
export type CreateLegalRepresentativeSuccess = StandardAction<ResourceResponse<LegalRepresentative>>;
export type BusinessFailure = StandardAction<ServerErrorResponse>;

export type LegalRepresentativeCreateResponse = ResourceResponse<LegalRepresentative>;

export type LegalRepresentativeParams = CreateLegalRepresentativeSuccess | StandardAction<ServerErrorResponse>;

export function createLegalRepresentative(payload: LegalRepresentativeCreate): CreateLegalRepresentativeActionType {
  return {
    type: CREATE_LEGAL_REPRESENTATIVE_ACTION,
    payload,
  };
}

export function createLegalRepresentativeSuccess(
  payload: LegalRepresentativeCreateResponse,
): StandardAction<LegalRepresentativeCreateResponse> {
  return {
    type: CREATE_LEGAL_REPRESENTATIVE_SUCCESS_ACTION,
    payload,
  };
}

export function createLegalRepresentativeFailure(resource: ServerErrorResponse): StandardAction<ServerErrorResponse> {
  return {
    type: CREATE_LEGAL_REPRESENTATIVE_FAILURE_ACTION,
    payload: resource,
  };
}
