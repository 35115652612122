import { ModuleConfig } from './../../models/module';
import BusinessInfoContainer from './containers/business-info-container';
import { BUSINESS_INFO_RESOURCE_NAME } from './models/business-info';
import { watchCreateBusiness } from './sagas/business-info';
import { watchFormChange } from './sagas/business-info-form';
import { business } from './redux/business-info-reducer';

export const config: ModuleConfig = {
  moduleName: BUSINESS_INFO_RESOURCE_NAME,
  requires: [],
  redux: {
    reducer: {
      business,
    },
  },
  listenerSagas: [watchCreateBusiness, watchFormChange],
  routes: {
    businessInfo: {
      path: 'business-info',
      component: BusinessInfoContainer,
    },
  },
};
