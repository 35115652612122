import { call, put, takeEvery, select } from 'redux-saga/effects';
import { getFormValues } from 'redux-form';
import {
  BeneficialOwnerCreateResponse,
  CreateBeneficialOwnerActionType,
  CREATE_BENEFICIAL_OWNER_ACTION,
  createBeneficialOwnerSuccess,
  createBeneficialOwnerFailure,
  createBeneficialOwner as createBeneficialOwnerAction,
} from '../redux/beneficial-owner-actions';
import BeneficialOwnerConfig from '../models/beneficial-owner';
import { resourceCreate } from '../../../sagas/resource-requests';
import { selectPersonApiIds, selectBusinessId } from '../../../selectors';
import { BeneficialOwnerProps } from '../../../types';

export function* createBeneficialOwner(action: CreateBeneficialOwnerActionType) {
  const { businessId, personId, votingShare } = action.payload!;
  const BeneficialOwnerResponse: BeneficialOwnerCreateResponse = yield call(
    resourceCreate,
    BeneficialOwnerConfig,
    {
      pathParams: {
        businessId,
      },
    },
    {
      person_id: personId,
      voting_share: votingShare,
    },
  );

  if (BeneficialOwnerResponse && BeneficialOwnerResponse.data) {
    yield put(createBeneficialOwnerSuccess(BeneficialOwnerResponse));
  } else if (BeneficialOwnerResponse.error) {
    const { apiErrors } = BeneficialOwnerResponse.error;
    const notUniqueErrorDetail = 'Could not save BeneficialOwner. ‘person name’ must be unique for BeneficialOwner.';

    if (apiErrors) {
      const notUniqueError = apiErrors.find(error => !!error.detail && error.detail.includes(notUniqueErrorDetail));
      if (notUniqueError) {
        return BeneficialOwnerResponse;
      }
    } else {
      yield put(createBeneficialOwnerFailure(BeneficialOwnerResponse.error));
      throw new Error('Beneficial Owner creation error');
    }
  }
  return BeneficialOwnerResponse;
}

export function* watchCreateBeneficialOwner() {
  yield takeEvery(CREATE_BENEFICIAL_OWNER_ACTION, createBeneficialOwner);
}

export function* createBeneficialOwnersFromForm() {
  const beneficialOwnersForm = yield select(getFormValues('beneficialOwner'));
  const beneficialOwnersFormData = beneficialOwnersForm.otherBeneficialOwners;
  const businessId = yield select(selectBusinessId);
  const apiIds = yield select(selectPersonApiIds);

  return yield beneficialOwnersFormData.map((bo: BeneficialOwnerProps) => {
    const personId = bo.referenced || bo.id;
    if (personId) {
      return call(
        createBeneficialOwner,
        createBeneficialOwnerAction({
          businessId,
          personId: apiIds[personId],
          votingShare: bo.percentageOfShares!,
        }),
      );
    } else {
      return null;
    }
  });
}
