import { ResourceConfig } from '../../../types';

export const LOGIN_RESOURCE_NAME = 'login';
export const LOGIN_FORM_NAME = 'login';
export const AUTH_TOKEN = 'TOKEN';
export const AUTHENTICATION_VALID = 'AUTHENTICATION_VALID';

export interface LoginResponsePayload {
  access_token: string;
  expires_in: number;
  token_type: string;
}

const loginResourceConfig: ResourceConfig = {
  resourceName: LOGIN_RESOURCE_NAME,
  endpoints: {
    create: {
      path: '/v1/authentication/login',
      httpMethod: 'post',
      internal: true,
    },
  },
};

export default loginResourceConfig;
