import { ModuleConfig } from './../../models/module';
import LegalRepresentativeContainer from './containers/legal-representative-container';
import { LEGAL_REPRESENTATIVE_RESOURCE_NAME } from './models/legal-representative';
import { watchSuccessfulFormSubmit } from './sagas/legalRepresentativeForm';
import { legalRepresentative } from './redux/legal-representative-reducer';

export const config: ModuleConfig = {
  moduleName: LEGAL_REPRESENTATIVE_RESOURCE_NAME,
  requires: [],
  redux: {
    reducer: {
      legalRepresentative,
    },
  },
  listenerSagas: [watchSuccessfulFormSubmit],
  routes: {
    legalRepresentative: {
      path: 'legal-representative',
      component: LegalRepresentativeContainer,
    },
  },
};
