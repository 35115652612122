import { Person, SbPerson, AddressProps, SbAddressProps } from '../types';
import { parseSalutationToParam } from '../lib/utils';

export function parsePersonToSbPerson(person: Person): SbPerson {
  const { first_name, last_name, salutation, email, dateOfBirth, address, city, country, postalCode } = person;
  return {
    salutation: parseSalutationToParam(salutation!.value),
    first_name,
    last_name,
    email,
    birth_date: transformDate(dateOfBirth),
    address:
      address && postalCode && city && country
        ? parseAddressToSbAddress({
            address,
            postalCode,
            city,
            country,
          })
        : null,
  };
}

export const transformDate = (date?: string) => {
  if (!date) return date;
  const dateObj = new Date(date);
  const utcDate = new Date(dateObj.getTime() - dateObj.getTimezoneOffset() * 60000);
  return utcDate.toISOString().split('T')[0];
};

export function parseAddressToSbAddress(address: AddressProps): SbAddressProps {
  return {
    line_1: address.address,
    city: address.city,
    country: address.country.value,
    postal_code: address.postalCode,
  };
}

export function createPersonObjectWithCustomKey(
  customKey: string,
  data: {
    person?: Person;
    address?: string;
    city?: string;
    country?: {
      label: string;
      value: string;
    };
    postalCode?: string;
  },
): {} {
  const { person, ...remainingData } = data;

  return {
    [customKey]: {
      ...remainingData,
      ...person,
    },
  };
}
