import * as React from 'react';
import { FormProps, FieldArray, reduxForm } from 'redux-form';
import { hashHistory } from 'react-router';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { BeneficialOwnerFormProps, Person, BeneficialOwnerProps } from '../../../types';
import {
  BENEFICIAL_OWNER_FORM,
  BENEFICIAL_OWNER_FIELD_ARRAY,
} from '../../../modules/beneficialOnwer/models/beneficial-owner';
import Remark from '../../atoms/remark';
import BeneficialFormArray from './beneficial-owner-form-array/beneficial-owner-from-array';
import './beneficial-owner-form.css';

interface BeneficialOwnerFProps extends FormProps<BeneficialOwnerFormProps, {}, {}> {
  persons: Array<Person>;
  noBeneficialOwner: boolean;
}

const language: string = navigator.language === 'de' ? 'de' : 'en';
const messages: Object = {
  en: {
    no_BO_defined: 'At least one beneficial owner should be defined',
    total_percentage_share_invalid: "Total percentage of shares can't be more than 100%",
  },
  de: {
    no_BO_defined: 'Ein wirtschaftlich Berechtigter muss angegeben werden',
    total_percentage_share_invalid: 'Die Gesammtmenge an Anteile darf nicht 100% überschreiten',
  },
};

const validate = (values: BeneficialOwnerFormProps) => {
  const errors: BeneficialOwnerFormProps = {};
  if (!values.otherBeneficialOwners || values.otherBeneficialOwners.length === 0) {
    errors._error = messages[language].no_BO_defined;
  } else if (
    values.otherBeneficialOwners
      .map((otb: BeneficialOwnerProps) => Number(otb.percentageOfShares))
      .reduce((sum: number, x: number) => sum + x, 0) > 100
  ) {
    errors._error = messages[language].total_percentage_share_invalid;
  }
  return errors;
};

class BeneficialOwnerForm extends React.PureComponent<BeneficialOwnerFProps & InjectedIntlProps, {}> {
  public render(): JSX.Element {
    const {
      persons,
      error,
      noBeneficialOwner,
      intl: { formatMessage },
    } = this.props;
    return (
      <div>
        {error && <strong>{error}</strong>}
        <FieldArray
          name={BENEFICIAL_OWNER_FIELD_ARRAY}
          component={BeneficialFormArray}
          props={{ persons, noBeneficialOwner }}
        />
        <Remark>{formatMessage({ id: 'forms.required_fields_note' })}</Remark>
      </div>
    );
  }
}

export default reduxForm({
  form: BENEFICIAL_OWNER_FORM,
  validate,
  destroyOnUnmount: false,
  enableReinitialize: true,
  onSubmit: () => {
    hashHistory.push('review');
  },
})(injectIntl(BeneficialOwnerForm));
