import * as React from 'react';
import { Row, Col, Grid } from 'react-flexbox-grid';
import { InjectedIntlProps, injectIntl, FormattedMessage } from 'react-intl';
import Page from '../../../../components/page';
import UnloadHandler from '../../../../components/unload-handler';
import Steps from '../../../../patterns/molecules/steps';
import Header from '../../../../patterns/molecules/header';
import PersonalInfoForm from '../../../../patterns/molecules/personal-info-form';
import VerticalDivider from '../../../../patterns/atoms/vertical-divider';
import Sidebar from '../../../../patterns/molecules/sidebar';
import Button from '../../../../patterns/atoms/button';
import { ReactComponent as BackIcon } from '../../../../patterns/atoms/icons/back.svg';
import defaultSteps from '../../../../models/steps';

import { ReactComponent as UserIcon } from '../../../../patterns/atoms/icons/user.svg';

interface PersonalInfoPageProps extends InjectedIntlProps {
  submit: Function;
}

class PersonalInfoPage extends React.PureComponent<PersonalInfoPageProps, {}> {
  submitForm = () => {
    this.props.submit('aboutYou');
  };

  public render(): JSX.Element {
    const {
      intl: { formatMessage },
    } = this.props;
    return (
      <Page
        header={
          <Header
            sidebar={
              <Button variant="primary" fullWidth={true} handleClick={this.submitForm}>
                <FormattedMessage id="pages.next_step" />
              </Button>
            }
          >
            <Steps active={0} steps={defaultSteps} />
          </Header>
        }
      >
        <Grid fluid={true}>
          <Row between="xs">
            <Col sm={2} xs={12}>
              <Button href="welcome" display="inline">
                <BackIcon />
                <FormattedMessage id="pages.back" />
              </Button>
            </Col>
            <Col sm={6} xs={12}>
              <h2>
                <FormattedMessage id="forms.personal_info.title" />
              </h2>
              <PersonalInfoForm
                initialValues={{
                  // TW - 27-03-2020
                  // The Person struct used to initialize this form has the fields below as required
                  // Therefore we have to initialize them with a blank value
                  // When time permits a new Interface should be implemented for this specific form
                  person: {
                    id: 'client',
                    first_name: '',
                    last_name: '',
                    email: '',
                  },
                }}
              />
            </Col>
            <Col sm={1} xs={12}>
              <VerticalDivider />
            </Col>
            <Col sm={3} xs={12}>
              <Sidebar
                title={formatMessage({ id: 'pages.personal_info.title' })}
                icon={<UserIcon />}
                content={formatMessage({ id: 'pages.personal_info.content' })}
              />
            </Col>
          </Row>
        </Grid>
        <UnloadHandler />
      </Page>
    );
  }
}

export default injectIntl(PersonalInfoPage);
