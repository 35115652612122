import { getFormValues } from 'redux-form';
import { BUSINESS_INFO_FORM_NAME } from '../models/business-info';
import { AppState, BusinessInfoFormProps } from '../../../types';
import { hasBusinessAsLegalRep } from '../models/business-rulebook';
import { legalForms } from '../../../lib/resource-enums';

export const selectBusinessInfoFormValues = getFormValues(BUSINESS_INFO_FORM_NAME);

export const selectBusinessId = (state: AppState) => state.business.id;

export const businessInfoFormValues = (state: AppState) => {
  const formValues: BusinessInfoFormProps = selectBusinessInfoFormValues(state);
  return formValues;
};

export const selectHasBusinessAsLegalRepresentative = (state: AppState) => {
  const formValues = businessInfoFormValues(state);
  const legalForm = formValues && formValues.legal_form && formValues.legal_form.value;
  return hasBusinessAsLegalRep(legalForm);
};

export const selectLegalFormRequiresRegistryInfo = (state: AppState) => {
  const formValues = businessInfoFormValues(state);
  const selectedLegalForm: string =
    formValues && formValues.legal_form && formValues.legal_form.value ? formValues.legal_form.value : '';
  // These legalforms are not entered in the commercial registry
  const blacklist: string[] = [legalForms.SOLE_PROPRIETORSHIP, legalForms.GBR];
  return blacklist.includes(selectedLegalForm) ? false : true;
};

export const selectTaxCountryIsDe = (state: AppState) => {
  const formValues = businessInfoFormValues(state);
  if (formValues) {
    const { tax_country } = formValues;
    return tax_country && tax_country.value === 'DE' ? true : false;
  }
  return false;
};
