import { BusinessIdentificationParams, BusinessIdentification } from '../models/businessIdentification';
import { ResourceResponse, ServerErrorResponse, StandardAction } from '../../../types';

export const CREATE_BUSINESS_IDENTIFICATION_ACTION = 'business/CREATE_BUSINESS_IDENTIFICATION_ACTION';
export const CREATE_BUSINESS_IDENTIFICATION_SUCCESS_ACTION = 'business/CREATE_BUSINESS_IDENTIFICATION_SUCCESS_ACTION';

export const CREATE_BUSINESS_IDENTIFICATION_FAILURE_ACTION = 'business/CREATE_BUSINESS_IDENTIFICATION_FAILURE_ACTION';

export type BusinessIdentificationCreateResponse = ResourceResponse<BusinessIdentification>;

export interface BusinessIdentificationCreate extends StandardAction<BusinessIdentificationParams> {
  readonly type: typeof CREATE_BUSINESS_IDENTIFICATION_ACTION;
}

export function createBusinessIdentification(payload: BusinessIdentificationParams): BusinessIdentificationCreate {
  return {
    type: CREATE_BUSINESS_IDENTIFICATION_ACTION,
    payload,
  };
}

export function createBusinessIdentificationSuccess(
  payload: BusinessIdentification,
): StandardAction<BusinessIdentification> {
  return {
    type: CREATE_BUSINESS_IDENTIFICATION_SUCCESS_ACTION,
    payload,
  };
}

export function createBusinessIdentificationFailure(
  resource: ServerErrorResponse,
): StandardAction<ServerErrorResponse> {
  return {
    type: CREATE_BUSINESS_IDENTIFICATION_FAILURE_ACTION,
    payload: resource,
  };
}
