import * as React from 'react';
import { InjectedIntlProps, injectIntl, FormattedMessage } from 'react-intl';
import { Person } from '../../../types';
import FormInputWrap from '../../habitats/form-input';
import FormFieldDivider from '../../atoms/formFieldDivider';
import FieldSide from '../../atoms/fieldSide';
import PersonIcon from '../../atoms/icons/person';

import DisplayInput from '../../atoms/display-input';

export interface PersonFormDisplayProps {
  isClient?: boolean;
  person: Person;
}

class PersonFormDisplay extends React.PureComponent<PersonFormDisplayProps & InjectedIntlProps, {}> {
  public render(): JSX.Element {
    const {
      intl: { formatMessage },
      person,
      isClient,
    } = this.props;
    return (
      <div>
        <FormInputWrap>
          <FieldSide column={true} input={true}>
            <PersonIcon variant={isClient ? null : 'default'} />
            {isClient && (
              <span>
                <FormattedMessage id="forms.legal_representative.you" />
              </span>
            )}
          </FieldSide>
          <DisplayInput
            label={formatMessage({ id: 'forms.personal_info.input_title' })}
            value={person.salutation ? person.salutation.label : ''}
            short={true}
          />
          <FormFieldDivider />
          <DisplayInput label={formatMessage({ id: 'forms.personal_info.first_name' })} value={person.first_name} />
        </FormInputWrap>
        <FormInputWrap>
          <DisplayInput label={formatMessage({ id: 'forms.personal_info.last_name' })} value={person.last_name} />
        </FormInputWrap>
      </div>
    );
  }
}

export default injectIntl(PersonFormDisplay);
