// tslint:disable:max-line-length
const faq = {
  'faq_legal_representative.page_title': 'Gesetzliche Vertreter',
  'faq_legal_representative.page_subtitle': 'Häufig gestellte Fragen',
  'faq_legal_representative.section1.title':
    'Die Gesellschaft, die ich eintragen werde, ist eine "Gesellschaft mit beschränkter Haftung" (GmbH) - wer ist mein gesetzlicher Vertreter?',
  'faq_legal_representative.section1.p1':
    'Der einfachste und sicherste Weg, das herauszufinden, ist, Ihren aktuellen Handelsregisterauszug zu überprüfen. Dort finden Sie alle offiziellen gesetzlichen Vertreter, die Sie uns mitteilen müssen.',
  'faq_legal_representative.section1.p2':
    'Als weiterer Hinweis müssen diese Personen auch im Impressum Ihrer Website aufgeführt sein.',
  'faq_legal_representative.section1.p3':
    'Achten Sie darauf, dass Sie nur die gesetzlichen Vertreter des Unternehmens einreichen, das zum Vertragspartner wird. Die handelnden Personen mögen die gleichen sein, aber sie würden im Namen eines anderen Unternehmens handeln.',
  'faq_legal_representative.section2.title':
    'Der gesetzliche Vertreter hat das Alleinvertretungsrecht - muss ich auch die anderen gesetzlichen Vertreter angeben?',
  'faq_legal_representative.section2.p1':
    'Ja, das müssen sie. Auch wenn dieser gesetzliche Vertreter in den nächsten Schritten den Prozesses allein im Namen des Unternehmens abschließen könnte, benötogen wir die vollständigen Informationen. Bitte geben Sie die Informationen über alle gesetzlichen Vertreter ein - Sie müssen nicht angeben, wer das Alleinvertretungsrecht hat',
  'faq_legal_representative.section3.title':
    'Einer unserer gesetzlichen Vertreter ist derzeit nicht erreichbar - müssen wir ihn erreichen, um den Prozess abzuschließen?',
  'faq_legal_representative.section3.p1':
    'Nein. Solange einer Ihrer gesetzlichen Vertreter verfügbar ist und die Informationen mit der Video-Identifikation übermitteln und verifizieren kann, reicht dies aus. Der gesetzliche Vertreter würde in diesem Fall seine eigenen Informationen z.B. mit ihrem Personalausweis oder Reisepass nachweisen.',
  'faq_legal_representative.section5.title':
    'Unsere gesetzlichen Vertreter stehen kurz vor dem Wechsel - soll ich die neuen bereits einbeziehen?',
  'faq_legal_representative.section5.p1':
    'Nein, bitte reichen Sie die Informationen so ein, wie sie derzeit im offiziellen Register eingetragen sind.',
  'faq_legal_representative.section6.title': 'Warum muss ich alle gesetzlichen Vertreter eintragen?',
  'faq_legal_representative.section6.p1':
    'Wir sind verpflichtet, die sich hier registrierenden Unternehmen geldwäscherechtlich zu prüfen und die Richtigkeit der Angaben sicherzustellen. Unser Ziel ist es, den Ablauf so reibungslos wie möglich zu gestalten, dennoch sind alle gesetzlichen Vertreter an den Informationspflichten beteiligt.',
};

export default faq;
