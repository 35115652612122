import * as React from 'react';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import BaseField from '../baseField';

interface InputProps {
  disabled: boolean;
  defaultValue: string;
  className: string;
  readOnly: boolean;
  label: string;
  placeholder: string;
  showlabel?: boolean;
  input: WrappedFieldInputProps;
  meta: WrappedFieldMetaProps<{}>;
  short?: boolean;
}

class Input extends React.PureComponent<InputProps> {
  render() {
    const {
      input,
      meta,
      disabled,
      defaultValue,
      className,
      readOnly,
      placeholder,
      showlabel,
      short,
      label,
      ...otherProps
    } = this.props;

    const { pristine, active } = meta;

    return (
      <BaseField {...this.props} showlabel={showlabel || !(pristine && !active)}>
        <input
          {...input}
          {...otherProps}
          disabled={disabled}
          readOnly={readOnly}
          placeholder={pristine && !active ? label : placeholder}
          value={input.value || defaultValue || ''}
        />
      </BaseField>
    );
  }
}

export default Input;
