import { take, call, put, select } from 'redux-saga/effects';
import {
  CREATE_BUSINESS_IDENTIFICATION_ACTION,
  createBusinessIdentificationSuccess,
  createBusinessIdentificationFailure,
  BusinessIdentificationCreateResponse,
} from '../redux/businessIdentification-actions';
import ResourceConfig, { BusinessIdentificationParams } from '../models/businessIdentification';
import { resourceCreate } from '../../../sagas/resource-requests';
import { selectBusinessId } from '../../../selectors';

export function* businessIdentificationListener() {
  while (true) {
    const { payload } = yield take(CREATE_BUSINESS_IDENTIFICATION_ACTION);
    yield call(createBusinessIdentification, payload);
  }
}

export function* createBusinessIdentification(payload: BusinessIdentificationParams) {
  try {
    const response: BusinessIdentificationCreateResponse = yield call(
      resourceCreate,
      ResourceConfig,
      {
        pathParams: {
          businessId: payload.business_id,
        },
      },
      {
        method: 'idnow',
      },
    );

    if (response && response.data) {
      yield put(createBusinessIdentificationSuccess(response.data));
    } else {
      yield put(createBusinessIdentificationFailure(response.error!));
    }
  } catch (error) {
    // tslint:disable-next-line:no-console
    console.error(error); // TODO error handler
  }
}

export function* createIdentificationFromForm() {
  const businessId = yield select(selectBusinessId);
  return yield call(createBusinessIdentification, { business_id: businessId });
}
