import { ModuleConfig } from './../../models/module';
import { BUSINESS_IDENTIFICATION_RESOURCE_NAME } from './models/businessIdentification';
import { businessIdentificationListener } from './sagas/businessIdentification';
import { businessIdentification } from './redux/businessIdentification-reducer';

export const config: ModuleConfig = {
  moduleName: BUSINESS_IDENTIFICATION_RESOURCE_NAME,
  requires: [],
  redux: {
    reducer: {
      businessIdentification,
    },
  },
  listenerSagas: [businessIdentificationListener],
  routes: {},
};
