import * as React from 'react';
import * as classnames from 'classnames';
import './person.css';
import { ReactComponent as PersonIcon } from '../icons/person.svg';

export interface PersonProps {
  label?: string;
  name?: string;
  address?: string;
  active?: boolean;
  children?: React.ReactNode;
}

const Person: React.StatelessComponent<PersonProps> = ({ label, name, address, active, children }) => (
  <div className={classnames('person', { 'person--active': active })}>
    <div className="person__label">
      <PersonIcon className="person__icon" />
      {label}
    </div>
    <div className="person__name">{name}</div>
    <div className="person__address">{address}</div>
    <div className="person__content">{children}</div>
  </div>
);

export default Person;
