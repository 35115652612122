import * as React from 'react';
import { InjectedIntlProps, injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col } from 'react-flexbox-grid';

import Page from '../../../../components/page';
import Button from '../../../../patterns/atoms/button';
import VerticalDivider from '../../../../patterns/atoms/vertical-divider';
import Header from '../../../../patterns/molecules/header';
import Sidebar from '../../../../patterns/molecules/sidebar';
import DecoratedHeader from '../../../../patterns/atoms/decorated-header';
import { ReactComponent as SuccessIcon } from '../../../../patterns/atoms/icons/success.svg';
import Steps from '../../../../patterns/molecules/steps';

interface FormSuccessProps extends InjectedIntlProps {
  steps: Array<string>;
  step: number;
}

class FormSuccess extends React.PureComponent<FormSuccessProps, {}> {
  public render(): JSX.Element {
    const { steps, step } = this.props;
    return (
      <Page
        header={
          <Header>
            <Steps steps={steps} active={step} />
          </Header>
        }
      >
        <Row>
          <Col xs={2} />
          <Col xs={6}>
            <DecoratedHeader level="h1">
              <FormattedMessage id="pages.form_success.title" />
            </DecoratedHeader>
            <h3>
              <FormattedMessage id="pages.form_success.subtitle" />
            </h3>
            <p>
              <FormattedMessage id="pages.form_success.content" />
            </p>
            <Button variant="primary">
              <FormattedMessage id="pages.form_success.cta" />
            </Button>
          </Col>
          <Col xs={1}>
            <VerticalDivider />
          </Col>
          <Col xs={3}>
            <Sidebar icon={<SuccessIcon />} center={true} />
          </Col>
        </Row>
      </Page>
    );
  }
}

export default injectIntl(FormSuccess);
