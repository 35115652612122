import * as React from 'react';
import './modal-header-text.css';

export interface ModalHeaderTextProps {
  title: string;
  subtitle?: string;
}

const ModalHeaderText: React.StatelessComponent<ModalHeaderTextProps> = ({ title, subtitle }) => (
  <div className="modal-header-text">
    <h1>{title}</h1>
    <p>{subtitle}</p>
  </div>
);

export default ModalHeaderText;
