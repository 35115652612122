import { ResourceConfig, BusinessInfoFormProps } from '../../../types';
import { getSector } from './business-rulebook';
import { transformDate } from '../../../lib/formatters';

export const BUSINESS_INFO_RESOURCE_NAME = 'business-info';
export const BUSINESS_INFO_FORM_NAME = 'businessInfo';

export interface Business {
  id: string;
  name: string;
  industry: string;
  industry_key: string;
  legal_form: string;
  foundation_date: string;
  tax_country: string;
  registration_number?: string;
  registration_issuer?: string;
  business_purpose: string;
}

export const formatBusinessObject = (data: BusinessInfoFormProps) => {
  const {
    business_purpose,
    website,
    registration_number,
    registration_issuer,
    german_registration_issuer,
    industry,
    industry_key,
    legal_form,
    tax_country,
    foundation_date,
    ...otherBusinessFormData
  } = data;

  return {
    ...otherBusinessFormData,
    foundation_date: transformDate(foundation_date),
    business_purpose: `${business_purpose} ${website}`,
    industry: industry!.value,
    industry_key: industry_key!.value,
    legal_form: legal_form!.value,
    sector: getSector(tax_country!.value, legal_form!.value),
    tax_information: {
      tax_country: tax_country!.value,
      registration_number: registration_number,
      registration_issuer: (german_registration_issuer && german_registration_issuer.value) || registration_issuer,
      tax_confirmation: 'SINGLE', // should be always same value, not present in form
    },
  };
};

const config: ResourceConfig = {
  resourceName: BUSINESS_INFO_RESOURCE_NAME,
  endpoints: {
    create: {
      path: '/v1/businesses',
      httpMethod: 'post',
    },
    update: {
      path: '/v1/businesses/{business_id}/force',
      httpMethod: 'patch',
    },
  },
};

export default config;
