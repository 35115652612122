import { CreateBusinessSuccess, BusinessActionParams } from './business-info-actions';
import { CREATE_BUSINESS_SUCCESS_ACTION, BUSINESS_CREATE_FAILURE_ACTION } from '../redux/business-info-actions';

export const businessDefaultState = {};

export const business = (state = businessDefaultState, action: BusinessActionParams) => {
  if (!action.payload) {
    return state;
  }
  switch (action.type) {
    case CREATE_BUSINESS_SUCCESS_ACTION:
      action = action as CreateBusinessSuccess;
      if (!action.payload || !action.payload.data) {
        return state;
      }

      return {
        ...state,
        ...action.payload.data,
      };
    case BUSINESS_CREATE_FAILURE_ACTION:
      return {
        errors: action.payload,
      };
    default:
      return state;
  }
};
