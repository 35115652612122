// tslint:disable:max-line-length
const faq = {
  'faq_beneficial_owner.page_title': 'Beneficial Owners',
  'faq_beneficial_owner.page_subtitle': 'Frequently Asked Questions',
  'faq_beneficial_owner.section1.title': 'What is a “Beneficial Owner”?',
  'faq_beneficial_owner.section1.p1':
    'A so called “Beneficial Owner” is a natural person (a human) who owns directly or indirectly more than 25% of the voting shares of a legal entity today. Ultimately it is the person who benefits from the entered agreement and has the eventual decision power. It can never be another company, as we would need to look up the owner of that company as well.',
  'faq_beneficial_owner.section1.p2':
    'There is a more detailed (legally-worded) definition here as well. It may especially help for cases with beneficial owners being entities incorporated as trusts or other non-commercial entity types: ',
  'faq_beneficial_onwer.section1.a': 'Legal Definition | Beneficial Owner',
  'faq_beneficial_owner.section2.title': 'The company has no beneficial owners – how should I proceed?',
  'faq_beneficial_owner.section2.p1':
    'It might be the case after thorough investigation that there is nobody who has directly or indirectly enough voting shares of the business. In that case we are required to take the legal representatives instead.',
  'faq_beneficial_owner.section2.p2': 'Please enter their information then accordingly.',
  'faq_beneficial_owner.section3.title': 'I don’t know the ownership structure of the company – how shall I proceed?',
  'faq_beneficial_owner.section3.p1':
    'Ultimately, the ownership structure of a company is determined in the share-holder-agreement (Gesellschaftervertrag) which has been signed at least when founding the entity. In the meantime, this may has changed but would need to noted in an amendment or update of this contract.',
  'faq_beneficial_owner.section4.title': 'The company is owned by another company – what shall I do now?',
  'faq_beneficial_owner.section4.p1':
    'Please submit the information of the natural person (human) owning your shareholder. If this is also another company (holding- or corporate-structure) you would need to follow the trail of indirect ownership until you either find an individual or the ownership is diluted under 25% ownership (by the means of indirect ownership).',
  'faq_beneficial_owner.section5.title': 'What do you mean by direct or indirectly & how I calculate that?',
  'faq_beneficial_owner.section5.p1':
    'Direct ownership refers to a natural person (individual) owning him-/herself voting-shares in the business.',
  'faq_beneficial_owner.section5.p2':
    'Indirect ownership refers to somebody owning one entity which then owns a certain part of the business. It also qualifies, if the entities are stacked into each other (several companies in between). Also, if one individual holds voting-shares via different entities in the business you would need to add up his/ her total indirect ownership in order to see whether the total engagement reaches more than 25%.',
  'faq_beneficial_owner.section5.p3':
    'When the shareholding structure is stacked over several hierarchies you multiply the ownership-quotas of each entity in order to get the ultimate quota for the respective person.',
  'faq_beneficial_owner.section5.p4': 'Example:',
  'faq_beneficial_owner.section5.p5': 'Adello GmbH needs to provide its beneficial owners.',
  'faq_beneficial_owner.section5.p6':
    'Adello’s shareholder are: Peter 30% of shares, Susi 10% of shares, Anne 10% of shares and Toscana GmbH 50% of shares.',
  'faq_beneficial_owner.section5.p7': 'Toscana’s shareholder: Hugo 75% of shares and Marie 25% of shares',
  'faq_beneficial_owner.section5.p8': 'Direct beneficial owners of Adello: Peter',
  'faq_beneficial_owner.section5.p9':
    'Indirect beneficial owners of Adello: Hugo with 37,5% of shares (Toscana’s share in Adello 50% x Hugo’s ownership quota in Toscana 75%). Please note, that Marie is not a beneficial owner as she has indirectly only 12,5% in Adello.',
  'faq_beneficial_owner.section6.title': 'We have different kinds of shares – which shares are the decisive ones?',
  'faq_beneficial_owner.section6.p1':
    'Please refer to the shares with decisive voting power as the beneficial owner is ultimately the person deciding the major strokes of the company.',
  'faq_beneficial_owner.section7.title':
    'Does the UBO need to do something, for instance sign something or perform a video-identification?',
  'faq_beneficial_owner.section7.p1':
    'No, please only submit the information of that person as stated in his/her official documents (ID-card or passport). There is no further double-checking of that information. The beneficial owner does not need to do anything during this identification process.',
};

export default faq;
