import { call, put, take, select } from 'redux-saga/effects';
import { getFormValues } from 'redux-form';
import { BusinessCreateResponse } from '../redux/business-info-actions';
import { legalFormRequiresShareholdersAgreement } from '../models/business-rulebook';
import { createBusinessDocument } from '../../businessDocument/sagas/business-document';
import {
  CREATE_BUSINESS_ACTION,
  createBusinessSuccess,
  businessCreateRequestFailure,
  CreateBusinessActionType,
  createBusiness as createBusinessAction,
} from '../redux/actions-index';
import businessConfig, { formatBusinessObject } from '../models/business-info';
import { resourceCreate, resourceUpdate } from '../../../sagas/resource-requests';
import { selectBusinessId } from '../selectors';
import { getUrlParams } from '../../../lib/utils';

export function* createBusiness(action: CreateBusinessActionType) {
  const business = formatBusinessObject(action.payload!);

  const { business_id }: { business_id?: string } = getUrlParams(['business_id']);

  let businessResponse: BusinessCreateResponse;
  if (business_id) {
    businessResponse = yield call(resourceUpdate, businessConfig, { pathParams: { business_id } }, business);
  } else {
    businessResponse = yield call(resourceCreate, businessConfig, {}, business);
  }

  if (businessResponse.error) {
    yield put(businessCreateRequestFailure(businessResponse.error));
    throw new Error('Business creation error');
  }

  return businessResponse;
}

export function* watchCreateBusiness() {
  while (true) {
    const action = yield take(CREATE_BUSINESS_ACTION);
    yield call(createBusiness, action);
  }
}

export function* createBusinessFromForm() {
  const businessForm = yield select(getFormValues('businessInfo'));
  const response = yield call(createBusiness, createBusinessAction(businessForm));
  yield put(createBusinessSuccess(response));
  return response;
}

export function* createBusinessDocumentFromForm() {
  const businessForm = yield select(getFormValues('businessInfo'));
  const businessId = yield select(selectBusinessId);
  if (
    legalFormRequiresShareholdersAgreement(businessForm.legal_form.value) &&
    businessForm.shareholders_agreement &&
    businessForm.shareholders_agreement[0]
  ) {
    return yield call(
      createBusinessDocument,
      businessId,
      'SHARE_HOLDERS_AGREEMENT',
      businessForm.shareholders_agreement[0],
    );
  }
}
