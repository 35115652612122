import * as React from 'react';
import { InjectedIntlProps, injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col, Grid } from 'react-flexbox-grid';

import Page from '../../../components/page';
import UnloadHandler from '../../../components/unload-handler';
import Steps from '../../../patterns/molecules/steps';
import Header from '../../../patterns/molecules/header';
import defaultSteps from '../../../models/steps';
import Spinner from '../../../patterns/atoms/spinner';

interface PreloaderProps extends InjectedIntlProps {}

class Preloader extends React.PureComponent<PreloaderProps, {}> {
  public render(): JSX.Element {
    return (
      <Page
        header={
          <Header>
            <Steps active={4} steps={defaultSteps} />
          </Header>
        }
      >
        <Grid fluid={true}>
          <Row between="xs">
            <Col md={2} />
            <Col md={8}>
              <Row>
                <Col sm={3} xs={12}>
                  <Spinner />
                </Col>
                <Col sm={9} xs={12}>
                  <h2>
                    <span>
                      <FormattedMessage id="pages.preloader.heading" />
                    </span>
                  </h2>
                  <p>
                    <FormattedMessage id="pages.preloader.text" />
                  </p>
                </Col>
              </Row>
            </Col>
            <Col md={2} />
          </Row>
        </Grid>
        <UnloadHandler />
      </Page>
    );
  }
}

export default injectIntl(Preloader);
