import * as React from 'react';
import * as classnames from 'classnames';
import { ReactComponent as Icon } from './single-accent.svg';
import './person.css';

type PersonIconVariant = 'default' | null;

export interface PersonIconProps {
  variant?: PersonIconVariant;
}

const PersonIcon: React.StatelessComponent<PersonIconProps> = ({ variant }) => (
  <Icon className={classnames({ 'icon-person--default': variant === 'default' })} />
);

export default PersonIcon;
