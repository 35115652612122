import moment from 'moment';
import { stopAsyncValidation } from 'redux-form';
import { ResourceError } from '../types';

const messages: Object = {
  en: {
    email: 'Invalid email address',
    phone: 'Invalid phone address',
    number: 'Must be a number',
    required: "Can't be empty",
    customLength: (limit: number): string => `Maximal length of ${limit} characters`,
    numberRange: (from: number, to: number): string => `Must be between ${from} and ${to}`,
    dateRange: (from: Date, to: Date): string => `Date must be between ${from} and ${to}`,
    greaterThan: (limit: number): string => `Must be greater than ${limit}`,
    lessOrEqual: (limit: number): string => `Must be less or equal than ${limit}`,
  },
  de: {
    email: 'Ungültige E-Mail-Adresse',
    phone: 'Ungültige Telefonnummer',
    number: 'Muss eine Zahl sein',
    required: 'Darf nicht leer sein',
    customLength: (limit: number): string => `Länge maximal ${limit} Zeichen`,
    numberRange: (from: number, to: number): string => `Muss zwischen ${from} und ${to} liegen`,
    dateRange: (from: Date, to: Date): string => `Das Datum muss zwischen ${from} und ${to} liegen`,
    greaterThan: (limit: number): string => `Muss größer als ${limit} sein`,
    lessOrEqual: (limit: number): string => `Muss kleiner oder gleich ${limit} sein`,
  },
};

function getMessage(message: string) {
  const language: string = navigator.language === 'de' ? 'de' : 'en';
  return messages[language][message];
}

export const emailIsValid = (value: string | undefined) => value && value.indexOf('@') !== -1;

export const mobileIsValid = (value: string | undefined) => value && /^\+[1-9]{1}[0-9]{6,13}$/i.test(value);

export const emailValidation = (value: string | undefined) => (emailIsValid(value) ? undefined : getMessage('email'));

export const mobileValidation = (value: string | undefined) => (mobileIsValid(value) ? undefined : getMessage('phone'));

export const requiredValidation = (value: string | undefined) => (value ? undefined : getMessage('required'));

export const customLengthValidation = (limit: number) => (value: string | undefined) =>
  value && value.length > limit ? getMessage('customLength')(limit) : undefined;

export const lengthValidation = customLengthValidation(100);

export const numberValidation = (value: string | undefined) =>
  value && isNaN(Number(value)) ? getMessage('number') : undefined;

export const numberRangeValidation = (from: number, to: number) => (value: string | undefined) => {
  const numberValue = Number(value);
  return numberValue < from || numberValue > to ? getMessage('numberRange')(from, to) : undefined;
};

export const dateRangeValidation = (from: moment.Moment, to: moment.Moment) => (value: Date) =>
  moment(value).isBefore(to) && moment(value).isAfter(from)
    ? undefined
    : getMessage('dateRange')(from.format('DD.MM.YYYY'), to.format('DD.MM.YYYY'));

export const greaterThan = (limit: number) => (value: string | undefined) => {
  const numberValue = Number(value);
  return numberValue <= limit ? getMessage('greaterThan')(limit) : undefined;
};

export const lessOrEqual = (limit: number) => (value: string | undefined) => {
  const numberValue = Number(value);
  return numberValue > limit ? getMessage('lessOrEqual')(limit) : undefined;
};

export const required = (values: Object, fields: Array<string>) => {
  const erros = {};
  fields.forEach((field: string) => {
    if (!values[field]) {
      erros[field] = getMessage('required');
    }
  });
  return erros;
};

export const stringLimitValidation = (values: Object, fields: Array<string>, limit?: number) => {
  const errors = {};
  const defaultLimit = limit || 100;
  fields.forEach((field: string) => {
    if (values[field] && values[field].length > defaultLimit) {
      errors[field] = getMessage('customLength')(defaultLimit);
    }
  });
  return errors;
};

interface OptionalValidation {
  field: string;
  validation: Function;
}

export const arrayValidation = (
  requiredFields: Array<string>,
  stringValidation: Array<string>,
  values?: Array<Object>,
  optionalValidation?: Array<OptionalValidation>,
) => {
  if (values && values.length) {
    const formArrayErrors: Array<Object> = [];
    values.forEach((form: Object, index: number) => {
      const formErrors: Object = required(form, requiredFields);
      const stringLimitErrors: Object = stringLimitValidation(form, stringValidation);
      formArrayErrors[index] = { ...stringLimitErrors, ...formErrors };
      if (optionalValidation) {
        const optValid = optionalValidation.reduce(
          (acc, opt) => ({ [opt.field]: opt.validation(form[opt.field]) }),
          {},
        );
        formArrayErrors[index] = {
          ...optValid,
          ...formArrayErrors[index],
        };
      }
    });
    return formArrayErrors;
  } else {
    return {};
  }
};

export const apiValidationErros = (apiErros: ResourceError[], formName: string) => {
  const asyncErrors = apiErros.reduce((accumulator: any, { source }) => {
    if (source) {
      return {
        ...accumulator,
        [source.field]: source.message,
      };
    }
    return undefined;
  }, {});
  return stopAsyncValidation(formName, asyncErrors);
};
