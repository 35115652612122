import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import { IntlShape, injectIntl, FormattedMessage } from 'react-intl';
import FormInputWrap from '../../habitats/form-input';
import Input from '../../atoms/textInput';
import Button from '../../atoms/button/button';
import { LOGIN_FORM_NAME } from '../../../modules/login/models/login';
import { ServerErrorResponse } from '../../../types';
import './login-form.css';

const LoginForm: React.FunctionComponent<{ onSubmit: () => void; errors: ServerErrorResponse } & {
  intl: IntlShape;
}> = props => {
  const {
    errors,
    onSubmit,
    intl: { formatMessage },
  } = props;
  const submitForm = () => onSubmit();
  return (
    <div>
      <FormInputWrap nospace={true}>
        <Field name="username" label={formatMessage({ id: 'forms.email' })} component={Input} />
      </FormInputWrap>
      <FormInputWrap nospace={true}>
        <Field name="password" label={formatMessage({ id: 'forms.password' })} component={Input} type="password" />
      </FormInputWrap>
      {errors && (
        <div className="login__error">
          <FormattedMessage
            id="forms.error.login"
            values={{
              error: errors.apiErrors || 'generic error',
            }}
          />
        </div>
      )}
      <br />
      <Button variant="primary" handleClick={submitForm}>
        <FormattedMessage id="forms.button.login" />
      </Button>
    </div>
  );
};

export default reduxForm({
  form: LOGIN_FORM_NAME,
  destroyOnUnmount: false,
})(injectIntl(LoginForm));
