import { ResourceConfig, EndpointConfig } from '../types';
import { call } from 'redux-saga/effects';
import { request, binaryRequest, buildRequestConfig, ApiRequest, ApiRequestParams } from '../lib/api';

export const defaultParams = {
  queryParams: {
    'page[number]': 1,
    'page[size]': 10,
  },
};

export function* resourceShow(model: ResourceConfig, apiRequestParams: ApiRequestParams) {
  const showConfig = model.endpoints.show!;
  return yield call(resourceRequest, showConfig, { ...apiRequestParams });
}

export function* resourceCreate(model: ResourceConfig, apiRequestParams: ApiRequestParams, payload: {}) {
  const createConfig = model.endpoints.create!;
  return yield call(resourceRequest, createConfig, apiRequestParams, payload);
}

export function* resourceUpdate(model: ResourceConfig, apiRequestParams: ApiRequestParams, payload: {}) {
  const updateConfig = model.endpoints.update!;
  return yield call(resourceRequest, updateConfig, apiRequestParams, payload);
}

export function* resourceDelete(model: ResourceConfig, apiRequestParams: ApiRequestParams) {
  const deleteConfig = model.endpoints.delete!;
  return yield call(resourceRequest, deleteConfig, apiRequestParams);
}

export function* resourceRequest(endpointConfig: EndpointConfig, apiRequestParams: ApiRequestParams, payload?: {}) {
  return yield call(genericResourceRequest, endpointConfig, apiRequestParams, request, payload);
}

export function* binaryResourceRequest(
  endpointConfig: EndpointConfig,
  apiRequestParams: ApiRequestParams,
  payload?: {},
) {
  return yield call(genericResourceRequest, endpointConfig, apiRequestParams, binaryRequest, payload);
}

export function* genericResourceRequest(
  endpointConfig: EndpointConfig,
  apiRequestParams: ApiRequestParams,
  requestSaga: (params: ApiRequest) => void,
  payload?: {},
) {
  // apiRequestParams.headerParams = { solarisPartnerId };
  const apiRequestConfig: ApiRequest = buildRequestConfig(endpointConfig, apiRequestParams, payload);
  try {
    return yield call(requestSaga, apiRequestConfig);
  } catch (error) {
    return error;
  }
}
