// tslint:disable:max-line-length
const pages = {
  'pages.next_step': 'Weiter',
  'pages.start_again': 'Neu starten',
  'pages.edit': 'Bearbeiten',
  'pages.back': 'Zurück',
  'pages.remove': 'Entfernen',
  'pages.finish': 'Beenden',
  'pages.please_select': '- Bitte auswählen -',
  'pages.see_example': 'Siehe Beispiel',
  'pages.hide_example': 'Beispiel ausblenden',
  'pages.your_business.title': 'Ihr Unternehmen',
  'pages.your_business.content':
    'Die juristische Person, die Vertragspartner wird. Bitte geben Sie die Daten so ein, wie sie auch im Handelsregister zu finden sind.',
  'pages.form_failure.title': 'Etwas ist schief gelaufen',
  'pages.form_failure.subtitle': 'Wir konnte Ihre Identität nicht verifizieren.',
  'pages.form_failure.content': 'Bitte versuchen Sie es erneut oder kontaktieren Sie uns unter ',
  'pages.form_failure.try_again': 'Versuchen Sie es erneut',
  'pages.form_success.title': 'Herzlichen Glückwunsch!',
  'pages.form_success.subtitle': 'Sie haben Ihre persönliche Identifikation erfolgreich abgeschlossen.',
  'pages.form_success.content':
    'Ihr Ansprechpartner bei der solarisBank wird sich in Kürze mit Ihnen in Verbindung setzen, um die nächsten Schritte zu besprechen.',
  'pages.form_success.cta': 'Besuchen Sie solarisBank.com',
  'pages.legal_representative.title': 'Gesetzliche Vertreter',
  'pages.legal_representative.content':
    'Ein "Geschäftsführer", "CEO" oder "Führungskraft" ist jemand, der rechtlich für das Unternehmen verantwortlich ist und in ihrem Namen handelt.',
  'pages.legal_representative.point1':
    'Bitte fügen Sie alle gesetzlichen Vertreter Ihres Unternehmens hinzu - unabhängig davon, ob die Person(en) allein oder gemeinsam vertretungsberechtigt sind.',
  'pages.legal_representative.point2':
    'Bitte geben Sie die Daten so ein, wie sie in Ihren offiziellen Dokumenten angegeben werden.',
  'pages.legal_representative.point3':
    'Personen, die Prokura oder ähnliche Titel halten, werden in diesem Schritt nicht erhoben.',
  'pages.legal_representative.point4':
    'Gesetzliche Vertreter können Anteile an dem Unternehmen halten, müssen es allerdings nicht.',
  'pages.legal_representative.legal_representatives_faq': 'FAQ | Gesetzliche Vertreter',
  'pages.personal_info.title': 'Ansprechpartner',
  'pages.personal_info.content': 'Als gesetzlicher Vertreter sind Sie unser Hauptansprechpartner.',
  'pages.review.title': 'Übersicht',
  'pages.review.content':
    'Sie sehen alle Daten, die Sie während des Prozesses eingegeben haben. Bitte beachten Sie, dass wir Daten Ihres Unternehmens benötigen, wie sie in offiziellen Dokumenten eingetragen sind (Ihre persönlichen Angaben wie auch die Geschäftsdaten sowie Angaben zu allen gesetzlichen Vertreter und wirtschaftlich Berechtigten).',
  'pages.review.form.title':
    'Bevor Sie die Video-Identifikation starten, überprüfen und bestätigen Sie bitte Ihre Angaben',
  'pages.review.form.agreement':
    'Hiermit bestätige ich, dass die folgenden Informationen vollständig und korrekt sind. Sollten sich diese Informationen ändern, werde ich die solarisBank unverzüglich darüber informieren und der solarisBank gegebenenfalls Unterlagen zur Verfügung stellen.',
  'pages.review.form.your_business': 'Ihr Unternehmen',
  'pages.review.form.founded_on': 'gegründet am {date}',
  'pages.review.form.industry': 'Industrie',
  'pages.review.form.registration_number': 'Handelsregisternummer',
  'pages.review.form.industry_key': 'Branchenschlüssel',
  'pages.review.form.registration_issuer': 'Handelsregisteramt',
  'pages.review.form.business_purpose': 'Geschäftszweck',
  'pages.review.form.you': 'Ansprechpartner',
  'pages.review.form.legal_representatives': 'Gesetzliche Vertreter',
  'pages.review.form.beneficial_owners': 'Wirtschaftlich Berechtigte',
  'pages.review.form.percentage_of_shares': 'Prozentualer Anteil der Unternehmensbeteiligung',
  'pages.review.form.date_of_birth': 'Geburtsdatum',
  'pages.welcome.title': 'Willkommen zu Ihrem Identifizierungsprozess',
  'pages.welcome.subtitle':
    'Dieser Prozess führt Sie durch den so genannten "Know-Your-Customer"-Prozess. Bevor wir anfangen, stellen Sie bitte sicher, dass Sie:',
  'pages.welcome.checks1':
    'gesetzlicher Vertreter des Unternehmens sind und entsprechende Registerinformationen zur Hand haben',
  'pages.welcome.checks2': 'einen Laptop oder Rechner mit funktionierender Webcam und Mikrofon nutzen',
  'pages.welcome.checks3': 'Ihren Ausweis oder Reisepass, sowie Ihr Mobiltelefon bereit halten',
  'pages.welcome.sidebar.title': 'jeder Zeit fortfahren',
  'pages.welcome.sidebar.content':
    'Sie können diesen Prozess jederzeit abbrechen und später fortführen. Alle Daten werden für Sie gespeichert.',
  'pages.beneficial_owner.form_title':
    'Bitte geben Sie Informationen zu den wirtschaftlich Berechtigten Ihres Unternehmens an',
  'pages.beneficial_owner.has_shareholder':
    'Das Unternehmen besitzt mindestens einen Anteilseigner, der mehr als 25% der Unternehmensanteile hält.',
  'pages.beneficial_owner.has_no_shareholder':
    'Es gibt keine natürliche Person, die mehr als, der mehr als 25% der Unternehmensanteile hält.',
  'pages.beneficial_owner.legal_representative_set_as_beneficial_owner':
    'Ein gesetzlicher Vertreter wurde als wirtschaftlich Berechtigter angegeben, da keine natürliche Person existiert, die mehr als 25% der Unternehmensanteile besitzt.',
  'pages.beneficial_owner.change_answer': 'Antwort ändern',
  'pages.beneficial_owner.sidebar.title': 'Wirtschaftlich Berechtigte',
  'pages.beneficial_owner.sidebar.content1':
    '“Wirtschaftlich berechtigt” ist eine Person, welche unmittelbar oder mittelbar mehr als 25 Prozent der Unternehmensanteile hält. Im Sinne des Gesetzes ist dies immer eine natürliche Person und kann keine juristische Person sein.',
  'pages.beneficial_owner.sidebar.content2':
    'Sollte es keine natürliche Person geben, welche unmittelbar oder mittelbar mehr als 25 Prozent der Unternehmensanteile hält, werden Sie gebeten, stattdessen die gesetzlichen Vertreter als wirtschaftlich berechtigt anzugeben.',
  'pages.beneficial_owner.sidebar.point1':
    'Bitte geben Sie alle natürlichen Personen an, die als wirtschaftlich berechtigt gelten.',
  'pages.beneficial_owner.sidebar.point2':
    'Bei unterschiedlichen Aktienarten beziehen Sie sich bitte auf die stimmberechtigten Aktien.',
  'pages.beneficial_owner.sidebar.point3':
    'Wenn ein Unternehmen als wirtschaftliche Berechtigt gilt, müssen die Informationen über den Eigentümer dieses Unternehmens angeben werden.',
  'pages.beneficial_owner.sidebar.point4':
    'Im Falle eines börsenquotierten Unternehmens können unterschiedliche Ansätze angewendet werden. Bitte nehmen Sie dann Kontakt mit uns auf.',
  'pages.beneficial_owner.sidebar.read_full_definition': 'Rechtliche Definition | Wirtschaftlich Berechtigte',
  'pages.beneficial_owner.sidebar.faq_link': 'FAQ | Wirtschaftlich Berechtigte',
  'pages.preloader.heading': 'Ihre Daten werden verarbeitet',
  'pages.preloader.text':
    'Bitte schließen Sie dieses Browserfenster nicht, bis der Identifizierungsprozess abgeschlossen ist. Der Ladevorgang kann bis zu 2 Minuten dauern. Bitte haben Sie etwas Geduld.',
  'pages.identification.header_text':
    'Sobald Sie die Video-Identifikation abgeschlossen haben, können Sie dieses Fenster schließen.',
  'pages.business_legal_representative.alert':
    'Sie gaben an, Ihr Unternehmen sei eine GmbH & Co. KG. Bitte geben Sie daher hier die Informationen der GmbH (gesetzlicher Vertreter der KG) ebenfalls an.',
  'pages.business_legal_representative.sidebar.title': 'Gesetzlicher Vertreter der {business_name}',
  'pages.business_legal_representative.sidebar.content1':
    'Ihr Unternehmen ist eine GmbH & Co. KG. Das bedeutet, dass anstelle von natürlichen Personen eine juristische Person die Gesellschaft rechtlich vertritt (eine “komplementäre” GmbH). Aus diesem Grund benötigen wir hier ebenfalls die Informationen der komplementären GmbH.',
  'pages.business_legal_representative.sidebar.content2':
    'Bitte geben Sie die Informationen über die GmbH wie im Handelsregister eingetragen an.',
  'pages.business_legal_representative.sidebar.content3':
    'Die gesetzlichen Vertreter (natürliche Personen) der GmbH werden nachfolgend gesammelt.',
  'pages.businesslegalrepresentativepersons.sidebar.title': 'Gesetzliche Vertretung der {businessname}',
  'pages.business_legal_representative_persons.sidebar.content':
    'Ihr Unternehmen ist eine GmbH & Co. KG. Das bedeutet, dass anstelle von natürlichen Personen eine juristische Person die Gesellschaft rechtlich vertritt (eine “komplementäre” GmbH). Aus diesem Grund benötigen wir hier ebenfalls die Informationen der komplementären GmbH.',
  'pages.business_legal_representative_persons.sidebar.point1':
    'Bitte fügen Sie alle Ihre gesetzlichen Vertreter hinzu - unabhängig davon, ob die Person(en) allein oder gemeinsam vertretungsberechtigt sind.',
  'pages.business_legal_representative_persons.sidebar.point2':
    'Bitte geben Sie die Daten so ein, wie sie in Ihren offiziellen Dokumenten angegeben werden.',
  'pages.business_legal_representative_persons.sidebar.point3':
    'Personen, die Prokura oder ähnliche Titel halten, werden in diesem Schritt nicht erhoben.',
  'pages.business_legal_representative_persons.sidebar.point4':
    'Gesetzliche Vertreter können Anteile an dem Unternehmen halten, müssen es allerdings nicht.',
  'pages.shareholder_modal.title': 'Gibt es mindestens eine Person, die mehr als 25% der Unternehmensanteile hält?',
  'pages.shareholder_modal.button': 'Speichern',
  'pages.shareholder_modal.option_yes': 'Ja, die gibt es.',
  'pages.shareholder_modal.option_no': 'Nein, es gibt niemanden.',
  'pages.footer.imprint': 'Impressum',
  'pages.footer.imprint_url': 'https://www.solarisbank.com/de/impressum/',
  'pages.footer.terms': 'Nutzungsbedingungen',
  'pages.footer.terms_url': 'https://www.solarisbank.com/de/nutzungsbedingungen/',
  'pages.footer.privacy_policy': 'Datenschutzhinweise',
  'pages.footer.privacy_policy_url': 'https://www.solarisbank.com/de/datenschutzhinweise/',
  'pages.footer.redo': 'Noch einmal beginnen',
  'pages.footer.need_help': 'Brauchen Sie Hilfe? Sie erreichen uns unter: ',
};

export default pages;
