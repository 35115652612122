import {
  CREATE_BUSINESS_IDENTIFICATION_ACTION,
  CREATE_BUSINESS_IDENTIFICATION_SUCCESS_ACTION,
  CREATE_BUSINESS_IDENTIFICATION_FAILURE_ACTION,
} from './businessIdentification-actions';

import { StandardAction, ServerErrorResponse } from '../../../types';
import { BusinessIdentificationParams, BusinessIdentification } from '../models/businessIdentification';

export const businessIdentificationDefaultState = {};

export const businessIdentification = (
  state = businessIdentificationDefaultState,
  action: StandardAction<BusinessIdentificationParams | BusinessIdentification | ServerErrorResponse>,
) => {
  if (!action.payload) {
    return state;
  }
  switch (action.type) {
    case CREATE_BUSINESS_IDENTIFICATION_ACTION:
      return businessIdentificationDefaultState;
    case CREATE_BUSINESS_IDENTIFICATION_SUCCESS_ACTION:
      let successAction = action as StandardAction<BusinessIdentification>;
      return {
        ...state,
        ...successAction.payload!,
      };
    case CREATE_BUSINESS_IDENTIFICATION_FAILURE_ACTION:
      return {
        ...state,
        errors: action.payload,
      };
    default:
      return state;
  }
};
