// tslint:disable:max-line-length
import { Enum } from 'typescript-string-enums';

export const titles = Enum('DR', 'MAGISTER', 'PROF', 'PROF_DR');
export const salutations = Enum('MR', 'MS', 'DIV');
export const sectors = Enum(
  'OTHER_COMPANIES',
  'OTHER_COMPANIES_WORKMAN',
  'NON_PROFIT_ORGANIZATION',
  'FOREIGN_COMPANIES',
);
export const currencies = Enum('EURO');
export const accountLockingStatuses = Enum('NO_BLOCK', 'DEBIT_BLOCK', 'CREDIT_BLOCK', 'BLOCK');
export const employmentStatuses = Enum(
  'EMPLOYED',
  'PUBLIC_SECTOR_EMPLOYEE',
  'PROFESSIONAL_SOLDIER',
  'FREELANCER',
  'HOUSEWORK',
  'APPRENTICE',
  'MANAGEMENT',
  'UNEMPLOYED',
  'RETIRED',
  'STUDENT',
  'SELF_EMPLOYED',
  'MILITARY_OR_COMMUNITY_SERVICE',
);
export const accountTypes = Enum(
  'SETTLEMENT',
  'CASH_DEPOSIT',
  'CHECKING_BUSINESS',
  'CHECKING_PERSONAL',
  'DEPOSIT_TIME_PASSIVE',
  'ESCROW_LIABILITY',
  'CHECKING_FIXED_MATURITY',
  'LOAN_RATE_VARIABLE',
  'LOAN_RATE_FIXED',
  'LOAN_BALLOON',
  'LOAN_BULLET',
  'CLEARING_ACCOUNT',
  'CHECKING_OVERDRAFT',
  'BILLING_ACCOUNT',
  'FACTORING',
  'TAGESGELD_ACTIVE',
  'SDD_DEPOSIT',
  'GUARANTEE',
  'SURETY',
  'LIABILITY_ASSUMPTION',
  'LORO',
);
export const maritalStatuses = Enum('SINGLE', 'MARRIED', 'DIVORCED', 'WIDOWED');
export const states = Enum(
  'BE',
  'BB',
  'BW',
  'BY',
  'HB',
  'HE',
  'HH',
  'MV',
  'NI',
  'NW',
  'RP',
  'SH',
  'SL',
  'SN',
  'ST',
  'TH',
);
export const countries = Enum(
  'DE',
  'AF',
  'AX',
  'AL',
  'DZ',
  'AS',
  'AD',
  'AO',
  'AI',
  'AQ',
  'AG',
  'AR',
  'AM',
  'AW',
  'AU',
  'AT',
  'AZ',
  'BS',
  'BH',
  'BD',
  'BB',
  'BY',
  'BE',
  'BZ',
  'BJ',
  'BM',
  'BT',
  'BO',
  'BQ',
  'BA',
  'BW',
  'BV',
  'BR',
  'IO',
  'BN',
  'BG',
  'BF',
  'BI',
  'KH',
  'CM',
  'CA',
  'CV',
  'KY',
  'CF',
  'TD',
  'CL',
  'CN',
  'CX',
  'CC',
  'CO',
  'KM',
  'CG',
  'CD',
  'CK',
  'CR',
  'CI',
  'HR',
  'CU',
  'CW',
  'CY',
  'CZ',
  'DK',
  'DJ',
  'DM',
  'DO',
  'EC',
  'EG',
  'SV',
  'GQ',
  'ER',
  'EE',
  'ET',
  'FK',
  'FO',
  'FJ',
  'FI',
  'FR',
  'GF',
  'PF',
  'TF',
  'GA',
  'GM',
  'GE',
  'GH',
  'GI',
  'GR',
  'GL',
  'GD',
  'GP',
  'GU',
  'GT',
  'GG',
  'GN',
  'GW',
  'GY',
  'HT',
  'HM',
  'VA',
  'HN',
  'HK',
  'HU',
  'IS',
  'IN',
  'ID',
  'IR',
  'IQ',
  'IE',
  'IM',
  'IL',
  'IT',
  'JM',
  'JP',
  'JE',
  'JO',
  'KZ',
  'KE',
  'KI',
  'KP',
  'KR',
  'XK',
  'KW',
  'KG',
  'LA',
  'LV',
  'LB',
  'LS',
  'LR',
  'LY',
  'LI',
  'LT',
  'LU',
  'MO',
  'MK',
  'MG',
  'MW',
  'MY',
  'MV',
  'ML',
  'MT',
  'MH',
  'MQ',
  'MR',
  'MU',
  'YT',
  'MX',
  'FM',
  'MD',
  'MC',
  'MN',
  'ME',
  'MS',
  'MA',
  'MZ',
  'MM',
  'NA',
  'NR',
  'NP',
  'NL',
  'NC',
  'NZ',
  'NI',
  'NE',
  'NG',
  'NU',
  'NF',
  'MP',
  'NO',
  'OM',
  'PK',
  'PW',
  'PS',
  'PA',
  'PG',
  'PY',
  'PE',
  'PH',
  'PN',
  'PL',
  'PT',
  'PR',
  'QA',
  'RE',
  'RO',
  'RU',
  'RW',
  'BL',
  'SH',
  'KN',
  'LC',
  'MF',
  'PM',
  'VC',
  'WS',
  'SM',
  'ST',
  'SA',
  'SN',
  'RS',
  'SC',
  'SL',
  'SG',
  'SX',
  'SK',
  'SI',
  'SB',
  'SO',
  'ZA',
  'GS',
  'SS',
  'ES',
  'LK',
  'SD',
  'SR',
  'SJ',
  'SZ',
  'SE',
  'CH',
  'SY',
  'TW',
  'TJ',
  'TZ',
  'TH',
  'TL',
  'TG',
  'TK',
  'TO',
  'TT',
  'TN',
  'TR',
  'TM',
  'TC',
  'TV',
  'UG',
  'UA',
  'AE',
  'GB',
  'US',
  'UM',
  'UY',
  'UZ',
  'VU',
  'VE',
  'VN',
  'VG',
  'VI',
  'WF',
  'EH',
  'YE',
  'ZM',
  'ZW',
);

export const germanRegistrationIssuers = Enum(
  'AACHEN',
  'ALTENBURG',
  'AMBERG',
  'ANSBACH',
  'APOLDA',
  'ARNSBERG',
  'ARNSTADT',
  'ARNSTADT_ZWEIGSTELLE_ILMENAU',
  'ASCHAFFENBURG',
  'AUGSBURG',
  'AURICH',
  'BAD_HERSFELD',
  'BAD_HOMBURG_VDH',
  'BAD_KREUZNACH',
  'BAD_OEYNHAUSEN',
  'BAD_SALZUNGEN',
  'BAMBERG',
  'BAYREUTH',
  'BERLIN_CHARLOTTENBURG',
  'BIELEFELD',
  'BOCHUM',
  'BONN',
  'BRAUNSCHWEIG',
  'BREMEN',
  'CHEMNITZ',
  'COBURG',
  'COESFELD',
  'COTTBUS',
  'DARMSTADT',
  'DEGGENDORF',
  'DORTMUND',
  'DRESDEN',
  'DUISBURG',
  'DUEREN',
  'DUESSELDORF',
  'EISENACH',
  'ERFURT',
  'ESCHWEGE',
  'ESSEN',
  'FLENSBURG',
  'FRANKFURT_AM_MAIN',
  'FRANKFURT_ODER',
  'FREIBURG',
  'FRIEDBERG',
  'FRITZLAR',
  'FULDA',
  'FUERTH',
  'GELSENKIRCHEN',
  'GERA',
  'GIESSEN',
  'GOTHA',
  'GOETTINGEN',
  'GREIFSWALD',
  'GREIZ',
  'GUESTROW',
  'GUETERSLOH',
  'HAGEN',
  'HAMBURG',
  'HAMM',
  'HANAU',
  'HANNOVER',
  'HEILBAD_HEILIGENSTADT',
  'HILDBURGHAUSEN',
  'HILDESHEIM',
  'HOF',
  'HOMBURG',
  'INGOLSTADT',
  'ISERLOHN',
  'JENA',
  'KAISERSLAUTERN',
  'KASSEL',
  'KEMPTEN_ALLGAUE',
  'KIEL',
  'KLEVE',
  'KOBLENZ',
  'KOELN',
  'KOENIGSTEIN',
  'KORBACH',
  'KREFELD',
  'LANDAU',
  'LANDSHUT',
  'LEBACH',
  'LEIPZIG',
  'LEMGO',
  'LIMBURG',
  'LUEBECK',
  'LUDWIGSHAFEN_A_RHEIN_LUDWIGSHAFEN',
  'LUDWIGSLUST',
  'LUENEBURG',
  'MAINZ',
  'MANNHEIM',
  'MARBURG',
  'MEININGEN',
  'MEMMINGEN',
  'MERZIG',
  'MOENCHENGLADBACH',
  'MONTABAUR',
  'MUEHLHAUSEN',
  'MUENCHEN',
  'MUENSTER',
  'NEUBRANDENBURG',
  'NEUNKIRCHEN',
  'NEURUPPIN',
  'NEUSS',
  'NORDHAUSEN',
  'NUERNBERG',
  'OFFENBACH_AM_MAIN',
  'OLDENBURG_OLDENBURG',
  'OSNABRUECK',
  'OTTWEILER',
  'PADERBORN',
  'PASEWALK',
  'PASSAU',
  'PINNEBERG',
  'POESSNECK',
  'POESSNECK_ZWEIGSTELLE_BAD_LOBENSTEIN',
  'POTSDAM',
  'RECKLINGHAUSEN',
  'REGENSBURG',
  'RIBNITZ_DAMGARTEN',
  'ROSTOCK',
  'RUDOLSTADT',
  'RUDOLSTADT_ZWEIGSTELLE_SAALFELD',
  'SAARBRUECKEN',
  'SAARLOUIS',
  'SCHWEINFURT',
  'SCHWERIN',
  'SIEGBURG',
  'SIEGEN',
  'SOEMMERDA',
  'SONDERSHAUSEN',
  'SONNEBERG',
  'ST_INGBERT_ST_INGBERT',
  'ST_WENDEL_ST_WENDEL',
  'STADTHAGEN',
  'STADTRODA',
  'STEINFURT',
  'STENDAL',
  'STRALSUND',
  'STRAUBING',
  'STUTTGART',
  'SUHL',
  'TOSTEDT',
  'TRAUNSTEIN',
  'ULM',
  'VOELKLINGEN',
  'WALSRODE',
  'WAREN_MUERITZ',
  'WEIDEN_ID_OPF',
  'WEIMAR',
  'WETZLAR',
  'WIESBADEN',
  'WISMAR',
  'WITTLICH',
  'WUPPERTAL',
  'WUERZBURG',
  'ZWEIBRUECKEN',
);
export const industries = Enum(
  'BUILDING_INDUSTRY',
  'TRADE_MAINTENANCE_AND_REPAIR_OF_VEHICLES',
  'GASTRONOMY',
  'SCIENTIFIC_TECHNICAL_CONSULTING_SERVICES',
  'INFORMATION_AND_COMMUNICATION',
  'OTHER_ECONOMIC_SERVICES',
  'OTHER_SERVICES',
  'FARMER_FORESTRY_FISHING_AND_AQUACULTURE',
  'POWER_WATER_SUPPLY_DISPOSAL_MINING',
  'CHEMICAL_INDUSTRY_COKE_OVEN_MINERAL_OIL',
  'MANUFACTURE_PLASTICS_RUBBER_GOODS',
  'STONE_SOIL_CERAMICS_GLASS',
  'METAL_PRODUCTION_PROCESSING',
  'MECHANICAL_ENGINEERING_VEHICLE_MANUFACTURING',
  'MANUFACTURE_DATA_PROC_EQUIPMENT_ELECTRIC_OPTICS',
  'WOOD_PAPER_PRINT_INDUSTRY_FURNITURE_ETC',
  'LEATHER_TEXTILE_CLOTHES_INDUSTRY',
  'FOOD_INDUSTRY_TOBACCO_PRODUCTS',
  'TRANSPORT_AND_STORAGE_AND_NEWS_TRANSMISSION',
  'FINANCIAL_LEASING_COMPANIES',
  'HOUSING_COMPANIES',
  'HOLDING_COMPANIES',
  'OTHER_PROPERTY_MANAGEMENT',
  'HEALTH_CARE_VETERIN_A_WELFARE_FIRM_FREELANCE',
  'LEASING_OF_MOVABLE_OBJECTS',
  'NON_PROFIT_ORGANIZATION',
  'NON_MARKET_PRODUCERS',
  'ORGANIZATION_WITHOUT_ACQUIRING_HOUSE_BUILDING',
);
export const industryKeys = Enum(
  'NONE',
  'CONSTRUCTION_OF_BUILDINGS',
  'CIVIL_ENGINEERING',
  'SPECIALISED_CONSTRUCTION_ACTIVITIES',
  'WHOLESALE_AND_RETAIL_TRADE_AND_REPAIR_OF_MOTOR_VEHICLES_AND_MOTORCYCLES',
  'WHOLESALE_TRADE_EXCEPT_OF_MOTOR_VEHICLES_AND_MOTORCYCLES',
  'RETAIL_TRADE_EXCEPT_OF_MOTOR_VEHICLES_AND_MOTORCYCLES',
  'EDUCATION',
  'CREATIVE_ARTS_AND_ENTERTAINMENT_ACTIVITIES',
  'LIBRARIES_ARCHIVES_MUSEUMS_AND_OTHER_CULTURAL_ACTIVITIES',
  'GAMBLING_AND_BETTING_ACTIVITIES',
  'SPORTS_ACTIVITIES_AND_AMUSEMENT_AND_RECREATION_ACTIVITIES',
  'OTHER_PERSONAL_SERVICE_ACTIVITIES',
  'ACCOMMODATION',
  'FOOD_AND_BEVERAGE_SERVICE_ACTIVITIES',
  'ARCHITECTURAL_AND_ENGINEERING_ACTIVITIES_TECHNICAL_TESTING_AND_ANALYSIS',
  'SCIENTIFIC_RESEARCH_AND_DEVELOPMENT',
  'ADVERTISING_AND_MARKET_RESEARCH',
  'OTHER_PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_ACTIVITIES',
  'LEGAL_AND_ACCOUNTING_ACTIVITIES',
  'MANAGEMENT_CONSULTANCY_ACTIVITIES',
  'PUBLISHING_ACTIVITIES',
  'MOTION_PICTURE_VIDEO_AND_TELEVISION_PROGRAMME_PRODUCTION_SOUND_RECORDING_AND_MUSIC_PUBLISHING_ACTIVITIES',
  'PROGRAMMING_AND_BROADCASTING_ACTIVITIES',
  'TELECOMMUNICATIONS',
  'COMPUTER_PROGRAMMING_CONSULTANCY_AND_RELATED_ACTIVITIES',
  'INFORMATION_SERVICE_ACTIVITIES',
  'EMPLOYMENT_ACTIVITIES',
  'SECURITY_AND_INVESTIGATION_ACTIVITIES',
  'OFFICE_ADMINISTRATIVE_OFFICE_SUPPORT_AND_OTHER_BUSINESS_SUPPORT_ACTIVITIES',
  'OWN_ASSET_MANAGEMENT',
  'ACTIVITIES_OF_BUSINESS_EMPLOYERS_AND_PROFESSIONAL_MEMBERSHIP_ORGANISATIONS',
  'CROP_AND_ANIMAL_PRODUCTION_HUNTING_AND_RELATED_SERVICE_ACTIVITIES',
  'FORESTRY_AND_LOGGING',
  'FISHING_AND_AQUACULTURE',
  'MINING_OF_COAL_AND_LIGNITE',
  'EXTRACTION_OF_CRUDE_PETROLEUM_AND_NATURAL_GAS',
  'MINING_OF_METAL_ORES',
  'OTHER_MINING_AND_QUARRYING',
  'ELECTRICITY_GAS_STEAM_AND_AIR_CONDITIONING_SUPPLY',
  'WATER_COLLECTION_TREATMENT_AND_SUPPLY',
  'SEWERAGE',
  'WASTE_COLLECTION_TREATMENT_AND_DISPOSAL_ACTIVITIES_MATERIALS_RECOVERY',
  'REMEDIATION_ACTIVITIES_AND_OTHER_WASTE_MANAGEMENT_SERVICES',
  'MANUFACTURE_OF_COKE_AND_REFINED_PETROLEUM_PRODUCTS',
  'MANUFACTURE_OF_CHEMICALS_AND_CHEMICAL_PRODUCTS',
  'MANUFACTURE_OF_BASIC_PHARMACEUTICAL_PRODUCTS_AND_PHARMACEUTICAL_PREPARATIONS',
  'MANUFACTURE_OF_RUBBER_AND_PLASTIC_PRODUCTS',
  'MANUFACTURE_OF_PRECIOUS_METALS_OR_STONES',
  'MANUFACTURE_OF_BASIC_METALS',
  'MANUFACTURE_OF_FABRICATED_METAL_PRODUCTS_EXCEPT_MACHINERY_AND_EQUIPMENT',
  'MANUFACTURE_OF_MACHINERY_AND_EQUIPMENT_N_E_C',
  'MANUFACTURE_OF_MOTOR_VEHICLES_TRAILERS_AND_SEMI_TRAILERS',
  'MANUFACTURE_OF_OTHER_TRANSPORT_EQUIPMENT',
  'REPAIR_AND_INSTALLATION_OF_MACHINERY_AND_EQUIPMENT',
  'MANUFACTURE_OF_COMPUTER_ELECTRONIC_AND_OPTICAL_PRODUCTS',
  'MANUFACTURE_OF_ELECTRICAL_EQUIPMENT',
  'MANUFACTURE_OF_WOOD_AND_OF_PRODUCTS_OF_WOOD_AND_CORK_EXCEPT_FURNITURE_MANUFACTURE_OF_ARTICLES_OF_STRAW_AND_PLAITING_MATERIALS',
  'MANUFACTURE_OF_PAPER_AND_PAPER_PRODUCTS',
  'PRINTING_AND_REPRODUCTION_OF_RECORDED_MEDIA',
  'MANUFACTURE_OF_FURNITURE',
  'OTHER_MANUFACTURING',
  'MANUFACTURE_OF_TEXTILES',
  'MANUFACTURE_OF_WEARING_APPAREL',
  'MANUFACTURE_OF_LEATHER_AND_RELATED_PRODUCTS',
  'MANUFACTURE_OF_FOOD_PRODUCTS',
  'MANUFACTURE_OF_BEVERAGES',
  'MANUFACTURE_OF_TOBACCO_PRODUCTS',
  'LAND_TRANSPORT_AND_TRANSPORT_VIA_PIPELINES',
  'WATER_TRANSPORT',
  'AIR_TRANSPORT',
  'WAREHOUSING_AND_SUPPORT_ACTIVITIES_FOR_TRANSPORTATION',
  'POSTAL_AND_COURIER_ACTIVITIES',
  'TRAVEL_AGENCY_TOUR_OPERATOR_AND_OTHER_RESERVATION_SERVICE_AND_RELATED_ACTIVITIES',
  'FINANCIAL_LEASING',
  'BUYING_AND_SELLING_OF_OWN_RESIDENTIAL_REAL_ESTATE',
  'ACTIVITIES_OF_HEAD_OFFICES',
  'BUYING_AND_SELLING_OF_OWN_NON_RESIDENTIAL_REAL_ESTATE',
  'SERVICES_TO_BUILDINGS_AND_LANDSCAPE_ACTIVITIES',
  'VETERINARY_ACTIVITIES',
  'HUMAN_HEALTH_ACTIVITIES',
  'RESIDENTIAL_CARE_ACTIVITIES',
  'SOCIAL_WORK_ACTIVITIES_WITHOUT_ACCOMMODATION',
  'RENTAL_AND_LEASING_ACTIVITIES',
  'PRIVATE_NON_PROFIT_ORGANIZATIONS',
);

export const legalForms = Enum(
  'NONE',
  'SELF_EMPLOYED',
  'OHG',
  'LTD',
  'PARTG',
  'KG',
  'UG',
  'SOLE_PROPRIETORSHIP',
  'GMBH',
  'KGAA',
  'AG',
  'EG',
  'EK',
  'EV',
  'NEV',
  'GBR',
  'GMBH_I_GR',
  'UG_I_GR',
  'FOREIGN_CORPORATION',
  'GMBH_CO_KG',
);

export const statusTypes = Enum('payin', 'payout');
export const identificationMethods = Enum('idnow', 'postident');
export const legitimationTypes = Enum('IDCARD', 'PASSPORT');
export const ratingTypes = Enum('NONE', 'ONE', 'TWO', 'THREE', 'FOUR', 'FIVE', 'SIX', 'SEVEN', 'EIGHT', 'NINE', 'TEN');
export const documentTypes = Enum('ID_DOCUMENT', 'SIGNATURE', 'KYC_REPORT', 'PICTURE', 'SIGNED_CONTRACT', 'OTHER');

export const legalRepresentativeTypes = Enum('Business', 'Person');

export const cardStatus = Enum('CLOSED', 'CLOSED_BY_SOLARIS', 'BLOCKED', 'BLOCKED_BY_SOLARIS');

export const legalRepresentativeRepresentationTypes = Enum('ALONE', 'JOINT', 'OTHER');
