import { call, put, select } from 'redux-saga/effects';
import { getFormValues } from 'redux-form';
import { createBusinessLegalRepSuccess } from '../redux/actions-index';
import {
  businessCreateRequestFailure,
  createBusiness as createBusinessAction,
  CreateBusinessActionType,
} from '../../businessInfo/redux/actions-index';
import { BUSINESS_LEGAL_REPRESENTATIVE_RESOURCE_NAME } from '../models/business-legal-representative';
import { selectBusinessId, selectHasBusinessAsLegalRepresentative } from '../../../selectors';
import { createLegalRepresentative } from '../../legalRepresentative/sagas/legalRepresentative';
import { BusinessCreateResponse } from '../../businessInfo/redux/business-info-actions';
import { resourceCreate } from '../../../sagas/resource-requests';
import businessConfig, { formatBusinessObject } from '../../businessInfo/models/business-info';

export function* createBusinessLegalRep(action: CreateBusinessActionType) {
  const business = formatBusinessObject(action.payload!);

  let businessResponse: BusinessCreateResponse = yield call(resourceCreate, businessConfig, {}, business);

  if (businessResponse.error) {
    yield put(businessCreateRequestFailure(businessResponse.error));
    throw new Error('Business creation error');
  }

  return businessResponse;
}

export function* createBusinessLegalRepFromForm() {
  const hasBusinessAsLegalRep = yield select(selectHasBusinessAsLegalRepresentative);
  if (hasBusinessAsLegalRep) {
    const businessId = yield select(selectBusinessId);
    const businessForm = yield select(getFormValues(BUSINESS_LEGAL_REPRESENTATIVE_RESOURCE_NAME));
    const response = yield call(createBusinessLegalRep, createBusinessAction(businessForm));
    yield put(createBusinessLegalRepSuccess(response));
    yield call(createLegalRepresentative, businessId, response.data.id);
    return response;
  }
}
