import { SagaIterator } from 'redux-saga';
import { LOGIN_ACTION, LoginActionType, loginFailedAction, loginSuceededAction } from '../redux/login-actions';
import { takeEvery, select, call, put } from 'redux-saga/effects';
import loginResourceConfig, {
  AUTHENTICATION_VALID,
  AUTH_TOKEN,
  LOGIN_FORM_NAME,
  LoginResponsePayload,
} from '../models/login';
import { getFormValues } from 'redux-form';
import { resourceCreate } from '../../../sagas/resource-requests';
import moment from 'moment';
import { ResourceResponse } from '../../../types';

export const getToken = () => localStorage.getItem(AUTH_TOKEN);

export function* persistToken(result: ResourceResponse<LoginResponsePayload>) {
  if (result.data) {
    yield localStorage.setItem(AUTH_TOKEN, result.data!.access_token);
    localStorage.setItem(
      AUTHENTICATION_VALID,
      moment()
        .add(result.data!.expires_in, 'seconds')
        .unix()
        .toString(),
    );
    return true;
  }
  return false;
}

export function* listenToLogin(): SagaIterator<void> {
  yield takeEvery(LOGIN_ACTION, handleLogin);
}

export function* handleLogin(action: LoginActionType): SagaIterator {
  const loginFormValues = yield select(getFormValues(LOGIN_FORM_NAME));
  const response = yield call(resourceCreate, loginResourceConfig, {}, loginFormValues);
  const tokenCanBePersisted: boolean = yield call(persistToken, response);
  tokenCanBePersisted ? yield put(loginSuceededAction()) : yield put(loginFailedAction(response.error));
}
