import * as React from 'react';
import { Row, Col, Grid } from 'react-flexbox-grid';
import { hashHistory } from 'react-router';
import { InjectedIntlProps, injectIntl, FormattedMessage } from 'react-intl';
import Page from '../../../components/page';
import UnloadHandler from '../../../components/unload-handler';
import Steps from '../../../patterns/molecules/steps';
import Header from '../../../patterns/molecules/header';
import BusinessInfoForm from '../../../patterns/molecules/business-info-form';
import VerticalDivider from '../../../patterns/atoms/vertical-divider';
import Sidebar from '../../../patterns/molecules/sidebar';
import Button from '../../../patterns/atoms/button';
import { ReactComponent as BackIcon } from '../../../patterns/atoms/icons/back.svg';
import defaultSteps from '../../../models/steps';
import { BUSINESS_LEGAL_REPRESENTATIVE_RESOURCE_NAME } from '../models/business-legal-representative';
import { BusinessInfoFormProps } from '../../../types';
import Alert from '../../../patterns/atoms/alert';
import { config as businessLegalRepresentativeConfig } from '../../businessLegalRepresentative';
import { ReactComponent as LegalRepIcon } from '../../../patterns/atoms/icons/legalrep.svg';

interface BusinessInfoPageProps extends InjectedIntlProps {
  submit: Function;
  first_name: string;
  business_name: string;
  formValues: BusinessInfoFormProps;
}
class BusinessInfoPage extends React.PureComponent<BusinessInfoPageProps, {}> {
  submitForm = () => {
    this.props.submit(BUSINESS_LEGAL_REPRESENTATIVE_RESOURCE_NAME);
  };
  public render(): JSX.Element {
    const {
      first_name,
      intl: { formatMessage },
      formValues,
      business_name,
    } = this.props;
    return (
      <Page
        header={
          <Header
            sidebar={
              <Button variant="primary" fullWidth={true} handleClick={this.submitForm}>
                <FormattedMessage id="pages.next_step" />
              </Button>
            }
          >
            <Steps active={2} steps={defaultSteps} />
          </Header>
        }
      >
        <Grid fluid={true}>
          <Row between="xs">
            <Col sm={2}>
              <Button href="business-info" display="inline">
                <BackIcon />
                <FormattedMessage id="pages.back" />
              </Button>
            </Col>
            <Col sm={6}>
              <h2>{formatMessage({ id: 'forms.business_info.title' }, { name: first_name })}</h2>
              <Alert>
                <p>
                  <FormattedMessage id="pages.business_legal_representative.alert" />
                </p>
              </Alert>
              <BusinessInfoForm
                formValues={formValues}
                form={BUSINESS_LEGAL_REPRESENTATIVE_RESOURCE_NAME}
                isLegalRepresentative={true}
                onSubmit={() => {
                  hashHistory.push(businessLegalRepresentativeConfig.routes.businessLegalRepresentativePersons.path);
                }}
                initialValues={{
                  tax_country: {
                    value: 'DE',
                    label: formatMessage({ id: 'country.DE' }),
                  },
                  legal_form: {
                    value: 'GMBH',
                    label: formatMessage({ id: 'legal_form.GMBH' }),
                  },
                }}
              />
            </Col>
            <Col sm={1}>
              <VerticalDivider />
            </Col>
            <Col sm={3}>
              <Sidebar
                title={formatMessage({ id: 'pages.business_legal_representative.sidebar.title' }, { business_name })}
                icon={<LegalRepIcon />}
              >
                <p>
                  <FormattedMessage id="pages.business_legal_representative.sidebar.content1" />
                </p>
                <p>
                  <FormattedMessage id="pages.business_legal_representative.sidebar.content2" />
                </p>
                <p>
                  <FormattedMessage id="pages.business_legal_representative.sidebar.content3" />
                </p>
                <Button href="info/faq-legal-representatives" display="inline" variant="primary" bold={true}>
                  <FormattedMessage id="pages.legal_representative.legal_representatives_faq" />
                </Button>
              </Sidebar>
            </Col>
          </Row>
        </Grid>
        <UnloadHandler />
      </Page>
    );
  }
}

export default injectIntl(BusinessInfoPage);
