// tslint:disable:max-line-length
const common = {
  'keyword.edit': 'Bearbeiten',
  'keyword.details': '{entity} Angaben',
  'message.no_result_found': 'Keine Ergebnisse gefunden',
  'entity.card': 'Karte',
  'card.status.BLOCKED': 'BLOCKED',
  'card.status.BLOCKED_BY_SOLARIS': 'BLOCKED_BY_SOLARIS',
  'card.status.CLOSED': 'CLOSED',
  'card.status.CLOSED_BY_SOLARIS': 'CLOSED_BY_SOLARIS',
  'auth.successfullyLoggedOut': 'Erfolgreich abgemeldet',
  'auth.wrongCredentials': 'Wrong username or password',
  'fieldLabel.upload_file': 'Wähle eine Datei',
  'fieldLabel.voting_share': 'Stimmrechtsanteil',
  'fieldLabel.valid_until': 'Gültig bis',
  'fieldLabel.salutation': 'Anrede',
  'fieldLabel.sector': 'Sektor',
  'fieldLabel.industry': 'Industrie',
  'fieldLabel.country': 'Land',
  'fieldLabel.job_title': 'Stellenbezeichnung',
  'fieldLabel.first_name': 'Vorname',
  'fieldLabel.last_name': 'Name',
  'fieldLabel.name': 'Name',
  'fieldLabel.employment_status': 'Erwerbsstatus',
  'fieldLabel.email': 'E-Mail',
  'fieldLabel.mobile_number': 'Handynummer',
  'fieldLabel.industry_key': 'Industrieschlüssel',
  'fieldLabel.legal_form': 'Rechtsform',
  'fieldLabel.tax_information.tax_country': 'Land der Besteuerung',
  'fieldLabel.tax_information.tax_confirmation': 'Steuerbestätigungsnachweis',
  'fieldLabel.tax_information.registration_number': 'Handelsregisternummer',
  'fieldLabel.tax_information.registration_issuer': 'Handelsregisteramt',
  'fieldLabel.address.line_1': 'Zeile 1',
  'fieldLabel.address.line_2': 'Zeile 2',
  'fieldLabel.address.postal_code': 'Postleitzahl',
  'fieldLabel.address.city': 'Stadt',
  'fieldLabel.address.state': 'Staat',
  'fieldLabel.address.country': 'Land',
  'fieldLabel.id': 'ID',
  'fieldLabel.foundation_date': 'Gründungsdatum',
  'fieldLabel.policy_id': 'Policy Id',
  'fieldLabel.partner_id': 'Partner Id',
  'fieldLabel.type': 'Typ',
  'noteInput.email': 'E-Mail',
  'noteInput.body': 'Body',
  'noteInput.title': 'Add Note',
  'fieldLabel.title': 'Title',
  'fieldLabel.birth_name': 'Birth Name',
  'fieldLabel.birth_date': 'Birth Date',
  'fieldLabel.birth_city': 'Birth City',
  'fieldLabel.birth_country': 'Birth Country',
  'fieldLabel.tax_information.marital_status': 'Martial Status',
  'fieldLabel.tax_information.tax_assessment': 'Tax Assessment',
  'fieldLabel.locking_status': 'Status',
  'fieldLabel.account_limit': 'Account Limit (€)',
  'fieldLabel.valid_from': 'Valid From',
  'fieldLabel.internal_account_limit': 'Internal Account Limit (€)',
  'fieldLabel.type_of_representation': 'Type of representation',
  'accountType.SETTLEMENT': '(18) Settlement',
  'accountType.CASH_DEPOSIT': '(22) Cash Deposit',
  'accountType.CHECKING_BUSINESS': '(20) Checking Business',
  'accountType.CHECKING_PERSONAL': '(21) Checking Personal',
  'accountType.DEPOSIT_TIME_PASSIVE': '(24) Deposit Fixed-Term',
  'accountType.ESCROW_LIABILITY': '(27) Escrow Liability',
  'accountType.CHECKING_FIXED_MATURITY': '(30) Checking Fixed Maturity',
  'accountType.LOAN_RATE_VARIABLE': '(31) Loan Rate Variable',
  'accountType.LOAN_RATE_FIXED': '(32) Loan Rate Fixed',
  'accountType.LOAN_BALLOON': '(33) Loan Balloon',
  'accountType.LOAN_BULLET': '(34) Loan Bullet',
  'accountType.CLEARING_ACCOUNT': '(17) Clearing Account',
  'accountType.CHECKING_OVERDRAFT': '(26) Checking Overdraft',
  'accountType.BILLING_ACCOUNT': '(28) Billing Account',
  'accountType.FACTORING': '(19) Factoring',
  'accountType.TAGESGELD_ACTIVE': '(23) Tagesgeld Active',
  'accountType.SDD_DEPOSIT': '(36) SDD Deposit',
  'accountType.GUARANTEE': '(40) Guarantee',
  'accountType.SURETY': '(41) Surety',
  'accountType.LIABILITY_ASSUMPTION': '(42) Liability Assumption',
  'accountType.LORO': '(11) Loro',
  'button.submit': 'Absenden',
  'button.next': 'Next',
  'button.reset': 'Zurücksetzen',
  'button.createPerson': 'Create Person',
  'button.createAccount': 'Create Account',
  'button.createBusiness': 'Create Business',
  'button.createPartner': 'Create Partner',
  selectPartner: 'Select Partner',
  'button.createPolicy': 'Create Policy',
  'button.selectPolicy': 'Select Policy',
  'button.sendCredentials': 'Send Credentials',
  'country.DE': 'Deutschland',
  'country.AF': 'Afghanistan',
  'country.AX': 'Ålandinseln',
  'country.AL': 'Albanien',
  'country.DZ': 'Algerien',
  'country.AS': 'Amerikanisch-Samoa',
  'country.AD': 'Andorra',
  'country.AO': 'Angola',
  'country.AI': 'Anguilla',
  'country.AQ': 'Antarktis',
  'country.AG': 'Antigua und Barbuda',
  'country.AR': 'Argentinien',
  'country.AM': 'Armenien',
  'country.AW': 'Aruba',
  'country.AU': 'Australien',
  'country.AT': 'Österreich',
  'country.AZ': 'Aserbaidschan',
  'country.BS': 'Bahamas',
  'country.BH': 'Bahrain',
  'country.BD': 'Bangladesch',
  'country.BB': 'Barbados',
  'country.BY': 'Weißrussland',
  'country.BE': 'Belgien',
  'country.BZ': 'Belize',
  'country.BJ': 'Benin',
  'country.BM': 'Bermudas',
  'country.BT': 'Bhutan',
  'country.BO': 'Bolivien, Plurinationaler Staat',
  'country.BQ': 'Bonaire, Sint Eustatius und Saba',
  'country.BA': 'Bosnien und Herzegowina',
  'country.BW': 'Botswana',
  'country.BV': 'Bouvet Island',
  'country.BR': 'Brasilien',
  'country.IO': 'British Indian Ocean Territory',
  'country.BN': 'Brunei Darussalam',
  'country.BG': 'Bulgarien',
  'country.BF': 'Burkina Faso',
  'country.BI': 'Burundi',
  'country.KH': 'Kambodscha',
  'country.CM': 'Kamerun',
  'country.CA': 'Kanada',
  'country.CV': 'Kap Verde',
  'country.KY': 'Kaimaninseln',
  'country.CF': 'Zentralafrikanische Republik',
  'country.TD': 'Tschad',
  'country.CL': 'Chile',
  'country.CN': 'China',
  'country.CX': 'Weihnachtsinsel',
  'country.CC': 'Cocos (Keeling) Inseln',
  'country.CO': 'Kolumbien',
  'country.KM': 'Komoren',
  'country.CG': 'Kongo',
  'country.CD': 'Kongo, die Demokratische Republik Kongo',
  'country.CK': 'Cook Islands',
  'country.CR': 'Costa Rica',
  'country.CI': 'Côte dIvoire',
  'country.HR': 'Kroatien',
  'country.CU': 'Kuba',
  'country.CW': 'Curaçao',
  'country.CY': 'Zypern',
  'country.CZ': 'Tschechische Republik',
  'country.DK': 'Dänemark',
  'country.DJ': 'Dschibuti',
  'country.DM': 'Dominica',
  'country.DO': 'Dominikanische Republik',
  'country.EC': 'Ecuador',
  'country.EG': 'Ägypten',
  'country.SV': 'El Salvador',
  'country.GQ': 'Äquatorialguinea',
  'country.ER': 'Eritrea',
  'country.EE': 'Estland',
  'country.ET': 'Äthiopien',
  'country.FK': 'Falklandinseln (Malvinas)',
  'country.FO': 'Färöer-Inseln',
  'country.FJ': 'Fidschi',
  'country.FI': 'Finnland',
  'country.FR': 'Frankreich',
  'country.GF': 'Französisch-Guayana',
  'country.PF': 'Französisch-Polynesien',
  'country.TF': 'Französische Südgebiete',
  'country.GA': 'Gabun',
  'country.GM': 'Gambia',
  'country.GE': 'Georgia',
  'country.GH': 'Ghana',
  'country.GI': 'Gibraltar',
  'country.GR': 'Griechenland',
  'country.GL': 'Grönland',
  'country.GD': 'Grenada',
  'country.GP': 'Guadeloupe',
  'country.GU': 'Guam',
  'country.GT': 'Guatemala',
  'country.GG': 'Guernsey',
  'country.GN': 'Guinea',
  'country.GW': 'Guinea-Bissau',
  'country.GY': 'Guyana',
  'country.HT': 'Haiti',
  'country.HM': 'Heard Island und McDonald Islands',
  'country.VA': 'Heiliger Stuhl (Staat Vatikanstadt)',
  'country.HN': 'Honduras',
  'country.HK': 'Hongkong',
  'country.HU': 'Ungarn',
  'country.IS': 'Island',
  'country.IN': 'Indien',
  'country.ID': 'Indonesien',
  'country.IR': 'Iran, Islamische Republik',
  'country.IQ': 'Irak',
  'country.IE': 'Irland',
  'country.IM': 'Isle of Man',
  'country.IL': 'Israel',
  'country.IT': 'Italien',
  'country.JM': 'Jamaika',
  'country.JP': 'Japan',
  'country.JE': 'Jersey',
  'country.JO': 'Jordanien',
  'country.KZ': 'Kasachstan',
  'country.KE': 'Kenia',
  'country.KI': 'Kiribati',
  'country.KP': 'Korea, Demokratische Volksrepublik Korea',
  'country.KR': 'Korea, Republik',
  'country.XK': 'Kosovo',
  'country.KW': 'Kuwait',
  'country.KG': 'Kirgisistan',
  'country.LA': 'Demokratische Volksrepublik Laos',
  'country.LV': 'Lettland',
  'country.LB': 'Libanon',
  'country.LS': 'Lesotho',
  'country.LR': 'Liberia',
  'country.LY': 'Libyen',
  'country.LI': 'Liechtenstein',
  'country.LT': 'Litauen',
  'country.LU': 'Luxemburg',
  'country.MO': 'Macao',
  'country.MK': 'Mazedonien, die ehemalige jugoslawische Republik',
  'country.MG': 'Madagaskar',
  'country.MW': 'Malawi',
  'country.MY': 'Malaysia',
  'country.MV': 'Malediven',
  'country.ML': 'Mali',
  'country.MT': 'Malta',
  'country.MH': 'Marshallinseln',
  'country.MQ': 'Martinique',
  'country.MR': 'Mauretanien',
  'country.MU': 'Mauritius',
  'country.YT': 'Mayotte',
  'country.MX': 'Mexiko',
  'country.FM': 'Mikronesien, Föderierte Staaten von',
  'country.MD': 'Moldawien, Republik',
  'country.MC': 'Monaco',
  'country.MN': 'Mongolei',
  'country.ME': 'Montenegro',
  'country.MS': 'Montserrat',
  'country.MA': 'Marokko',
  'country.MZ': 'Mosambik',
  'country.MM': 'Myanmar',
  'country.NA': 'Namibia',
  'country.NR': 'Nauru',
  'country.NP': 'Nepal',
  'country.NL': 'Niederlande',
  'country.NC': 'Neukaledonien',
  'country.NZ': 'Neuseeland',
  'country.NI': 'Nicaragua',
  'country.NE': 'Niger',
  'country.NG': 'Nigeria',
  'country.NU': 'Niue',
  'country.NF': 'Norfolkinsel',
  'country.MP': 'Nördliche Marianeninseln',
  'country.NO': 'Norwegen',
  'country.OM': 'Oman',
  'country.PK': 'Pakistan',
  'country.PW': 'Palau',
  'country.PS': 'Palästinensisches Gebiet, besetzt',
  'country.PA': 'Panama',
  'country.PG': 'Papua-Neuguinea',
  'country.PY': 'Paraguay',
  'country.PE': 'Peru',
  'country.PH': 'Philippinen',
  'country.PN': 'Pitcairn',
  'country.PL': 'Polen',
  'country.PT': 'Portugal',
  'country.PR': 'Puerto Rico',
  'country.QA': 'Katar',
  'country.RE': 'Réunion',
  'country.RO': 'Rumänien',
  'country.RU': 'Russische Föderation',
  'country.RW': 'Ruanda',
  'country.BL': 'Saint Barthélemy',
  'country.SH': 'Saint Helena, Ascension and Tristan da Cunha',
  'country.KN': 'Saint Kitts and Nevis',
  'country.LC': 'Saint Lucia',
  'country.MF': 'Saint Martin (französischer Teil)',
  'country.PM': 'Saint Pierre und Miquelon',
  'country.VC': 'St. Vincent und die Grenadinen',
  'country.WS': 'Samoa',
  'country.SM': 'San Marino',
  'country.ST': 'Sao Tome und Principe',
  'country.SA': 'Saudi-Arabien',
  'country.SN': 'Senegal',
  'country.RS': 'Serbien',
  'country.SC': 'Seychellen',
  'country.SL': 'Sierra Leone',
  'country.SG': 'Singapur',
  'country.SX': 'Sint Maarten (niederländischer Teil)',
  'country.SK': 'Slowakei',
  'country.SI': 'Slowenien',
  'country.SB': 'Salomonen',
  'country.SO': 'Somalia',
  'country.ZA': 'Südafrika',
  'country.GS': 'Südgeorgien und die Süd-Sandwich-Inseln',
  'country.SS': 'Südsudan',
  'country.ES': 'Spanien',
  'country.LK': 'Sri Lanka',
  'country.SD': 'Sudan',
  'country.SR': 'Suriname',
  'country.SJ': 'Svalbard und Jan Mayen',
  'country.SZ': 'Swasiland',
  'country.SE': 'Schweden',
  'country.CH': 'Schweiz',
  'country.SY': 'Syrische Arabische Republik',
  'country.TW': 'Taiwan, Provinz China',
  'country.TJ': 'Tadschikistan',
  'country.TZ': 'Tansania, Vereinigte Republik',
  'country.TH': 'Thailand',
  'country.TL': 'Timor-Leste',
  'country.TG': 'Togo',
  'country.TK': 'Tokelau',
  'country.TO': 'Tonga',
  'country.TT': 'Trinidad und Tobago',
  'country.TN': 'Tunesien',
  'country.TR': 'Türkei',
  'country.TM': 'Turkmenistan',
  'country.TC': 'Turks- und Caicosinseln',
  'country.TV': 'Tuvalu',
  'country.UG': 'Uganda',
  'country.UA': 'Ukraine',
  'country.AE': 'Vereinigte Arabische Emirate',
  'country.GB': 'Vereinigtes Königreich',
  'country.US': 'Vereinigte Staaten',
  'country.UM': 'Kleinere abgelegene Inseln der Vereinigten Staaten',
  'country.UY': 'Uruguay',
  'country.UZ': 'Usbekistan',
  'country.VU': 'Vanuatu',
  'country.VE': 'Venezuela, Bolivarische Republik',
  'country.VN': 'Viet Nam',
  'country.VG': 'Jungferninseln, Britisch',
  'country.VI': 'Jungferninseln, USA',
  'country.WF': 'Wallis und Futuna',
  'country.EH': 'Westsahara',
  'country.YE': 'Jemen',
  'country.ZM': 'Sambia',
  'country.ZW': 'Simbabwe',
  'state.DE-BE': 'Berlin',
  'state.DE-BB': 'Brandenburg',
  'state.DE-BW': 'Baden-Württemberg',
  'state.DE-BY': 'Bayern',
  'state.DE-HB': 'Bremen',
  'state.DE-HE': 'Hessen',
  'state.DE-HH': 'Hamburg',
  'state.DE-MV': 'Mecklenburg-Vorpommern',
  'state.DE-NI': 'Niedersachsen',
  'state.DE-NW': 'Nordrhein-Westfalen',
  'state.DE-RP': 'Rheinland-Pfalz',
  'state.DE-SH': 'Schleswig-Holstein',
  'state.DE-SL': 'Saarland',
  'state.DE-SN': 'Sachsen',
  'state.DE-ST': 'Sachsen-Anhalt',
  'state.DE-TH': 'Thüringen',
  'taxConfirmation.SINGLE': 'Single',
  'taxConfirmation.ANNUALY': 'Annualy',
  'lockingStatus.NO_BLOCK': 'No Block',
  'lockingStatus.DEBIT_BLOCK': 'Debit Block',
  'lockingStatus.CREDIT_BLOCK': 'Credit Block',
  'lockingStatus.BLOCK': 'Block',
  'sector.OTHER_COMPANIES': 'Other Companies',
  'sector.OTHER_COMPANIES_WORKMAN': 'Other Companies Workman',
  'sector.FOREIGN_COMPANIES': 'Foreign Companies',
  'sector.MUNICIPALITY_AND_MUNICIPALITY_ASSOCIATION': 'Municipality And Municipality Association',
  'sector.GERMAN_BANKS': 'German Banks',
  'sector.NON_PROFIT_ORGANIZATION': 'Organisationen ohne Erwerbszweck',
  'legalRepresentativeRepresentationTypes.ALONE': 'Alone',
  'legalRepresentativeRepresentationTypes.JOINT': 'Joint',
  'legalRepresentativeRepresentationTypes.OTHER': 'Other',
  'legal_form.NONE': 'None',
  'legal_form.SELF_EMPLOYED': 'Self Employed',
  'legal_form.GBR': 'GbR',
  'legal_form.OHG': 'OHG',
  'legal_form.LTD': 'LTD',
  'legal_form.PARTG': 'PartG',
  'legal_form.KG': 'KG',
  'legal_form.UG': 'UG',
  'legal_form.SOLE_PROPRIETORSHIP': 'Einzelunternehmen',
  'legal_form.GMBH': 'GmbH',
  'legal_form.KGAA': 'KGaA',
  'legal_form.AG': 'AG',
  'legal_form.EG': 'EG',
  'legal_form.EK': 'EK',
  'legal_form.MUNICIPALITY': 'Municipality',
  'legal_form.MUNICIPAL_COMPANY': 'Municipal Company',
  'legal_form.SAVINGS_BANK': 'Savings Bank',
  'legal_form.GMBH_I_GR': 'GmbH i.G.',
  'legal_form.UG_I_GR': 'UG i.G.',
  'legal_form.FOREIGN_CORPORATION': 'Ausländische Gesellschaft',
  'legal_form.GMBH_CO_KG': 'GmbH & Co. KG',
  'legal_form.EV': 'EV',
  'legal_form.NEV': 'NEV',
  'industry.BUILDING_INDUSTRY': 'Bauindustrie',
  'industry.TRADE_MAINTENANCE_AND_REPAIR_OF_VEHICLES': 'Handel; Instandhaltung und Reparatur von Kraftfahrzeugen',
  'industry.GASTRONOMY': 'Gastronomie',
  'industry.SCIENTIFIC_TECHNICAL_CONSULTING_SERVICES':
    'Erbringung von freiberuflichen, wissenschaftlichen und technischen Dienstleistungen',
  'industry.OTHER_ECONOMIC_SERVICES': 'Erbringung von sonstigen wirtschaftlichen Dienstleistungen',
  'industry.INFORMATION_AND_COMMUNICATION': 'Information und Kommunikation',
  'industry.OTHER_SERVICES': 'Andere Branche',
  'industry.NONE': 'Nicht vorhanden',
  'industry.FARMER_FORESTRY_FISHING_AND_AQUACULTURE': 'Land/Forstwirtsch./Fischerei und Aquakultur',
  'industry.POWER_WATER_SUPPLY_DISPOSAL_MINING': 'Energie u. Wasservers.; Entsorgung; Bergbau',
  'industry.CHEMICAL_INDUSTRY_COKE_OVEN_MINERAL_OIL': 'Chem.Industrie/Kokerei/Mineralöl',
  'industry.MANUFACTURE_PLASTICS_RUBBER_GOODS': 'Herstellung: Kunststoffe/Gummiwaren',
  'industry.STONE_SOIL_CERAMICS_GLASS': 'Steine, Erden/Keramik/Glas',
  'industry.METAL_PRODUCTION_PROCESSING': 'Metallerzeug.-bearbeit./Metallerzeugnisse',
  'industry.MECHANICAL_ENGINEERING_VEHICLE_MANUFACTURING': 'Maschinenbau/Fahrzeugbau',
  'industry.MANUFACTURE_DATA_PROC_EQUIPMENT_ELECTRIC_OPTICS': 'Herst. v. DV-geräten/elektr. u. opt. Erzeugnissen',
  'industry.WOOD_PAPER_PRINT_INDUSTRY_FURNITURE_ETC': 'Holz-/Papier-/Druckgewerbe; Möbel etc.',
  'industry.LEATHER_TEXTILE_CLOTHES_INDUSTRY': 'Leder-/Textil-/Bekleidung-gewerbe',
  'industry.FOOD_INDUSTRY_TOBACCO_PRODUCTS': 'Ernährungsgew./Tabakverarb.',
  'industry.TRANSPORT_AND_STORAGE_AND_NEWS_TRANSMISSION': 'Verkehr und Lagerei; Nachrichtenüberm.',
  'industry.FINANCIAL_LEASING_COMPANIES': 'Finanz./Leasinggesellsch.',
  'industry.HOUSING_COMPANIES': 'Wohnungsunternehmen',
  'industry.HOLDING_COMPANIES': 'Holdinggesellschaften',
  'industry.OTHER_PROPERTY_MANAGEMENT': 'Sonst. Grundstückswesen',
  'industry.HEALTH_CARE_VETERIN_A_WELFARE_FIRM_FREELANCE': 'Gesundheits-,Veterinär- u. Sozialwesen (Unt.+Frei)',
  'industry.LEASING_OF_MOVABLE_OBJECTS': 'Vermietung bewegl. Sachen',
  'industry.NON_MARKET_PRODUCERS': 'Nichtmarktproduzenten',
  'industry.NON_PROFIT_ORGANIZATION': 'Organisationen ohne Erwerbszweck',
  'industry.ORGANIZATION_WITHOUT_ACQUIRING_HOUSE_BUILDING': 'Organization Without Acquiring House/Building',
  'industry_key.NONE': 'Nicht vorhanden',
  // tslint:disable-next-line:max-line-length
  'industry_key.WHOLESALE_TRADE_EXCEPT_OF_MOTOR_VEHICLES_AND_MOTORCYCLES':
    'Großhandel (ohne Handel mit Kraftfahrzeugen)',
  // tslint:disable-next-line:max-line-length
  'industry_key.RETAIL_TRADE_EXCEPT_OF_MOTOR_VEHICLES_AND_MOTORCYCLES':
    'Einzelhandel (ohne Handel mit Kraftfahrzeugen)',
  'industry_key.FOOD_AND_BEVERAGE_SERVICE_ACTIVITIES': 'Gastronomie',
  'industry_key.GAMBLING_AND_BETTING_ACTIVITIES': 'Spiel-, Wett- und Lotteriewesen',
  'industry_key.CONSTRUCTION_OF_BUILDINGS': 'Hochbau',
  'industry_key.CIVIL_ENGINEERING': 'Tiefbau',
  'industry_key.SPECIALISED_CONSTRUCTION_ACTIVITIES':
    'Vorbereitende Baustellenarbeiten, Bauinstallation und sonstiges Ausbaugewerbe',
  // tslint:disable-next-line:max-line-length
  'industry_key.WHOLESALE_AND_RETAIL_TRADE_AND_REPAIR_OF_MOTOR_VEHICLES_AND_MOTORCYCLES':
    'Handel mit Kraftfahrzeugen, Instandhaltung und Reparatur von Kraftfahrzeugen',
  'industry_key.EDUCATION': 'Erziehung und Unterricht',
  'industry_key.CREATIVE_ARTS_AND_ENTERTAINMENT_ACTIVITIES': 'Kreative, künstlerische und unterhaltende Tätigkeiten',
  'industry_key.LIBRARIES_ARCHIVES_MUSEUMS_AND_OTHER_CULTURAL_ACTIVITIES':
    'Bibliotheken, Archive, Museen, botanische und zoologische Gärten',
  'industry_key.SPORTS_ACTIVITIES_AND_AMUSEMENT_AND_RECREATION_ACTIVITIES':
    'Erbringung von Dienstleistungen des Sports, der Unterhaltung und der Erholung',
  'industry_key.OTHER_PERSONAL_SERVICE_ACTIVITIES':
    'Erbringung von sonstigen überwiegend persönlichen Dienstleistungen',
  'industry_key.ACCOMMODATION': 'Beherbergung: Hotels, Gasthöfe und Pensionen',
  'industry_key.PUBLISHING_ACTIVITIES': 'Verlagswesen',
  // tslint:disable-next-line:max-line-length
  'industry_key.MOTION_PICTURE_VIDEO_AND_TELEVISION_PROGRAMME_PRODUCTION_SOUND_RECORDING_AND_MUSIC_PUBLISHING_ACTIVITIES':
    'Herstellung, Verleih, Vertrieb von Filmen / Fernsehprogrammen; Kinos; Tonstud. / Verlegen von Musik',
  'industry_key.PROGRAMMING_AND_BROADCASTING_ACTIVITIES': 'Rundfunkveranstalter',
  'industry_key.TELECOMMUNICATIONS': 'Telekommunikation',
  'industry_key.COMPUTER_PROGRAMMING_CONSULTANCY_AND_RELATED_ACTIVITIES':
    'Erbringung von Dienstleistungen der Informationstechnologie',
  'industry_key.INFORMATION_SERVICE_ACTIVITIES': 'Informationsdienstleistungen',
  'industry_key.LEGAL_AND_ACCOUNTING_ACTIVITIES': 'Rechts- und Steuerberatung, Wirtschaftsprüfung',
  'industry_key.MANAGEMENT_CONSULTANCY_ACTIVITIES': 'Public-Relations- und Unternehmensberatung',
  // tslint:disable-next-line:max-line-length
  'industry_key.ARCHITECTURAL_AND_ENGINEERING_ACTIVITIES_TECHNICAL_TESTING_AND_ANALYSIS':
    'Architektur- und Ingenieurbüros, technische, physikalische und chemische Untersuchung',
  'industry_key.SCIENTIFIC_RESEARCH_AND_DEVELOPMENT': 'Forschung und Entwicklung',
  'industry_key.ADVERTISING_AND_MARKET_RESEARCH': 'Werbung und Marktforschung',
  'industry_key.OTHER_PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_ACTIVITIES':
    'Sonstige freiberufliche, wissenschaftliche und technische Tätigkeiten',
  'industry_key.EMPLOYMENT_ACTIVITIES': 'Vermittlung und Überlassung von Arbeitskräften',
  'industry_key.SECURITY_AND_INVESTIGATION_ACTIVITIES': 'Wach- und Sicherheitsdienste sowie Detekteien',
  // tslint:disable-next-line:max-line-length
  'industry_key.OFFICE_ADMINISTRATIVE_OFFICE_SUPPORT_AND_OTHER_BUSINESS_SUPPORT_ACTIVITIES':
    'Erbringung von wirtschaftlichen Dienstleistungen für Unternehmen und Privatpersonen, a.n.g.',
  'industry_key.OWN_ASSET_MANAGEMENT': 'Eigene Vermögensverwaltung',
  // tslint:disable-next-line:max-line-length
  'industry_key.ACTIVITIES_OF_BUSINESS_EMPLOYERS_AND_PROFESSIONAL_MEMBERSHIP_ORGANISATIONS':
    'Interessenvertretungen (ohne Organisationen ohne Erwerbszweck)',
  'industry_key.CROP_AND_ANIMAL_PRODUCTION_HUNTING_AND_RELATED_SERVICE_ACTIVITIES':
    'Landwirtschaft, Jagd und damit verbundene Tätigkeiten',
  'industry_key.FORESTRY_AND_LOGGING': 'Forstwirtschaft und Holzeinschlag',
  'industry_key.FISHING_AND_AQUACULTURE': 'Fischerei und Aquakultur',
  'industry_key.MINING_OF_COAL_AND_LIGNITE': 'Kohlenbergbau',
  'industry_key.EXTRACTION_OF_CRUDE_PETROLEUM_AND_NATURAL_GAS': 'Gewinnung von Erdöl und Erdgas',
  'industry_key.MINING_OF_METAL_ORES': 'Erzbergbau',
  'industry_key.OTHER_MINING_AND_QUARRYING': 'Gewinnung von Steinen und Erden, sonstiger Bergbau',
  'industry_key.ELECTRICITY_GAS_STEAM_AND_AIR_CONDITIONING_SUPPLY': 'Energieversorgung',
  'industry_key.WATER_COLLECTION_TREATMENT_AND_SUPPLY': 'Wasserversorgung',
  'industry_key.SEWERAGE': 'Abwasserentsorgung',
  'industry_key.WASTE_COLLECTION_TREATMENT_AND_DISPOSAL_ACTIVITIES_MATERIALS_RECOVERY':
    'Sammlung, Behandlung und Beseitigung von Abfällen, Rückgewinnung',
  'industry_key.REMEDIATION_ACTIVITIES_AND_OTHER_WASTE_MANAGEMENT_SERVICES':
    'Beseitigung von Umweltverschmutzungen und sonstige Entsorgung',
  'industry_key.MANUFACTURE_OF_COKE_AND_REFINED_PETROLEUM_PRODUCTS': 'Kokerei und Mineralölverarbeitung',
  'industry_key.MANUFACTURE_OF_CHEMICALS_AND_CHEMICAL_PRODUCTS': 'Herstellung von chemischen Erzeugnissen',
  'industry_key.MANUFACTURE_OF_BASIC_PHARMACEUTICAL_PRODUCTS_AND_PHARMACEUTICAL_PREPARATIONS':
    'Herstellung von pharmazeutischen Erzeugnissen',
  'industry_key.MANUFACTURE_OF_RUBBER_AND_PLASTIC_PRODUCTS': 'Herstellung von Gummi- und Kunststoffwaren',
  'industry_key.MANUFACTURE_OF_PRECIOUS_METALS_OR_STONES': 'Herstellung von Edelmetallen oder Steinen',
  'industry_key.MANUFACTURE_OF_BASIC_METALS': 'Metallerzeugung und -bearbeitung',
  'industry_key.MANUFACTURE_OF_FABRICATED_METAL_PRODUCTS_EXCEPT_MACHINERY_AND_EQUIPMENT':
    'Herstellung von Metallerzeugnissen',
  'industry_key.MANUFACTURE_OF_MACHINERY_AND_EQUIPMENT_N_E_C': 'Maschinenbau',
  'industry_key.MANUFACTURE_OF_MOTOR_VEHICLES_TRAILERS_AND_SEMI_TRAILERS':
    'Herstellung von Kraftwagen und Kraftwagenteilen',
  'industry_key.MANUFACTURE_OF_OTHER_TRANSPORT_EQUIPMENT': 'Sonstiger Fahrzeugbau',
  'industry_key.REPAIR_AND_INSTALLATION_OF_MACHINERY_AND_EQUIPMENT':
    'Reparatur und Installation von Maschinen und Ausrüstungen',
  'industry_key.MANUFACTURE_OF_COMPUTER_ELECTRONIC_AND_OPTICAL_PRODUCTS':
    'Herstellung von Datenverarbeitungsgeräten, elektronischen und optischen Erzeugnissen',
  'industry_key.MANUFACTURE_OF_ELECTRICAL_EQUIPMENT': 'Herstellung von elektrischen Ausrüstungen',
  'industry_key.MANUFACTURE_OF_WOOD_AND_OF_PRODUCTS_OF_WOOD_AND_CORK_EXCEPT_FURNITURE_MANUFACTURE_OF_ARTICLES_OF_STRAW_AND_PLAITING_MATERIALS':
    'Herstellung von Holz-, Flecht-, Korb- und Korkwaren (ohne Möbel)',
  'industry_key.MANUFACTURE_OF_PAPER_AND_PAPER_PRODUCTS': 'Herstellung von Papier, Pappe und Waren daraus',
  'industry_key.PRINTING_AND_REPRODUCTION_OF_RECORDED_MEDIA':
    'Herstellung von Druckerzeugnissen, Vervielfältigung von bespielten Ton-, Bild- und Datenträgern',
  'industry_key.MANUFACTURE_OF_FURNITURE': 'Herstellung von Möbeln',
  'industry_key.OTHER_MANUFACTURING': 'Herstellung von sonstigen Waren',
  'industry_key.MANUFACTURE_OF_TEXTILES': 'Herstellung von Textilien',
  'industry_key.MANUFACTURE_OF_WEARING_APPAREL': 'Herstellung von Bekleidung',
  'industry_key.MANUFACTURE_OF_LEATHER_AND_RELATED_PRODUCTS': 'Herstellung von Leder, Lederwaren und Schuhen',
  'industry_key.MANUFACTURE_OF_FOOD_PRODUCTS': 'Herstellung von Nahrungs- und Futtermitteln',
  'industry_key.MANUFACTURE_OF_BEVERAGES': 'Getränkeherstellung',
  'industry_key.MANUFACTURE_OF_TOBACCO_PRODUCTS': 'Tabakverarbeitung',
  'industry_key.LAND_TRANSPORT_AND_TRANSPORT_VIA_PIPELINES': 'Landverkehr und Transport in Rohrfernleitungen',
  'industry_key.WATER_TRANSPORT': 'Schiff-Fahrt',
  'industry_key.AIR_TRANSPORT': 'Luftfahrt',
  'industry_key.WAREHOUSING_AND_SUPPORT_ACTIVITIES_FOR_TRANSPORTATION':
    'Lagerei sowie Erbringung von sonstigen Dienstleistungen für den Verkehr',
  'industry_key.POSTAL_AND_COURIER_ACTIVITIES': 'Post-, Kurier- und Expressdienste',
  'industry_key.TRAVEL_AGENCY_TOUR_OPERATOR_AND_OTHER_RESERVATION_SERVICE_AND_RELATED_ACTIVITIES':
    'Reisebüros, Reiseveranstalter und Erbringung sonstiger Reservierungsdienstleistungen',
  'industry_key.FINANCIAL_LEASING': 'Institutionen für Finanzierungsleasing',
  'industry_key.BUYING_AND_SELLING_OF_OWN_RESIDENTIAL_REAL_ESTATE': 'Wohnungsunternehmen',
  'industry_key.ACTIVITIES_OF_HEAD_OFFICES':
    'Management-Holdinggesellschaften mit überwiegend nicht finanziellem Anteilsbesitz',
  'industry_key.BUYING_AND_SELLING_OF_OWN_NON_RESIDENTIAL_REAL_ESTATE': 'Sonstiges Grundstückswesen',
  'industry_key.SERVICES_TO_BUILDINGS_AND_LANDSCAPE_ACTIVITIES': 'Gebäudebetreuung; Garten- und Landschaftsbau',
  'industry_key.VETERINARY_ACTIVITIES': 'Veterinärwesen',
  'industry_key.HUMAN_HEALTH_ACTIVITIES': 'Gesundheitswesen',
  'industry_key.RESIDENTIAL_CARE_ACTIVITIES': 'Heime (ohne Erholungs- und Ferienheime)',
  'industry_key.SOCIAL_WORK_ACTIVITIES_WITHOUT_ACCOMMODATION': 'Sozialwesen (ohne Heime)',
  'industry_key.RENTAL_AND_LEASING_ACTIVITIES': 'Vermietung von beweglichen Sachen',
  'industry_key.PRIVATE_NON_PROFIT_ORGANIZATIONS': 'Private Organisationen ohne Erwerbszweck',
  'salutation.MR': 'Herr',
  'salutation.MS': 'Frau',
  'salutation.DIV': 'Divers',
  'title.DR': 'Dr.',
  'title.MAGISTER': 'Magister',
  'title.PROF': 'Prof.',
  'title.PROF_DR': 'Prof. Dr.',
  'employment_status.EMPLOYED': 'Angestellte/r',
  'employment_status.PUBLIC_SECTOR_EMPLOYEE': 'Mitarbeiter im öffentlichen Dienst',
  'employment_status.PROFESSIONAL_SOLDIER': 'Soldat',
  'employment_status.FREELANCER': 'Freiberufler',
  'employment_status.HOUSEWORK': 'Hausarbeit',
  'employment_status.APPRENTICE': 'Auszubildende/r',
  'employment_status.MANAGEMENT': 'Management',
  'employment_status.UNEMPLOYED': 'Arbeitslos',
  'employment_status.RETIRED': 'Pensioniert',
  'employment_status.STUDENT': 'Student',
  'employment_status.SELF_EMPLOYED': 'Selbständige/r',
  'employment_status.MILITARY_OR_COMMUNITY_SERVICE': 'Militär- oder Zivildienst',
  'tax_assessment.NONE': 'None',
  'tax_assessment.SEPARATE': 'Separate',
  'tax_assessment.JOINT': 'Joint',
  'marital_status.DIVORCED': 'Geschieden',
  'marital_status.MARRIED': 'Verheiratet',
  'marital_status.SINGLE': 'Ledig',
  'marital_status.WIDOWED': 'Verwitwet',
  'pageSectionTitle.birthDetails': 'Birth Details',
  'pageSectionTitle.taxInformation': 'Tax Information',
  'pageSectionTitle.personDetails': 'Person Details',
  'pageSectionTitle.personAccountDetails': 'Person Account Details',
  'pageSectionTitle.businessAccountDetails': 'Business Account Details',
  'pageSectionTitle.primaryAddress': 'Primary Address',
  'pageSectionTitle.contactAddress': 'Contact Address',
  'pageSectionTitle.createPerson': 'Create Person',
  'pageSectionTitle.allPersons': 'All Persons',
  'pageSectionTitle.allBusinesses': 'All Businesses',
  'pageSectionTitle.personIdentifications': 'Person Identifications',
  'pageSectionTitle.personIdentificationDetails': 'Person Identification Details',
  'pageSectionTitle.createAccount': 'Create Account',
  'pageSectionTitle.accounts': 'Accounts',
  'pageSectionTitle.createBusiness': 'Create Business',
  'pageSectionTitle.createPartner': 'Create Partner',
  'pageSectionTitle.createApplication': 'Create Application',
  'pageSectionTitle.createUser': 'Create User',
  'pageSectionTitle.updateApplication': 'Update Application',
  'pageSectionTitle.selectApplicationName': 'Select Application name',
  'pageSectionTitle.userDetails': 'User Details',
  'pageSectionTitle.updateUser': 'Update User',
  'pageSectionTitle.authorizedPersons': 'Authorized Persons',
  'pageSectionTitle.reservations': 'Reservations',
  'pageSectionTitle.businessIdentifications': 'Business Identifications',
  'pageSectionTitle.taxIdentifications': 'Tax Identifications',
  'pageSectionTitle.updateBusinessLegalRepresentative': 'Update Business Legal Representative',
  'pageSectionTitle.updateBusinessAccountAuthorizedPerson': 'Business Account Authorized Person Update',
  'columnLabel.decline_reason': 'Decline Reason',
  'columnLabel.amount': 'Amount',
  'columnLabel.expired_at': 'Expired At',
  'columnLabel.expiresAt': 'Expires At',
  'columnLabel.status': 'Status',
  'columnLabel.expiresAtMin': 'Expires At (min)',
  'columnLabel.expiresAtMax': 'Expires At (max)',
  'columnLabel.reservation_type': 'Reservation Type',
  'columnLabel.reference': 'Reference',
  'columnLabel.valuta_date': 'Valuta Date',
  'columnLabel.creation_date': 'Creation Date',
  'columnLabel.booking_date': 'Booking Date',
  'columnLabel.bookingDateMin': 'Booking Date (min)',
  'columnLabel.bookingDateMax': 'Booking Date (max)',
  'protectedForm.leaveComfirmation': 'You have made changes to this form. Do you want to leave anyway?',
  'columnLabel.booking_type': 'Booking Type',
  'columnLabel.id': 'Id',
  'columnLabel.resolved_at': 'Resolved At',
  'columnLabel.description': 'Description',
  'columnLabel.createdAt': 'Created At',
  'columnLabel.settlement_amount': 'Settlement Amount',
  'columnLabel.recipient_bic': 'Recipient BIC',
  'columnLabel.recipient_iban': 'Recipient IBAN',
  'columnLabel.recipient_name': 'Recipient Name',
  'columnLabel.sender_bic': 'Sender BIC',
  'columnLabel.sender_iban': 'Sender IBAN',
  'columnLabel.sender_name': 'Sender Name',
  'columnLabel.end_to_end_id': 'End to end Id',
  'columnLabel.creditor_identifier': 'Creditor Identifier',
  'columnLabel.mandate_reference': 'Mandate Reference',
  'columnLabel.transaction_id': 'Transaction Id',
  'columnLabel.authorization_amount': 'Auth. Amount',
  'columnLabel.authorization_response': 'Auth. Response',
  'columnLabel.authorization_datetime': 'Auth. Date',
  'columnLabel.expiry_date': 'Expiry Date',
  'columnLabel.merchant_country': 'Merchant Country',
  'columnLabel.merchant_name': 'Merchant Name',
  'columnLabel.original_amount': 'Original Amount',
  'columnLabel.original_currency': 'Original Currency',
  'columnLabel.transaction_date': 'Transaction Date',
  'columnLabel.transaction_type': 'Transaction Type',
  'columnLabel.pos_entry_mode': 'Pos Entry Mode',
  'columnLabel.person_name': 'Person Name',
  'columnLabel.valid_from': 'Valid From',
  'columnLabel.valid_until': 'Valid Until',
};

export default common;
