import { modals } from './redux/modals-reducer';
import { ModuleConfig } from './../../models/module';

export * from './redux/modals-actions';

const MODALS_MODULE_NAME = 'modalsModule';

export const config: ModuleConfig = {
  moduleName: MODALS_MODULE_NAME,
  requires: [],
  models: {},
  redux: {
    reducer: {
      modals,
    },
  },
  listenerSagas: [],
  routes: {},
};
