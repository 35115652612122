import { connect } from 'react-redux';
import { submit, formValueSelector, getFormValues } from 'redux-form';
import { BUSINESS_LEGAL_REPRESENTATIVE_RESOURCE_NAME } from '../models/business-legal-representative';
import { AppState } from '../../../types';
import BusinessInfoPageContainer from '../components/business-legal-representative-page';

const personalFormSelector = formValueSelector('aboutYou');
const businessFormSelector = formValueSelector('businessInfo');

const mapStateToProps = (state: AppState) => ({
  first_name: personalFormSelector(state, 'person.first_name'),
  business_name: businessFormSelector(state, 'name'),
  formValues: getFormValues(BUSINESS_LEGAL_REPRESENTATIVE_RESOURCE_NAME)(state),
});

export const mapDispatchToProps = {
  submit,
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessInfoPageContainer);
