import * as React from 'react';
import * as classnames from 'classnames';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as UploadIcon } from '../icons/upload.svg';
import './file-input.css';

interface FileInputProps {
  input: WrappedFieldInputProps;
  meta: WrappedFieldMetaProps<{}>;
  label?: string;
  placeholder?: string;
}

class FileInput extends React.Component<FileInputProps> {
  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { input } = this.props;
    if (e.target.files && e.target.files.length) {
      input.onChange(e);
    }
  };
  render() {
    const {
      input: { value, name },
      label,
      placeholder,
    } = this.props;
    const { touched, error } = this.props.meta;
    return (
      <div
        className={classnames('file-input', {
          'file-input--invalid': touched && error,
        })}
      >
        <label className="file-input__label">{label}</label>
        <div className="file-input__wrapper">
          <input
            id={'fileinput-' + name}
            className="file-input__input"
            type="file"
            accept=".pdf,.jpg,.jpeg,.png"
            onChange={this.onChange}
          />
          <label htmlFor={'fileinput-' + name} className="file-input__button">
            <UploadIcon className="file-input__icon " />
            <FormattedMessage id="fieldLabel.upload_file" />
          </label>
          <div className="file-input__file-name">{value && value.length ? value[0].name : placeholder}</div>
        </div>
        {touched && (
          <div className="file-input__feedback">{error && <div className="file-input__error">{error}</div>}</div>
        )}
      </div>
    );
  }
}

export default FileInput;
