import * as React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import ShareholderForm from '../../../patterns/molecules/shareholder-form';

interface ModalShareholderProps extends InjectedIntlProps {
  setShareholder: (value: boolean) => void;
  hideModal: () => void;
  hasShareholder: boolean;
}

class ModalShareholderComponent extends React.PureComponent<ModalShareholderProps, {}> {
  setShareholder = (value: number) => {
    const { hasShareholder, setShareholder, hideModal } = this.props;
    const formHasShareholderValue = value === 0;
    if (hasShareholder !== formHasShareholderValue) {
      setShareholder(value === 0);
    }
    hideModal();
  };

  render(): JSX.Element {
    const {
      intl: { formatMessage },
    } = this.props;
    return (
      <ShareholderForm
        onConfirmClick={this.setShareholder}
        title={formatMessage({ id: 'pages.shareholder_modal.title' })}
        button={formatMessage({ id: 'pages.shareholder_modal.button' })}
        options={[
          formatMessage({ id: 'pages.shareholder_modal.option_yes' }),
          formatMessage({ id: 'pages.shareholder_modal.option_no' }),
        ]}
        active={this.props.hasShareholder ? 0 : 1}
      />
    );
  }
}

export default injectIntl(ModalShareholderComponent);
