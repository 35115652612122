import * as React from 'react';
import ModalHabitat from '../../../patterns/habitats/modal';
import Overlay from '../../../patterns/atoms/overlay';

interface ModalProps {
  hideModal: () => void;
}

class Modal extends React.Component<ModalProps, {}> {
  listenKeyboard = (event: KeyboardEvent) => {
    if (event.key === 'Escape' || event.keyCode === 27) {
      this.props.hideModal();
    }
  };

  componentDidMount() {
    if (this.props.hideModal) {
      window.addEventListener('keydown', this.listenKeyboard, true);
    }
  }

  componentWillUnmount() {
    if (this.props.hideModal) {
      window.removeEventListener('keydown', this.listenKeyboard, true);
    }
  }

  public render(): JSX.Element {
    return (
      <div>
        <Overlay onClick={this.props.hideModal} />
        <ModalHabitat>{this.props.children}</ModalHabitat>
      </div>
    );
  }
}

export default Modal;
