import * as React from 'react';
import './sidebar-check-list.css';
import { ReactComponent as CheckIcon } from '../icons/check-icon.svg';

export interface PersonProps {}

const SidebarCheckList: React.StatelessComponent<PersonProps> = ({ children }) => (
  <div className="check-list">
    <CheckIcon className="check-list__check-icon" />
    <span className="check-list__text">{children}</span>
  </div>
);

export default SidebarCheckList;
