interface SanitizedQuery {
  [key: string]: string | number;
}

interface RawQuery {
  [key: string]: string;
}

export function sanitizeQueryParameters(query?: RawQuery): SanitizedQuery {
  if (!query) {
    return {};
  }

  const sanitizedQuery = Object.keys(query).reduce((prev, currentQueryKey) => {
    const currentQueryValue =
      ['page[size]', 'page[number]'].indexOf(currentQueryKey) !== -1
        ? parseInt(query[currentQueryKey], 10)
        : query[currentQueryKey];
    return { ...prev, [currentQueryKey]: currentQueryValue };
  }, {});
  return sanitizedQuery;
}
