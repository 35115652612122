import Identification from '../components/identification';
import { connect } from 'react-redux';
import { AppState } from '../../../types';
import { selectIdNowUrl } from '../../../selectors';

export const mapStateToProps = (state: AppState) => ({
  url: `${selectIdNowUrl(state)}/webcam`,
});

export default connect(mapStateToProps)(Identification);
