import { ModuleConfig } from './../../models/module';
import businessAccountConfig, { BUSINESS_DOCUMENT_RESOURCE_NAME } from './models/business-document';

export const config: ModuleConfig = {
  moduleName: BUSINESS_DOCUMENT_RESOURCE_NAME,
  requires: [],
  models: {
    [BUSINESS_DOCUMENT_RESOURCE_NAME]: businessAccountConfig,
  },
  redux: {},
  listenerSagas: [],
  routes: {},
};
