import { StandardAction, RouterHookParams, HashMap } from '../types';
import { getRoutes } from '../config';

const URI = require('urijs');
const URITemplate = require('urijs/src/URITemplate');
const invariant = require('invariant');

// Redux actions

export const ON_ENTER = 'router/ON_ENTER';
export const ON_CHANGE = 'router/ON_CHANGE';

// when entering a route, this action will be fired
export interface RouterOnEnterAction extends StandardAction<RouterHookParams> {
  readonly type: 'router/ON_ENTER';
}

export function onEnter(payload: RouterHookParams): RouterOnEnterAction {
  return {
    type: ON_ENTER,
    payload,
  };
}

// when changing a route like adding GET parameters, this action will be fired
export interface RouterOnChangeAction extends StandardAction<RouterHookParams> {
  readonly type: 'router/ON_CHANGE';
}

export function onChange(payload: RouterHookParams): RouterOnChangeAction {
  return {
    type: ON_CHANGE,
    payload,
  };
}

interface RouteParams {
  queryParams?: HashMap<string | number>;
  pathParams?: HashMap<string | number>;
}

export function createPathByRouteName(name: string, routerParams?: RouteParams): string {
  const routesConfig = getRoutes();
  invariant(routesConfig[name], `No path specified for route name ${name}.`);

  const pathTemplate = routesConfig[name]!.path;
  const pathParams = routerParams ? routerParams.pathParams : {};
  const queryParams = routerParams ? routerParams.queryParams : {};

  // check for path variables that are required by the template but not provided in pathParams
  if (!hasRequiredPathParams(pathTemplate, pathParams)) {
    console.warn(`Error: The path template ${pathTemplate} is missing the requried
                  path parametres in ${JSON.stringify(pathParams)}`);
    return '';
  }

  const template = new URITemplate(pathTemplate);
  const url = template.expand(pathParams);
  // Adding undefined queryParams as search returns undefined url
  return queryParams
    ? URI(url)
        .query(queryParams)
        .toString()
    : url;
}

export function getTemplateVariables(pathTemplate: string): string[] | undefined {
  const matches = pathTemplate.match(/\{(.*?)\}/g);
  if (!matches) {
    return;
  }
  return matches.map(pathVar => pathVar.replace('}', '').replace('{', ''));
}

export function hasRequiredPathParams(template: string, pathParams: HashMap<string | number> | undefined): boolean {
  const templateVars = getTemplateVariables(template);
  if (!templateVars) {
    return true;
  }
  const missingPathParams = templateVars.filter(templateVar => !!pathParams && !pathParams.hasOwnProperty(templateVar));

  if (missingPathParams.length > 0) {
    // tslint:disable-next-line:max-line-length
    console.warn(
      `Missing variables. The provided template "${template}" misses these variables: ${missingPathParams.join(', ')}.`,
    );
    return false;
  }
  return true;
}
