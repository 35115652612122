import 'normalize.css';
import * as React from 'react';
import './app.css';

class AppHabitat extends React.Component<{}, {}> {
  render() {
    return <div className="s-app-habitat">{this.props.children}</div>;
  }
}

export default AppHabitat;
