import { connect } from 'react-redux';
import { getFormValues, submit } from 'redux-form';
import { AppState } from '../../../types';
import ReviewPageContainer from '../components/review-page/review-page';
import {
  selectDecoratedBeneficialOwners,
  selectHasBusinessAsLegalRepresentative,
  selectBusinessLegalRepForm,
} from '../../../selectors';
import { LEGAL_REPRESENTATIVE_FORM } from '../../legalRepresentative/models/legal-representative';
import { BUSINESS_INFO_FORM_NAME } from '../../businessInfo/models/business-info';
const aboutFormSelector = getFormValues('aboutYou');
const businessFormSelector = getFormValues(BUSINESS_INFO_FORM_NAME);
const legalRepresentativeFormSelector = getFormValues(LEGAL_REPRESENTATIVE_FORM);

export const mapDispatchToProps = {
  submit,
};

export const mapStateToProps = (state: AppState) => ({
  aboutForm: aboutFormSelector(state),
  businessForm: businessFormSelector(state),
  legalRepresentatives: legalRepresentativeFormSelector(state),
  beneficialOwners: selectDecoratedBeneficialOwners(state),
  hasBusinessAsLegalRep: selectHasBusinessAsLegalRepresentative(state),
  businessLegalRep: selectBusinessLegalRepForm(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewPageContainer);
