// tslint:disable:max-line-length
const forms = {
  'form.steps.you': 'Sie',
  'form.steps.your_business': 'Unternehmen',
  'form.steps.legal_representative': 'Gesetzliche Vertreter',
  'form.steps.beneficial_owner': 'Wirtschaftlich Berechtigte',
  'form.steps.verification': 'Verifizierung',
  'forms.select_placeholder': '—Bitte auswählen',
  'forms.required_fields_note': 'Die mit einem Sternchen (*) markierten Felder sind Pflichtfelder',
  'forms.business_info.title': 'Bitte geben Sie Informationen zu Ihrem Unternehmen an',
  'forms.business_info.business_name': 'Firmenname*',
  'forms.business_info.legal_form': 'Rechtsform*',
  'forms.business_info.foundation_date': 'Gründungsdatum*',
  'forms.business_info.foundation_date_tip': 'Bitte verwenden Sie das Unterschriftsdatum des Gesellschaftsvertrag',
  'forms.business_info.industry': 'Industrie*',
  'forms.business_info.industry_key': 'Branchenschlüssel*',
  'forms.business_info.tax_country': 'Land der Firmengründung*',
  'forms.business_info.registration_number': 'Handelsregisternummer*',
  'forms.business_info.registration_issuer': 'Handelsregisteramt*',
  'forms.business_info.business_purpose': 'Unternehmenszweck*',
  'forms.business_info.business_purpose_placeholder':
    '"Under the Roof GmbH" entwickelt, unterstützt und betreut die rechtliche und technische Infrastruktur, die digitale Kreativität, Austausch und Innovation maximiert.',
  'forms.business_info.business_purpose_example': 'Beschreiben Sie den Zweck Ihres Unternehmens',
  'forms.business_info.website': 'Website',
  'forms.business_info.shareholders_agreement': 'Gesellschaftsvertrag* (zulässige Formate: .pdf, .jpg, .jpeg, .png)',
  'forms.business_info.no_file_selected': 'Keine Datei ausgewählt',
  'forms.button.login': 'Login',
  'forms.legal_representative.title':
    'Bitte geben Sie Informationen zu den gesetzlichen Vertretern Ihres Unternehmens an',
  'forms.legal_representative.you': 'Sie',
  'forms.legal_representative.input_title': 'Titel*',
  'forms.legal_representative.input_title_placeholder': 'Herr, Frau…',
  'forms.legal_representative.first_name': 'Vorname*',
  'forms.legal_representative.last_name': 'Name*',
  'forms.legal_representative.email_address': 'E-Mail-Adresse*',
  'forms.legal_representative.me_also_beneficial_owner': 'Ich besitze mehr als 25% des Unternehmens.',
  'forms.legal_representative.also_beneficial_owner': 'Diese Person besitzt mehr als 25% des Unternehmens.',
  'forms.legal_representative.beneficial_owner_note':
    'Ein wirtschaftlich Berechtigter ist eine natürliche Person, die mehr als 25% des Unternehmens besitzt.',
  'forms.legal_representative.add_person': 'Person hinzufügen',
  'forms.personal_info.title': 'Bitte geben Sie Informationen zu Ihrer Person an',
  'forms.personal_info.input_title': 'Titel*',
  'forms.personal_info.input_title_placeholder': 'Herr, Frau',
  'forms.personal_info.first_name': 'Vorname*',
  'forms.personal_info.last_name': 'Name*',
  'forms.personal_info.email_address': 'E-Mail Adresse*',
  'forms.personal_info.mobile_phone': 'Mobiltelefonnummer*',
  'forms.personal_info.also_legal_representative': 'Ich bin ein gesetzlicher Vertreter des Unternehmens.*',
  'forms.personal_info.also_legal_representative_hint':
    'Ein gesetzlicher Vertreter ist eine Person, die im Namen des Unternehmens handelt und es rechtlich vertritt.',
  'forms.beneficial_owner.address': 'Adresse*',
  'forms.beneficial_owner.postal_code': 'Postleitzahl*',
  'forms.beneficial_owner.city': 'Stadt*',
  'forms.beneficial_owner.country': 'Land*',
  'forms.beneficial_owner.date_of_birth': 'Geburtsdatum*',
  'forms.beneficial_owner.percentage_of_shares': 'Prozentualer Anteil der Unternehmensbeteiligung*',
  'forms.beneficial_owner.add_person': 'Person hinzufügen',
  'forms.email': 'Email',
  'forms.error.login': 'Der Login ist fehlgeschlagen. Fehlermeldung: {error}',
  'forms.password': 'Passwort',
};

export default forms;
