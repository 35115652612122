import { call, put, take, takeEvery, select, all } from 'redux-saga/effects';
import { getFormValues } from 'redux-form';
import { ResourceResponse, Person } from '../../../types';
import personsConfig, { PersonNumber } from '../models/personal-info';
import {
  PersonsCreateResponse,
  CREATE_PERSON_ACTION,
  UPDATE_PERSON_NUMBER_ACTION,
  createPersonSuccess,
  updatePersonNumberSuccess,
  personsRequestFailure,
  createPerson as createPersonAction,
  updatePersonNumber as updatePersonNumberAction,
  CreatePersonActionType,
  UpdatePersonNumberActionType,
} from '../redux/persons-actions';
import { resourceCreate, resourceRequest } from '../../../sagas/resource-requests';
import { selectPersonsArray, selectPersonApiIds } from '../selectors';
import { parsePersonToSbPerson } from '../../../lib/formatters';

export function* createPerson(action: CreatePersonActionType) {
  const id = action.payload!.id;
  const personData = parsePersonToSbPerson(action.payload!);

  const personsResponse: PersonsCreateResponse = yield call(resourceCreate, personsConfig, {}, personData);

  if (personsResponse && personsResponse.data) {
    const enhancedPersonResponse = {
      ...personsResponse,
      data: {
        ...personsResponse.data,
        personId: id,
      },
    };
    yield put(createPersonSuccess(enhancedPersonResponse));
  } else if (personsResponse.error) {
    yield put(personsRequestFailure(personsResponse.error));
    throw new Error('Person creation error');
  }
  return personsResponse;
}

export function* watchCreatePerson() {
  yield takeEvery(CREATE_PERSON_ACTION, createPerson);
}

export function* updatePersonNumber({ payload }: UpdatePersonNumberActionType) {
  const updateNumberResponse: ResourceResponse<PersonNumber> = yield call(
    resourceRequest,
    personsConfig.endpoints.updatePhoneNumber,
    { pathParams: { person_id: payload!.id } },
    { number: payload!.number },
  );

  if (updateNumberResponse && updateNumberResponse.data) {
    yield put(updatePersonNumberSuccess(updateNumberResponse.data));
  } else if (updateNumberResponse.error) {
    yield put(personsRequestFailure(updateNumberResponse.error));
    throw new Error('Person number update error');
  }
  return updateNumberResponse;
}

export function* watchUpdatePersonNumber() {
  while (true) {
    const action = yield take(UPDATE_PERSON_NUMBER_ACTION);
    yield call(updatePersonNumber, action);
  }
}

export function* createPersonsFromForm() {
  const persons = yield select(selectPersonsArray);
  return yield all(persons.map((p: Person) => call(createPerson, createPersonAction(p))));
}

export function* updateNumberFromForm() {
  const aboutYouForm = yield select(getFormValues('aboutYou'));
  const apiIds = yield select(selectPersonApiIds);
  return yield call(
    updatePersonNumber,
    updatePersonNumberAction({
      number: aboutYouForm.number,
      id: apiIds.client,
    }),
  );
}
