import * as React from 'react';
import { Row, Col, Grid } from 'react-flexbox-grid';
import { hashHistory } from 'react-router';
import { InjectedIntlProps, injectIntl, FormattedMessage } from 'react-intl';
import { LEGAL_REPRESENTATIVE_RESOURCE_NAME } from '../../../../modules/legalRepresentative/models/legal-representative';
import Page from '../../../../components/page';
import UnloadHandler from '../../../../components/unload-handler';
import Steps from '../../../../patterns/molecules/steps';
import Header from '../../../../patterns/molecules/header';
import BusinessInfoForm from '../../../../patterns/molecules/business-info-form';
import VerticalDivider from '../../../../patterns/atoms/vertical-divider';
import Sidebar from '../../../../patterns/molecules/sidebar';
import Button from '../../../../patterns/atoms/button';
import { ReactComponent as BackIcon } from '../../../../patterns/atoms/icons/back.svg';
import defaultSteps from '../../../../models/steps';
import { BUSINESS_INFO_FORM_NAME } from '../../models/business-info';
import { ReactComponent as UserIcon } from '../../../../patterns/atoms/icons/business.svg';
import { BusinessInfoFormProps } from '../../../../types';

interface BusinessInfoPageProps extends InjectedIntlProps {
  submit: Function;
  first_name: string;
  formValues: BusinessInfoFormProps;
  hasBusinessAsLegalRep: boolean;
  legalFormRequiresRegistryInfo: boolean;
  isTaxCountryDe: boolean;
}

class BusinessInfoPage extends React.PureComponent<BusinessInfoPageProps, {}> {
  submitForm = () => {
    this.props.submit(BUSINESS_INFO_FORM_NAME);
  };
  onSubmit = () => {
    const { hasBusinessAsLegalRep } = this.props;
    if (hasBusinessAsLegalRep) {
      hashHistory.push('legal-representative/business');
    } else {
      hashHistory.push(LEGAL_REPRESENTATIVE_RESOURCE_NAME);
    }
  };
  public render(): JSX.Element {
    const {
      first_name,
      intl: { formatMessage },
      formValues,
      legalFormRequiresRegistryInfo,
      isTaxCountryDe,
    } = this.props;
    return (
      <Page
        header={
          <Header
            sidebar={
              <Button variant="primary" fullWidth={true} handleClick={this.submitForm}>
                <FormattedMessage id="pages.next_step" />
              </Button>
            }
          >
            <Steps active={1} steps={defaultSteps} />
          </Header>
        }
      >
        <Grid fluid={true}>
          <Row between="xs">
            <Col sm={2} xs={12}>
              <Button href="personal-info" display="inline">
                <BackIcon />
                <FormattedMessage id="pages.back" />
              </Button>
            </Col>
            <Col sm={6} xs={12}>
              <h2>{formatMessage({ id: 'forms.business_info.title' }, { name: first_name })}</h2>
              <BusinessInfoForm
                formValues={formValues}
                form={BUSINESS_INFO_FORM_NAME}
                onSubmit={this.onSubmit}
                isLegalRepresentative={false}
                legalFormRequiresRegistryInfo={legalFormRequiresRegistryInfo}
                isTaxCountryDe={isTaxCountryDe}
              />
            </Col>
            <Col sm={1} xs={12}>
              <VerticalDivider />
            </Col>
            <Col sm={3} xs={12}>
              <Sidebar
                title={formatMessage({ id: 'pages.your_business.title' })}
                icon={<UserIcon />}
                content={formatMessage({ id: 'pages.your_business.content' })}
              />
            </Col>
          </Row>
        </Grid>
        <UnloadHandler />
      </Page>
    );
  }
}

export default injectIntl(BusinessInfoPage);
