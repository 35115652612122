import * as React from 'react';
import * as classnames from 'classnames';
import { InjectedIntlProps, injectIntl } from 'react-intl';

import { ReactComponent as SelectIcon } from '../icons/select.svg';

import './collapse.css';

interface CollapseProps {
  title: string;
}

interface CollapseState {
  opened: boolean;
}

class Collapse extends React.PureComponent<CollapseProps & InjectedIntlProps, CollapseState> {
  state = {
    opened: false,
  };

  toggleCollapse = () => {
    this.setState(({ opened }) => ({
      opened: !opened,
    }));
  };
  render() {
    const {
      intl: { formatMessage },
      children,
      title,
    } = this.props;
    const { opened } = this.state;
    return (
      <div className="collapse">
        <div className={`collapse__header ${opened && 'collapse__header--opened'}`}>
          <span className="collapse__left">{title}</span>
          <button type="button" className="collapse__button" onClick={this.toggleCollapse}>
            <span className="collapse__text">
              {formatMessage({
                id: opened ? 'pages.hide_example' : 'pages.see_example',
              })}
            </span>
            <SelectIcon
              className={classnames({
                collapse__icon: true,
                'collapse__icon--opened': opened,
              })}
            />
          </button>
        </div>
        {opened && <div className="collapse__content">{children}</div>}
      </div>
    );
  }
}

export default injectIntl(Collapse);
