import * as React from 'react';
import { InjectedIntlProps, injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col, Grid } from 'react-flexbox-grid';
import moment from 'moment';

import {
  BeneficialOwnerFormProps,
  BeneficialOwnerProps,
  BusinessInfoFormProps,
  LegalRepresentativeFormProps,
  PersonalInfoFormProps,
} from '../../../../types';
import Page from '../../../../components/page';
import UnloadHandler from '../../../../components/unload-handler';
import Button from '../../../../patterns/atoms/button';
import HorizontalDivider from '../../../../patterns/atoms/horizontal-divider';
import VerticalDivider from '../../../../patterns/atoms/vertical-divider';
import Header from '../../../../patterns/molecules/header';
import Sidebar from '../../../../patterns/molecules/sidebar';
import ValueBox from '../../../../patterns/atoms/value-box';
import BusinessDisplay from '../../../../patterns/molecules/business-display';
import Person from '../../../../patterns/atoms/person';
import { ReactComponent as EditIcon } from '../../../../patterns/atoms/icons/edit.svg';
import { ReactComponent as BackIcon } from '../../../../patterns/atoms/icons/back.svg';
import { ReactComponent as IdIcon } from '../../../../patterns/atoms/icons/id.svg';
import Steps from '../../../../patterns/molecules/steps';
import defaultSteps from '../../../../models/steps';
import ReviewForm from '../../../../patterns/molecules/review-form';
import { REVIEW_FORM_NAME } from '../../models/review';

interface ReviewProps extends InjectedIntlProps {
  aboutForm: PersonalInfoFormProps;
  businessForm: BusinessInfoFormProps;
  legalRepresentatives: LegalRepresentativeFormProps;
  beneficialOwners: BeneficialOwnerFormProps;
  submit: Function;
  hasBusinessAsLegalRep: boolean;
  businessLegalRep: BusinessInfoFormProps;
}

class ReviewPage extends React.PureComponent<ReviewProps, {}> {
  submitForm = () => {
    this.props.submit(REVIEW_FORM_NAME);
    window.scrollTo(0, 0);
  };
  public render(): JSX.Element {
    const {
      businessForm,
      legalRepresentatives,
      beneficialOwners,
      aboutForm,
      intl: { formatMessage },
      hasBusinessAsLegalRep,
      businessLegalRep,
    } = this.props;
    return (
      <Page
        header={
          <Header
            sidebar={
              <Button fullWidth={true} variant="primary" handleClick={this.submitForm}>
                <FormattedMessage id="pages.next_step" />
              </Button>
            }
          >
            <Steps active={4} steps={defaultSteps} />
          </Header>
        }
      >
        <Grid fluid={true}>
          <Row between="xs" className="no-margin">
            <Col sm={8} xs={12}>
              <Row>
                <Col sm={3} xs={12}>
                  <Button href="beneficial-owner" display="inline">
                    <BackIcon />
                    <FormattedMessage id="pages.back" />
                  </Button>
                </Col>
                <Col sm={9} xs={12}>
                  <h2>
                    <FormattedMessage id="pages.review.form.title" />
                  </h2>
                  <ReviewForm />
                </Col>
              </Row>
              <HorizontalDivider />
              {businessForm && (
                <Row>
                  <Col sm={3} xs={12}>
                    <Button href="business-info" variant="primary" display="bordered">
                      <EditIcon />
                      <FormattedMessage id="pages.edit" />
                    </Button>
                  </Col>
                  <Col sm={9} xs={12}>
                    <h4>
                      <FormattedMessage id="pages.your_business.title" />
                    </h4>
                    <BusinessDisplay business={businessForm} />
                  </Col>
                </Row>
              )}
              <HorizontalDivider />
              {legalRepresentatives && (
                <Row>
                  <Col sm={3} xs={12}>
                    <Button href="legal-representative" variant="primary" display="bordered">
                      <EditIcon />
                      <FormattedMessage id="pages.edit" />
                    </Button>
                  </Col>
                  <Col sm={9} xs={12}>
                    <h4>
                      <FormattedMessage id="pages.legal_representative.title" />
                    </h4>
                    <Row>
                      {hasBusinessAsLegalRep && (
                        <Col sm={12} xs={12}>
                          <BusinessDisplay business={businessLegalRep} />
                        </Col>
                      )}
                      <Col sm={6} xs={12}>
                        <Person
                          label={formatMessage({ id: 'pages.review.form.you' })}
                          name={`${aboutForm.person!.first_name} ${aboutForm.person!.last_name}`}
                          address={aboutForm.person!.email}
                          active={true}
                        />
                      </Col>
                      {legalRepresentatives.otherLegalRepresentatives &&
                        legalRepresentatives.otherLegalRepresentatives.map((legal, index) => (
                          <Col key={index} sm={6} xs={12}>
                            <Person
                              name={`${legal.person!.first_name} ${legal.person!.last_name}`}
                              address={legal.person!.email}
                            />
                          </Col>
                        ))}
                    </Row>
                  </Col>
                </Row>
              )}
              <HorizontalDivider />
              {beneficialOwners && (
                <Row>
                  <Col sm={3} xs={12}>
                    <Button href="beneficial-owner" variant="primary" display="bordered">
                      <EditIcon />
                      <FormattedMessage id="pages.edit" />
                    </Button>
                  </Col>
                  <Col sm={9} xs={12}>
                    <h4>
                      <FormattedMessage id="pages.review.form.beneficial_owners" />
                    </h4>
                    <Row>
                      {beneficialOwners.otherBeneficialOwners!.map(
                        (beneficial: BeneficialOwnerProps, index: number) => (
                          <Col key={index} sm={6}>
                            <Person
                              label={
                                beneficial.referenced === 'client'
                                  ? formatMessage({
                                      id: 'pages.review.form.you',
                                    })
                                  : ''
                              }
                              name={`${beneficial.person!.first_name} ${beneficial.person!.last_name}`}
                              address={`${beneficial.address || ''} ${beneficial.city || ''}
                                 ${beneficial.country ? beneficial.country!.label : ''}`}
                              active={beneficial.referenced === 'client'}
                            >
                              <ValueBox
                                title={formatMessage({
                                  id: 'pages.review.form.percentage_of_shares',
                                })}
                                value={beneficial.percentageOfShares}
                              />
                              {beneficial.dateOfBirth && (
                                <ValueBox
                                  title={formatMessage({
                                    id: 'pages.review.form.date_of_birth',
                                  })}
                                  value={moment(beneficial.dateOfBirth!).format('DD.MM.YYYY')}
                                />
                              )}
                            </Person>
                          </Col>
                        ),
                      )}
                    </Row>
                  </Col>
                </Row>
              )}
            </Col>
            <Col sm={1} xs={12}>
              <VerticalDivider />
            </Col>
            <Col sm={3} xs={12}>
              <Sidebar
                title={formatMessage({ id: 'pages.review.title' })}
                content={formatMessage({ id: 'pages.review.content' })}
                icon={<IdIcon />}
              />
            </Col>
          </Row>
        </Grid>
        <UnloadHandler />
      </Page>
    );
  }
}

export default injectIntl(ReviewPage);
