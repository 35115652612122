const testingPaths = {
  internal: 'https://partner-onboarding-api.solaris-testing.de',
  external: 'https://partner-onboarding-api.solaris-testing.de/solarisbank',
};
const stagingPaths = {
  internal: 'https://partner-onboarding-api.solaris-staging.de',
  external: 'https://partner-onboarding-api.solaris-staging.de/solarisbank',
};
const sandboxPaths = {
  internal: 'https://partner-onboarding-api.solaris-sandbox.de',
  external: 'https://partner-onboarding-api.solaris-sandbox.de/solarisbank',
};
const productionPaths = {
  internal: 'https://partner-onboarding-api.solarisbank.de',
  external: 'https://partner-onboarding-api.solarisbank.de/solarisbank',
};

const isStaging = (host: string): boolean => /\.solaris-staging\.de$/.test(host);
const isSandbox = (host: string): boolean => /\.solaris-sandbox\.de$/.test(host);
const isTesting = (host: string): boolean => /\.solaris-testing\.de$/.test(host);
const isProduction = (host: string): boolean => /\.solarisbank\.de$/.test(host);

export function environmentApiUrl(proxy: string): string {
  const host = window.location.host;

  if (isProduction(host)) {
    return productionPaths[proxy];
  } else if (isSandbox(host)) {
    return sandboxPaths[proxy];
  } else if (isStaging(host)) {
    return stagingPaths[proxy];
  } else if (isTesting(host)) {
    return testingPaths[proxy];
  }
  return testingPaths[proxy];
}
