import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Routes from '../routes';
import { AppState, Action, RouterHookParams } from '../types';
import { onEnter, onChange } from '../lib/route-config-helper';
import { RoutesConfig } from '../routes-config';

export interface RoutesContainerProps {
  routes: RoutesConfig;
  defaultHomeRoute: string;
}

export const mapDispatchToProps = (dispatch: Dispatch<Action<{}>>, ownProps: RoutesContainerProps) => {
  return {
    onEnter: (params: RouterHookParams) => {
      dispatch(onEnter(params));
    },
    onChange: (params: RouterHookParams) => {
      dispatch(onChange(params));
    },
  };
};

export const mapStateToProps = (state: AppState) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
