// tslint:disable:max-line-length
const benown = {
  'info_beneficial_owner.page_title': 'Wirtschaftlich Berechtigte',
  'info_beneficial_owner.page_subtitle': 'Rechtliche Definition',
  'info_beneficial_owner.section1.title': 'Definition des wirtschaftlich Berechtigten',
  'info_beneficial_owner.section1.p1':
    'Der wirtschaftlich Berechtigte ist nach dem Geldwäschegesetz (GwG) die natürliche Person,',
  'info_beneficial_owner.section1.li1':
    'in deren Eigentum oder unter deren Kontrolle der Vertragspartner letztlich steht, oder',
  'info_beneficial_owner.section1.li2':
    'auf deren Veranlassung eine Transaktion letztlich durchgeführt oder eine Geschäftsbeziehung letztlich begründet wird.',
  'info_beneficial_owner.section2.title': 'Hierzu zählen insbesondere:',
  'info_beneficial_owner.section2.li1':
    'Bei juristischen Personen, außerrechtsfähigen Stiftungen und bei sonstigen Gesellschaften jede natürliche Person, welche unmittelbar oder mittelbar mehr als 25 Prozent der Kapitalanteile hält, mehr als 25 Prozent der Stimmrechte kontrolliert oder auf vergleichbare Weise Kontrolle ausübt.(2) Auf die Abklärung des wirtschaftlich Berechtigten kann aber verzichtet werden bei Gesellschaften, die innerhalb der EU an einem organisierten Markt im Sinne des § 2 Abs. 5 des Wertpapierhandelsgesetzes notiert sind, oder bei börsennotierten Unternehmen aus einem Drittstaat, wenn sie dem Gemeinschaftsrecht entsprechenden Transparenzanforderungen im Hinblick auf Stimmrechtsanteile oder gleichwertigen internationalen Standards unterliegen;',
  'info_beneficial_owner.section2.li2':
    'Bei rechtsfähigen Stiftungen und Rechtsgestaltungen, mit denen treuhänderisch Vermögen verwaltet oder verteilt oder die Verwaltung oder Verteilung durch Dritte beauftragt wird, oder diesen vergleichbaren Rechtsformen zählt zu den wirtschaftlich Berechtigten:',
  'info_beneficial_owner.section2.li2.1':
    'jede natürliche Person, die als Treugeber, Verwalter von Trusts (Trustee) oder Protektor, sofern vorhanden,',
  'info_beneficial_owner.section2.li2.2': 'jede natürliche Person, die Mitglied des Vorstands der Stiftung ist,',
  'info_beneficial_owner.section2.li2.3': 'jede natürliche Person, die als Begünstigte bestimmt worden ist,',
  'info_beneficial_owner.section2.li2.4':
    'die Gruppe von natürlichen Personen, zu deren Gunsten das Vermögen hauptsächlich verwaltet oder verteilt werden soll, sofern die natürliche Person, die Begünstigte des verwalteten Vermögens werden soll, noch nicht bestimmt ist,',
  'info_beneficial_owner.section2.li2.5':
    'jede natürliche Person, die auf sonstige Weise unmittelbar oder mittelbar beherrschenden Einfluss auf die Vermögensverwaltung oder Ertragsverteilung ausübt.',
  'info_beneficial_owner.section2.li3':
    'Bei Handeln auf Veranlassung zählt zu den wirtschaftlich Berechtigten derjenige, auf dessen Veranlassung die Transaktion durchgeführt wird. Soweit der Vertragspartner als Treuhänder handelt, handelt er ebenfalls auf Veranlassung.',
};

export default benown;
