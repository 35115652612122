import { change, untouch, FormAction } from 'redux-form';
import { put, take, call } from 'redux-saga/effects';
import { BUSINESS_INFO_FORM_NAME } from '../models/business-info';
import { businessDependentFields } from '../models/business-rulebook';

export function* handleBusinessInfoFieldChange(action: FormAction) {
  if (action.meta.form !== BUSINESS_INFO_FORM_NAME) {
    return;
  }

  if (businessDependentFields[action.meta.field]) {
    yield businessDependentFields[action.meta.field].map((field: string) =>
      put(change(BUSINESS_INFO_FORM_NAME, field, null)),
    );
    yield put(untouch(BUSINESS_INFO_FORM_NAME, ...businessDependentFields[action.meta.field]));
  }
}

export function* watchFormChange() {
  while (true) {
    const action = yield take('@@redux-form/CHANGE');
    yield call(handleBusinessInfoFieldChange, action);
  }
}
