import { connect } from 'react-redux';
import { AppState } from '../../../types';
import ModalShareholder from '../components/modal-shareholder';
import { setShareholder } from '../redux/shareholder-actions';

export const mapDispatchToProps = {
  setShareholder,
};

export const mapStateToProps = (state: AppState) => ({
  hasShareholder: state.shareholder.hasShareholder,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalShareholder);
