import { getFormValues } from 'redux-form';
import { BUSINESS_LEGAL_REPRESENTATIVE_RESOURCE_NAME } from '../models/business-legal-representative';
import { AppState } from '../../../types';
import { selectHasBusinessAsLegalRepresentative } from '../../businessInfo/selectors';

export const selectBusinessLegalRepForm = (state: AppState) =>
  getFormValues(BUSINESS_LEGAL_REPRESENTATIVE_RESOURCE_NAME)(state);

export const selectLegalRepBusinessId = (state: AppState) => state.businessLegalRepresentative.id;

export const selectBusinessLegalRepresentativeId = (state: AppState) => {
  if (selectHasBusinessAsLegalRepresentative(state)) {
    return selectLegalRepBusinessId(state);
  } else {
    return null;
  }
};
