import axios, { AxiosResponse } from 'axios';
import Raven from 'raven-js';
import { ResourceResponse, ResourceResponseMeta, APIResourceResponse, EndpointConfig } from '../types';
import { environmentApiUrl } from '../lib/environment';
import { getToken } from '../modules/login/sagas/login';

const URI = require('urijs');
const URITemplate = require('urijs/src/URITemplate');

export interface APIDomainConfig {
  api: string;
  api_internal: string;
}

export const API_CONFIG: APIDomainConfig = {
  api: environmentApiUrl('external'),
  api_internal: environmentApiUrl('internal'),
};

export const defaultHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export interface Headers {
  'Content-Type': string;
  Accept: string;
  Authorization?: string;
}

export interface ApiRequestParams {
  queryParams?: {};
  pathParams?: {};
  headerParams?: {};
}

/*
Exception example:
{
"id":"32b957fb44173ef1b52a731637468ab6ex",
"status":404,
"code":"model_not_found",
"title":"Model Not Found",
"detail":"Couldn't find 'Solaris::Partner' for id '16bb1e948be0f2319583d990dd8a1c57cpar'."
}
*/
export interface Exception {
  id?: string;
  status?: number;
  code?: string;
  title: string;
  detail: string;
}

export interface ApiRequest extends ApiRequestParams {
  url: string;
  payload?: {};
  method: 'get' | 'post' | 'patch' | 'delete';
}

export function logSentryException(exception: Exception) {
  const exceptionString = JSON.stringify(exception, null, 2);
  console.warn(`Exception reported to sentry: ${exceptionString}.`);
  Raven.captureException({
    name: exception.title,
    message: exception.detail,
    stack: exceptionString,
  });
}

export function getRequiredPathParamsFromConfig(path: string): string[] {
  const params = path.match(/{.*?}/g);
  if (!params) {
    return [];
  }
  return params.map((value: string) => value.replace(/{|}/g, ''));
}

export function buildRequestConfig(
  enpointConfig: EndpointConfig,
  params: ApiRequestParams = {
    queryParams: {},
    pathParams: {},
    headerParams: {},
  },
  payload?: {},
): ApiRequest {
  const config = API_CONFIG;
  const headerParams = {};
  const pathParams = params.pathParams || {};
  const queryParams = params.queryParams || {};
  const uriPath = URITemplate(enpointConfig.path).expand(pathParams);
  const path = URI(uriPath)
    .query(queryParams)
    .toString();
  const domainURL = !enpointConfig.internal ? config.api : config.api_internal;
  const url = domainURL + path;

  const apiRequest: ApiRequest = {
    url,
    payload,
    queryParams,
    pathParams,
    headerParams,
    ...{ method: enpointConfig.httpMethod },
  };

  return apiRequest;
}

export function buildHeaders(headerParams: {} | undefined) {
  const accessToken = getToken();
  const headers: Headers = {
    ...defaultHeaders,
    ...headerParams,
  };
  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }
  return headers;
}

/*
  bakes a ResourceResponse out of an AxiosResponse object
*/
// tslint:disable-next-line:no-any
export function processResponse(response: AxiosResponse, params: ApiRequestParams): ResourceResponse<any> {
  const meta: ResourceResponseMeta = {
    total: response.headers.total ? +response.headers.total : 0,
    queryParams: params.queryParams,
    pathParams: params.pathParams,
  };
  // tslint:disable-next-line:no-any
  const resourceReponse: ResourceResponse<any> = {
    data: response.data,
    meta,
  };
  return resourceReponse;
}

export function request(params: ApiRequest): Promise<APIResourceResponse> {
  const { url, method, payload, headerParams } = params;
  const headers = buildHeaders(headerParams);
  const options = {
    url,
    headers,
    method,
    ...{ data: payload },
  };
  return genericRequest(params, options);
}

export function binaryRequest(params: ApiRequest): Promise<APIResourceResponse> {
  const { url, method, payload, headerParams } = params;
  const headers = buildHeaders(headerParams);
  const options = {
    url,
    headers,
    responseType: 'arraybuffer',
    method,
    ...{ data: payload },
  };
  return genericRequest(params, options);
}

function genericRequest(params: ApiRequest, options: {}): Promise<APIResourceResponse> {
  const { queryParams, pathParams } = params;
  return new Promise(
    // tslint:disable-next-line:no-any
    function(resolve: any, reject: any) {
      axios
        .request(options)
        .then((response: AxiosResponse) => {
          resolve(processResponse(response, { queryParams, pathParams }));
        })
        .catch((errorResponse: { response: AxiosResponse }) => {
          // axios rejects the promise on all HTTP statuses not being between 200 and 300
          // we can define the valid statuses with validateStatus parameter
          // see https://github.com/mzabriskie/axios#user-content-request-config

          // TODO put an action for raven middleware
          // TODO DELETE
          const response = errorResponse.response;
          if (process.env.NODE_ENV !== 'development') {
            logSentryException(response?.data?.errors || response || errorResponse);
          }

          reject({
            meta: {
              queryParams,
              pathParams,
            },
            error: {
              apiErrors: response?.data?.errors,
              message: response?.statusText,
              status: response?.status,
            },
          } as ResourceResponse<{}>);
        });
    },
  );
}
