import * as React from 'react';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import BaseField from '../baseField';

interface TextareaProps {
  disabled: boolean;
  defaultValue: string;
  className: string;
  readOnly: boolean;
  placeholder: string;
  showlabel: boolean;
  input: WrappedFieldInputProps;
  meta: WrappedFieldMetaProps<{}>;
}

class Textarea extends React.PureComponent<TextareaProps> {
  render() {
    const { input, meta, disabled, defaultValue, className, readOnly, placeholder, ...otherProps } = this.props;

    return (
      <BaseField {...this.props} showlabel={true}>
        <textarea
          {...input}
          {...otherProps}
          disabled={disabled}
          readOnly={readOnly}
          placeholder={placeholder}
          value={input.value || defaultValue || ''}
        />
      </BaseField>
    );
  }
}

export default Textarea;
