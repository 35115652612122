import * as React from 'react';
import { Route, Router, RouterState, IndexRedirect, hashHistory } from 'react-router';
import AppContainer from './containers/app-container';
import { sanitizeQueryParameters } from './lib/query-sanitizer';
import { RoutesConfig } from './routes-config';
import { rfc6570ToReactRouterScheme } from './lib/url-builder';
import { RouterHookParams } from './types';

interface RouterProps {
  loggedIn: boolean;
  routes: RoutesConfig;
  defaultHomeRoute: string;
  onEnter: (params: RouterHookParams) => void;
  onChange: (params: RouterHookParams) => void;
}

interface OnEnterHook {
  nextState: RouterState;
  replace: (path: string) => void;
  callback: () => void;
}

interface OnChangeHook extends OnEnterHook {
  prevState?: RouterState;
}

class Routes extends React.Component<RouterProps> {
  componentDidMount() {
    // always show welcome screen at first
    window.location.hash = '';
  }

  shouldComponentUpdate() {
    // prevent the router to rerender
    // https://alejandronapoles.com/2016/09/19/fixing-hot-reloading-with-react-router/
    return false;
  }

  getCallbackParams(nextState: RouterState): RouterHookParams {
    const queryParams = sanitizeQueryParameters(nextState.location.query);
    const pathParams = nextState.params;

    // In this.createRoute we add a custom property called routeName. As we didn't use "Module Augmentation"
    // but the 'any' type, we need to retrieve the property with string literals.
    // tslint:disable-next-line:no-string-literal
    const routeName = nextState.routes[nextState.routes.length - 1]['routeName'];
    return {
      queryParams,
      pathParams,
      routeName,
    };
  }

  isAuthorized = (customCallback: (params: RouterHookParams) => void) => {
    return ({ nextState, replace, callback }: OnEnterHook | OnChangeHook) => {
      // if (this.props.loggedIn) {
      //   customCallback(this.getCallbackParams(nextState));
      // } else {
      //   authentication.setLastLocation();
      //   replace('/login');
      // }
      callback();
    };
  };

  handleOnEnter(cb: (params: OnEnterHook) => void) {
    return (nextState: RouterState, replace: (path: string) => void, callback: () => void): void => {
      cb({
        nextState,
        replace,
        callback,
      });
    };
  }

  handleOnChange(cb: (params: OnEnterHook) => void) {
    return (
      prevState: RouterState,
      nextState: RouterState,
      replace: (path: string) => void,
      callback: () => void,
    ): void => {
      cb({
        nextState,
        replace,
        callback,
      });
    };
  }

  // tslint:disable-next-line:no-shadowed-variable
  createRoute(routeName: string, routesConfig: RoutesConfig): JSX.Element {
    const path = rfc6570ToReactRouterScheme(routesConfig[routeName].path);
    const component = routesConfig[routeName].component;

    // Any? Are you seriously typing with 'any'? Are you crazy?
    // Yes, typing with 'any' is the only way to use custom properties
    // with react-router's route and typescript.
    // The provided types don't consider custom properties while the router supports them.

    // Another way could be "Module Augmentation" as explained here.
    // tslint:disable-next-line:max-line-length
    // https://stackoverflow.com/questions/42941806/typescript-custom-types-package-for-types-react-router/42943285#42943285

    // tslint:disable-next-line:no-any
    const customProps: any = { routeName };
    return (
      <Route
        {...customProps}
        component={component}
        key={`route-${routeName}`}
        path={path}
        onEnter={this.handleOnEnter(this.isAuthorized(this.props.onEnter))}
        // Not sure why we needed onChange but it's causing duplicate api calls
        // onChange={this.handleOnChange(this.isAuthorized(this.props.onChange))}
      />
    );
  }

  public render(): JSX.Element {
    const { routes } = this.props;
    const routesComponents = Object.keys(routes).map(route => this.createRoute(route, routes));

    return (
      <Router history={hashHistory} onUpdate={() => window.scrollTo(0, 0)}>
        <Route path="/" component={AppContainer}>
          <IndexRedirect to={this.props.defaultHomeRoute} />
          {routesComponents}
        </Route>
      </Router>
    );
  }
}

export default Routes;
