import { connect } from 'react-redux';
import { AppState, Person } from '../../../types';
import PersonFormDisplay, { PersonFormDisplayProps } from './person-form-display';

interface PersonFormDisplayContainerProps {
  isClient?: boolean;
  person: Person;
}

export const mapStateToProps = (state: AppState, ownProps: PersonFormDisplayContainerProps) =>
  ({
    isClient: ownProps.isClient,
    person: ownProps.person,
  } as PersonFormDisplayProps);

export default connect(mapStateToProps)(PersonFormDisplay);
