import { ResourceConfig } from '../../../types';
export const BUSINESS_IDENTIFICATION_RESOURCE_NAME = 'BUSINESS_IDENTIFICATION';

export interface BusinessIdentificationParams {
  readonly business_id: string;
}

export interface Identification {
  id: string;
  method: string;
  reference?: string;
  status: string;
  completed_at?: string;
  partner_id?: string;
  person_id?: string;
  url?: string;
}

export interface PersonIdentification {
  person_id: string;
  identifications: Array<Identification>;
}

export interface BusinessIdentification extends Identification {
  legal_representatives?: Array<PersonIdentification>;
  legal_identification_status?: string;
  legal_identification_reason?: string;
  legal_identification_missing_information?: string;
}

const config: ResourceConfig = {
  resourceName: BUSINESS_IDENTIFICATION_RESOURCE_NAME,
  endpoints: {
    create: {
      path: '/v1/businesses/{businessId}/identifications',
      httpMethod: 'post',
    },
  },
};

export default config;
