import * as React from 'react';
import { connect } from 'react-redux';
import { MODAL_COMPONENTS } from '../models/modals-types';
import Modal from '../components/modal';
import { hideModal as hideModalAction } from '../redux/modals-actions';
import { AppState } from '../../../types';

export const mapDispatchToProps = {
  hideModal: hideModalAction,
};

export const mapStateToProps = (state: AppState) => state.modals;

// tslint:disable-next-line:no-any
const ModalRoot: any = ({ type, props, hideModal }: { type: string; props: any; hideModal: () => void }) => {
  if (!type) {
    return null;
  }
  const ModalComponent = MODAL_COMPONENTS[type];
  return (
    <Modal hideModal={hideModal}>
      <ModalComponent hideModal={hideModal} {...props} />
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalRoot);
