// tslint:disable:max-line-length
const common = {
  'keyword.edit': 'Edit',
  'keyword.details': '{entity} Details',
  'message.no_result_found': 'No Results found',
  'entity.card': 'Card',
  'card.status.BLOCKED': 'BLOCKED',
  'card.status.BLOCKED_BY_SOLARIS': 'BLOCKED_BY_SOLARIS',
  'card.status.CLOSED': 'CLOSED',
  'card.status.CLOSED_BY_SOLARIS': 'CLOSED_BY_SOLARIS',
  'auth.successfullyLoggedOut': 'Successfully logged out',
  'auth.wrongCredentials': 'Wrong username or password',
  'fieldLabel.upload_file': 'Choose a file',
  'fieldLabel.voting_share': 'Voting Share',
  'fieldLabel.valid_until': 'Valid Until',
  'fieldLabel.salutation': 'Salutation',
  'fieldLabel.sector': 'Sector',
  'fieldLabel.industry': 'Industry',
  'fieldLabel.country': 'Country',
  'fieldLabel.job_title': 'Job Title',
  'fieldLabel.first_name': 'First Name',
  'fieldLabel.last_name': 'Last Name',
  'fieldLabel.name': 'Name',
  'fieldLabel.employment_status': 'Employment Status',
  'fieldLabel.email': 'Email',
  'fieldLabel.mobile_number': 'Mobile Number',
  'fieldLabel.industry_key': 'Industry Key',
  'fieldLabel.legal_form': 'Legal Form',
  'fieldLabel.tax_information.tax_country': 'Tax Country',
  'fieldLabel.tax_information.tax_confirmation': 'Tax Confirmation',
  'fieldLabel.tax_information.registration_number': 'Registration Number',
  'fieldLabel.tax_information.registration_issuer': 'Registration Issuer',
  'fieldLabel.address.line_1': 'Line 1',
  'fieldLabel.address.line_2': 'Line 2',
  'fieldLabel.address.postal_code': 'Postal Code',
  'fieldLabel.address.city': 'City',
  'fieldLabel.address.state': 'State',
  'fieldLabel.address.country': 'Country',
  'fieldLabel.id': 'Id',
  'fieldLabel.foundation_date': 'Foundation Date',
  'fieldLabel.policy_id': 'Policy Id',
  'fieldLabel.partner_id': 'Partner Id',
  'fieldLabel.type': 'Type',
  'noteInput.email': 'Email',
  'noteInput.body': 'Body',
  'noteInput.title': 'Add Note',
  'fieldLabel.title': 'Title',
  'fieldLabel.birth_name': 'Birth Name',
  'fieldLabel.birth_date': 'Birth Date',
  'fieldLabel.birth_city': 'Birth City',
  'fieldLabel.birth_country': 'Birth Country',
  'fieldLabel.tax_information.marital_status': 'Martial Status',
  'fieldLabel.tax_information.tax_assessment': 'Tax Assessment',
  'fieldLabel.locking_status': 'Status',
  'fieldLabel.account_limit': 'Account Limit (€)',
  'fieldLabel.valid_from': 'Valid From',
  'fieldLabel.internal_account_limit': 'Internal Account Limit (€)',
  'fieldLabel.type_of_representation': 'Type of representation',
  'accountType.SETTLEMENT': '(18) Settlement',
  'accountType.CASH_DEPOSIT': '(22) Cash Deposit',
  'accountType.CHECKING_BUSINESS': '(20) Checking Business',
  'accountType.CHECKING_PERSONAL': '(21) Checking Personal',
  'accountType.DEPOSIT_TIME_PASSIVE': '(24) Deposit Fixed-Term',
  'accountType.ESCROW_LIABILITY': '(27) Escrow Liability',
  'accountType.CHECKING_FIXED_MATURITY': '(30) Checking Fixed Maturity',
  'accountType.LOAN_RATE_VARIABLE': '(31) Loan Rate Variable',
  'accountType.LOAN_RATE_FIXED': '(32) Loan Rate Fixed',
  'accountType.LOAN_BALLOON': '(33) Loan Balloon',
  'accountType.LOAN_BULLET': '(34) Loan Bullet',
  'accountType.CLEARING_ACCOUNT': '(17) Clearing Account',
  'accountType.CHECKING_OVERDRAFT': '(26) Checking Overdraft',
  'accountType.BILLING_ACCOUNT': '(28) Billing Account',
  'accountType.FACTORING': '(19) Factoring',
  'accountType.TAGESGELD_ACTIVE': '(23) Tagesgeld Active',
  'accountType.SDD_DEPOSIT': '(36) SDD Deposit',
  'accountType.GUARANTEE': '(40) Guarantee',
  'accountType.SURETY': '(41) Surety',
  'accountType.LIABILITY_ASSUMPTION': '(42) Liability Assumption',
  'accountType.LORO': '(11) Loro',
  'button.submit': 'Submit',
  'button.next': 'Next',
  'button.reset': 'Reset',
  'button.createPerson': 'Create Person',
  'button.createAccount': 'Create Account',
  'button.createBusiness': 'Create Business',
  'button.createPartner': 'Create Partner',
  selectPartner: 'Select Partner',
  'button.createPolicy': 'Create Policy',
  'button.selectPolicy': 'Select Policy',
  'button.sendCredentials': 'Send Credentials',
  'country.AF': 'Afghanistan',
  'country.AX': 'Åland Islands',
  'country.AL': 'Albania',
  'country.DZ': 'Algeria',
  'country.AS': 'American Samoa',
  'country.AD': 'Andorra',
  'country.AO': 'Angola',
  'country.AI': 'Anguilla',
  'country.AQ': 'Antarctica',
  'country.AG': 'Antigua and Barbuda',
  'country.AR': 'Argentina',
  'country.AM': 'Armenia',
  'country.AW': 'Aruba',
  'country.AU': 'Australia',
  'country.AT': 'Austria',
  'country.AZ': 'Azerbaijan',
  'country.BS': 'Bahamas',
  'country.BH': 'Bahrain',
  'country.BD': 'Bangladesh',
  'country.BB': 'Barbados',
  'country.BY': 'Belarus',
  'country.BE': 'Belgium',
  'country.BZ': 'Belize',
  'country.BJ': 'Benin',
  'country.BM': 'Bermuda',
  'country.BT': 'Bhutan',
  'country.BO': 'Bolivia, Plurinational State of',
  'country.BQ': 'Bonaire, Sint Eustatius and Saba',
  'country.BA': 'Bosnia and Herzegovina',
  'country.BW': 'Botswana',
  'country.BV': 'Bouvet Island',
  'country.BR': 'Brazil',
  'country.IO': 'British Indian Ocean Territory',
  'country.BN': 'Brunei Darussalam',
  'country.BG': 'Bulgaria',
  'country.BF': 'Burkina Faso',
  'country.BI': 'Burundi',
  'country.KH': 'Cambodia',
  'country.CM': 'Cameroon',
  'country.CA': 'Canada',
  'country.CV': 'Cape Verde',
  'country.KY': 'Cayman Islands',
  'country.CF': 'Central African Republic',
  'country.TD': 'Chad',
  'country.CL': 'Chile',
  'country.CN': 'China',
  'country.CX': 'Christmas Island',
  'country.CC': 'Cocos (Keeling) Islands',
  'country.CO': 'Colombia',
  'country.KM': 'Comoros',
  'country.CG': 'Congo',
  'country.CD': 'Congo, the Democratic Republic of the',
  'country.CK': 'Cook Islands',
  'country.CR': 'Costa Rica',
  'country.CI': 'Côte dIvoire',
  'country.HR': 'Croatia',
  'country.CU': 'Cuba',
  'country.CW': 'Curaçao',
  'country.CY': 'Cyprus',
  'country.CZ': 'Czech Republic',
  'country.DE': 'Germany',
  'country.DK': 'Denmark',
  'country.DJ': 'Djibouti',
  'country.DM': 'Dominica',
  'country.DO': 'Dominican Republic',
  'country.EC': 'Ecuador',
  'country.EG': 'Egypt',
  'country.SV': 'El Salvador',
  'country.GQ': 'Equatorial Guinea',
  'country.ER': 'Eritrea',
  'country.EE': 'Estonia',
  'country.ET': 'Ethiopia',
  'country.FK': 'Falkland Islands (Malvinas)',
  'country.FO': 'Faroe Islands',
  'country.FJ': 'Fiji',
  'country.FI': 'Finland',
  'country.FR': 'France',
  'country.GF': 'French Guiana',
  'country.PF': 'French Polynesia',
  'country.TF': 'French Southern Territories',
  'country.GA': 'Gabon',
  'country.GM': 'Gambia',
  'country.GE': 'Georgia',
  'country.GH': 'Ghana',
  'country.GI': 'Gibraltar',
  'country.GR': 'Greece',
  'country.GL': 'Greenland',
  'country.GD': 'Grenada',
  'country.GP': 'Guadeloupe',
  'country.GU': 'Guam',
  'country.GT': 'Guatemala',
  'country.GG': 'Guernsey',
  'country.GN': 'Guinea',
  'country.GW': 'Guinea-Bissau',
  'country.GY': 'Guyana',
  'country.HT': 'Haiti',
  'country.HM': 'Heard Island and McDonald Islands',
  'country.VA': 'Holy See (Vatican City State)',
  'country.HN': 'Honduras',
  'country.HK': 'Hong Kong',
  'country.HU': 'Hungary',
  'country.IS': 'Iceland',
  'country.IN': 'India',
  'country.ID': 'Indonesia',
  'country.IR': 'Iran, Islamic Republic of',
  'country.IQ': 'Iraq',
  'country.IE': 'Ireland',
  'country.IM': 'Isle of Man',
  'country.IL': 'Israel',
  'country.IT': 'Italy',
  'country.JM': 'Jamaica',
  'country.JP': 'Japan',
  'country.JE': 'Jersey',
  'country.JO': 'Jordan',
  'country.KZ': 'Kazakhstan',
  'country.KE': 'Kenya',
  'country.KI': 'Kiribati',
  'country.KP': 'Korea, Democratic Peoples Republic of',
  'country.KR': 'Korea, Republic of',
  'country.XK': 'Kosovo',
  'country.KW': 'Kuwait',
  'country.KG': 'Kyrgyzstan',
  'country.LA': 'Lao Peoples Democratic Republic',
  'country.LV': 'Latvia',
  'country.LB': 'Lebanon',
  'country.LS': 'Lesotho',
  'country.LR': 'Liberia',
  'country.LY': 'Libya',
  'country.LI': 'Liechtenstein',
  'country.LT': 'Lithuania',
  'country.LU': 'Luxembourg',
  'country.MO': 'Macao',
  'country.MK': 'Macedonia, the former Yugoslav Republic of',
  'country.MG': 'Madagascar',
  'country.MW': 'Malawi',
  'country.MY': 'Malaysia',
  'country.MV': 'Maldives',
  'country.ML': 'Mali',
  'country.MT': 'Malta',
  'country.MH': 'Marshall Islands',
  'country.MQ': 'Martinique',
  'country.MR': 'Mauritania',
  'country.MU': 'Mauritius',
  'country.YT': 'Mayotte',
  'country.MX': 'Mexico',
  'country.FM': 'Micronesia, Federated States of',
  'country.MD': 'Moldova, Republic of',
  'country.MC': 'Monaco',
  'country.MN': 'Mongolia',
  'country.ME': 'Montenegro',
  'country.MS': 'Montserrat',
  'country.MA': 'Morocco',
  'country.MZ': 'Mozambique',
  'country.MM': 'Myanmar',
  'country.NA': 'Namibia',
  'country.NR': 'Nauru',
  'country.NP': 'Nepal',
  'country.NL': 'Netherlands',
  'country.NC': 'New Caledonia',
  'country.NZ': 'New Zealand',
  'country.NI': 'Nicaragua',
  'country.NE': 'Niger',
  'country.NG': 'Nigeria',
  'country.NU': 'Niue',
  'country.NF': 'Norfolk Island',
  'country.MP': 'Northern Mariana Islands',
  'country.NO': 'Norway',
  'country.OM': 'Oman',
  'country.PK': 'Pakistan',
  'country.PW': 'Palau',
  'country.PS': 'Palestinian Territory, Occupied',
  'country.PA': 'Panama',
  'country.PG': 'Papua New Guinea',
  'country.PY': 'Paraguay',
  'country.PE': 'Peru',
  'country.PH': 'Philippines',
  'country.PN': 'Pitcairn',
  'country.PL': 'Poland',
  'country.PT': 'Portugal',
  'country.PR': 'Puerto Rico',
  'country.QA': 'Qatar',
  'country.RE': 'Réunion',
  'country.RO': 'Romania',
  'country.RU': 'Russian Federation',
  'country.RW': 'Rwanda',
  'country.BL': 'Saint Barthélemy',
  'country.SH': 'Saint Helena, Ascension and Tristan da Cunha',
  'country.KN': 'Saint Kitts and Nevis',
  'country.LC': 'Saint Lucia',
  'country.MF': 'Saint Martin (French part)',
  'country.PM': 'Saint Pierre and Miquelon',
  'country.VC': 'Saint Vincent and the Grenadines',
  'country.WS': 'Samoa',
  'country.SM': 'San Marino',
  'country.ST': 'Sao Tome and Principe',
  'country.SA': 'Saudi Arabia',
  'country.SN': 'Senegal',
  'country.RS': 'Serbia',
  'country.SC': 'Seychelles',
  'country.SL': 'Sierra Leone',
  'country.SG': 'Singapore',
  'country.SX': 'Sint Maarten (Dutch part)',
  'country.SK': 'Slovakia',
  'country.SI': 'Slovenia',
  'country.SB': 'Solomon Islands',
  'country.SO': 'Somalia',
  'country.ZA': 'South Africa',
  'country.GS': 'South Georgia and the South Sandwich Islands',
  'country.SS': 'South Sudan',
  'country.ES': 'Spain',
  'country.LK': 'Sri Lanka',
  'country.SD': 'Sudan',
  'country.SR': 'Suriname',
  'country.SJ': 'Svalbard and Jan Mayen',
  'country.SZ': 'Swaziland',
  'country.SE': 'Sweden',
  'country.CH': 'Switzerland',
  'country.SY': 'Syrian Arab Republic',
  'country.TW': 'Taiwan, Province of China',
  'country.TJ': 'Tajikistan',
  'country.TZ': 'Tanzania, United Republic of',
  'country.TH': 'Thailand',
  'country.TL': 'Timor-Leste',
  'country.TG': 'Togo',
  'country.TK': 'Tokelau',
  'country.TO': 'Tonga',
  'country.TT': 'Trinidad and Tobago',
  'country.TN': 'Tunisia',
  'country.TR': 'Turkey',
  'country.TM': 'Turkmenistan',
  'country.TC': 'Turks and Caicos Islands',
  'country.TV': 'Tuvalu',
  'country.UG': 'Uganda',
  'country.UA': 'Ukraine',
  'country.AE': 'United Arab Emirates',
  'country.GB': 'United Kingdom',
  'country.US': 'United States',
  'country.UM': 'United States Minor Outlying Islands',
  'country.UY': 'Uruguay',
  'country.UZ': 'Uzbekistan',
  'country.VU': 'Vanuatu',
  'country.VE': 'Venezuela, Bolivarian Republic of',
  'country.VN': 'Viet Nam',
  'country.VG': 'Virgin Islands, British',
  'country.VI': 'Virgin Islands, U.S.',
  'country.WF': 'Wallis and Futuna',
  'country.EH': 'Western Sahara',
  'country.YE': 'Yemen',
  'country.ZM': 'Zambia',
  'country.ZW': 'Zimbabwe',
  'state.DE-BE': 'Berlin',
  'state.DE-BB': 'Brandenburg',
  'state.DE-BW': 'Baden-Wuerttemberg',
  'state.DE-BY': 'Bavaria',
  'state.DE-HB': 'Bremen',
  'state.DE-HE': 'Hesse',
  'state.DE-HH': 'Hamburg',
  'state.DE-MV': 'Mecklenburg Western Pomerania',
  'state.DE-NI': 'Lower Saxony',
  'state.DE-NW': 'North Rhine-Westphalia',
  'state.DE-RP': 'Rhineland Palatinate',
  'state.DE-SH': 'Schleswig Holstein',
  'state.DE-SL': 'Saarland',
  'state.DE-SN': 'Saxony',
  'state.DE-ST': 'Saxony-Anhalt',
  'state.DE-TH': 'Thuringia',
  'taxConfirmation.SINGLE': 'Single',
  'taxConfirmation.ANNUALY': 'Annualy',
  'lockingStatus.NO_BLOCK': 'No Block',
  'lockingStatus.DEBIT_BLOCK': 'Debit Block',
  'lockingStatus.CREDIT_BLOCK': 'Credit Block',
  'lockingStatus.BLOCK': 'Block',
  'sector.OTHER_COMPANIES': 'Other Companies',
  'sector.OTHER_COMPANIES_WORKMAN': 'Other Companies Workman',
  'sector.FOREIGN_COMPANIES': 'Foreign Companies',
  'sector.MUNICIPALITY_AND_MUNICIPALITY_ASSOCIATION': 'Municipality And Municipality Association',
  'sector.GERMAN_BANKS': 'German Banks',
  'sector.NON_PROFIT_ORGANIZATION': 'Non-Profit Organization',
  'legalRepresentativeRepresentationTypes.ALONE': 'Alone',
  'legalRepresentativeRepresentationTypes.JOINT': 'Joint',
  'legalRepresentativeRepresentationTypes.OTHER': 'Other',
  'legal_form.NONE': 'None',
  'legal_form.SELF_EMPLOYED': 'Self Employed',
  'legal_form.GBR': 'GbR',
  'legal_form.OHG': 'OHG',
  'legal_form.LTD': 'LTD',
  'legal_form.PARTG': 'PartG',
  'legal_form.KG': 'KG',
  'legal_form.UG': 'UG',
  'legal_form.SOLE_PROPRIETORSHIP': 'Sole Proprietorship',
  'legal_form.GMBH': 'GmbH',
  'legal_form.KGAA': 'KGaA',
  'legal_form.AG': 'AG',
  'legal_form.EG': 'EG',
  'legal_form.EK': 'EK',
  'legal_form.MUNICIPALITY': 'Municipality',
  'legal_form.MUNICIPAL_COMPANY': 'Municipal Company',
  'legal_form.SAVINGS_BANK': 'Savings Bank',
  'legal_form.GMBH_I_GR': 'GmbH i.G.',
  'legal_form.UG_I_GR': 'UG i.G.',
  'legal_form.FOREIGN_CORPORATION': 'Foreign Corporation',
  'legal_form.GMBH_CO_KG': 'GmbH & Co. KG',
  'legal_form.EV': 'EV',
  'legal_form.NEV': 'NEV',
  'industry.BUILDING_INDUSTRY': 'Building Industry',
  'industry.TRADE_MAINTENANCE_AND_REPAIR_OF_VEHICLES': 'Trade Maintence and Repair of Vehicles',
  'industry.GASTRONOMY': 'Gastronomy',
  'industry.SCIENTIFIC_TECHNICAL_CONSULTING_SERVICES': 'Scientific Technical Consulting Services',
  'industry.OTHER_ECONOMIC_SERVICES': 'Other Economic Services',
  'industry.INFORMATION_AND_COMMUNICATION': 'Information and Communication',
  'industry.OTHER_SERVICES': 'Other Services',
  'industry.NONE': 'None',
  'industry.FARMER_FORESTRY_FISHING_AND_AQUACULTURE': 'Farmer forestry fishing and aquaculture',
  'industry.POWER_WATER_SUPPLY_DISPOSAL_MINING': 'Power water supply disposal mining',
  'industry.CHEMICAL_INDUSTRY_COKE_OVEN_MINERAL_OIL': 'Chemical industry coke oven mineral oil',
  'industry.MANUFACTURE_PLASTICS_RUBBER_GOODS': 'Manufacture plastics rubber goods',
  'industry.STONE_SOIL_CERAMICS_GLASS': 'Stone soil ceramics glass',
  'industry.METAL_PRODUCTION_PROCESSING': 'Metal production processing',
  'industry.MECHANICAL_ENGINEERING_VEHICLE_MANUFACTURING': 'Mechanical engineering vehicle manufacturing',
  'industry.MANUFACTURE_DATA_PROC_EQUIPMENT_ELECTRIC_OPTICS': 'Manufacture data proc equipment electric optics',
  'industry.WOOD_PAPER_PRINT_INDUSTRY_FURNITURE_ETC': 'Wood paper print industry furniture etc',
  'industry.LEATHER_TEXTILE_CLOTHES_INDUSTRY': 'Leather textile clothes industry',
  'industry.FOOD_INDUSTRY_TOBACCO_PRODUCTS': 'Food industry tobacco products',
  'industry.TRANSPORT_AND_STORAGE_AND_NEWS_TRANSMISSION': 'Transport and storage and news transmission',
  'industry.FINANCIAL_LEASING_COMPANIES': 'Financial leasing companies',
  'industry.HOUSING_COMPANIES': 'Housing companies',
  'industry.HOLDING_COMPANIES': 'Holding companies',
  'industry.OTHER_PROPERTY_MANAGEMENT': 'Other property management',
  'industry.HEALTH_CARE_VETERIN_A_WELFARE_FIRM_FREELANCE': 'Health care veterin a welfare firm freelance',
  'industry.LEASING_OF_MOVABLE_OBJECTS': 'Leasing of movable objects',
  'industry.NON_MARKET_PRODUCERS': 'Non-Market Producers',
  'industry.NON_PROFIT_ORGANIZATION': 'Non-Profit Organization',
  'industry.ORGANIZATION_WITHOUT_ACQUIRING_HOUSE_BUILDING': 'Organization Without Acquiring House/Building',
  'industry_key.NONE': 'None',
  // tslint:disable-next-line:max-line-length
  'industry_key.WHOLESALE_TRADE_EXCEPT_OF_MOTOR_VEHICLES_AND_MOTORCYCLES':
    'Wholesale Trade except of Motor Vehicles and Motorcycles',
  // tslint:disable-next-line:max-line-length
  'industry_key.RETAIL_TRADE_EXCEPT_OF_MOTOR_VEHICLES_AND_MOTORCYCLES':
    'Retail Trade except of Motor Vehicles and Motorcycles',
  'industry_key.FOOD_AND_BEVERAGE_SERVICE_ACTIVITIES': 'Food and Beverage Service Activities',
  'industry_key.GAMBLING_AND_BETTING_ACTIVITIES': 'Gambling and Betting Activities',
  'industry_key.CONSTRUCTION_OF_BUILDINGS': 'Construction of Buildings',
  'industry_key.CIVIL_ENGINEERING': 'Civil Engineering',
  'industry_key.SPECIALISED_CONSTRUCTION_ACTIVITIES': 'Specialised Construction Activities',
  // tslint:disable-next-line:max-line-length
  'industry_key.WHOLESALE_AND_RETAIL_TRADE_AND_REPAIR_OF_MOTOR_VEHICLES_AND_MOTORCYCLES':
    'Trade and Repair of Vehicles',
  'industry_key.EDUCATION': 'Education',
  'industry_key.CREATIVE_ARTS_AND_ENTERTAINMENT_ACTIVITIES': 'Creative Arts and Entertainment Activities',
  'industry_key.LIBRARIES_ARCHIVES_MUSEUMS_AND_OTHER_CULTURAL_ACTIVITIES': 'Libraries, Museums, Cultural Activities',
  'industry_key.SPORTS_ACTIVITIES_AND_AMUSEMENT_AND_RECREATION_ACTIVITIES': 'Sports Activities and Recreation',
  'industry_key.OTHER_PERSONAL_SERVICE_ACTIVITIES': 'Other Personal Service Activities',
  'industry_key.ACCOMMODATION': 'Accomodation',
  'industry_key.PUBLISHING_ACTIVITIES': 'Publishing Activities',
  // tslint:disable-next-line:max-line-length
  'industry_key.MOTION_PICTURE_VIDEO_AND_TELEVISION_PROGRAMME_PRODUCTION_SOUND_RECORDING_AND_MUSIC_PUBLISHING_ACTIVITIES':
    'Motion Picture Television Program Production',
  'industry_key.PROGRAMMING_AND_BROADCASTING_ACTIVITIES': 'Programming and Broadcasting Activities',
  'industry_key.TELECOMMUNICATIONS': 'Telecommunications',
  'industry_key.COMPUTER_PROGRAMMING_CONSULTANCY_AND_RELATED_ACTIVITIES': 'Computer Programming and Related',
  'industry_key.INFORMATION_SERVICE_ACTIVITIES': 'Information Services',
  'industry_key.LEGAL_AND_ACCOUNTING_ACTIVITIES': 'Legal and Accounting Activities',
  'industry_key.MANAGEMENT_CONSULTANCY_ACTIVITIES': 'Management Consultancy Actitivies',
  // tslint:disable-next-line:max-line-length
  'industry_key.ARCHITECTURAL_AND_ENGINEERING_ACTIVITIES_TECHNICAL_TESTING_AND_ANALYSIS':
    'Architectural and Engineering Testing and Analysis',
  'industry_key.SCIENTIFIC_RESEARCH_AND_DEVELOPMENT': 'Scientific Research and Development',
  'industry_key.ADVERTISING_AND_MARKET_RESEARCH': 'Advertising and Market Research',
  'industry_key.OTHER_PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_ACTIVITIES': 'Other Scientific and Technical',
  'industry_key.EMPLOYMENT_ACTIVITIES': 'Employment Activities',
  'industry_key.SECURITY_AND_INVESTIGATION_ACTIVITIES': 'Security and Investigation Activities',
  // tslint:disable-next-line:max-line-length
  'industry_key.OFFICE_ADMINISTRATIVE_OFFICE_SUPPORT_AND_OTHER_BUSINESS_SUPPORT_ACTIVITIES':
    'Office Administration and Support',
  'industry_key.OWN_ASSET_MANAGEMENT': 'Own Asset Management',
  // tslint:disable-next-line:max-line-length
  'industry_key.ACTIVITIES_OF_BUSINESS_EMPLOYERS_AND_PROFESSIONAL_MEMBERSHIP_ORGANISATIONS':
    'Professional Membership Organisation',
  'industry_key.CROP_AND_ANIMAL_PRODUCTION_HUNTING_AND_RELATED_SERVICE_ACTIVITIES':
    'Crop and animal production hunting and related service activities',
  'industry_key.FORESTRY_AND_LOGGING': 'Forestry and logging',
  'industry_key.FISHING_AND_AQUACULTURE': 'Fishing and aquaculture',
  'industry_key.MINING_OF_COAL_AND_LIGNITE': 'Mining of coal and lignite',
  'industry_key.EXTRACTION_OF_CRUDE_PETROLEUM_AND_NATURAL_GAS': 'Extraction of crude petroleum and natural gas',
  'industry_key.MINING_OF_METAL_ORES': 'Mining of metal ores',
  'industry_key.OTHER_MINING_AND_QUARRYING': 'Other mining and quarrying',
  'industry_key.ELECTRICITY_GAS_STEAM_AND_AIR_CONDITIONING_SUPPLY': 'Electricity gas steam and air conditioning supply',
  'industry_key.WATER_COLLECTION_TREATMENT_AND_SUPPLY': 'Water collection treatment and supply',
  'industry_key.SEWERAGE': 'Sewerage',
  'industry_key.WASTE_COLLECTION_TREATMENT_AND_DISPOSAL_ACTIVITIES_MATERIALS_RECOVERY':
    'Waste collection treatment and disposal activities materials recovery',
  'industry_key.REMEDIATION_ACTIVITIES_AND_OTHER_WASTE_MANAGEMENT_SERVICES':
    'Remediation activities and other waste management services',
  'industry_key.MANUFACTURE_OF_COKE_AND_REFINED_PETROLEUM_PRODUCTS':
    'Manufacture of coke and refined petroleum products',
  'industry_key.MANUFACTURE_OF_CHEMICALS_AND_CHEMICAL_PRODUCTS': 'Manufacture of chemicals and chemical products',
  'industry_key.MANUFACTURE_OF_BASIC_PHARMACEUTICAL_PRODUCTS_AND_PHARMACEUTICAL_PREPARATIONS':
    'Manufacture of basic pharmaceutical products and pharmaceutical preparations',
  'industry_key.MANUFACTURE_OF_RUBBER_AND_PLASTIC_PRODUCTS': 'Manufacture of rubber and plastic products',
  'industry_key.MANUFACTURE_OF_PRECIOUS_METALS_OR_STONES': 'Manufacture of precious metals or stones',
  'industry_key.MANUFACTURE_OF_BASIC_METALS': 'Manufacture of basic metals',
  'industry_key.MANUFACTURE_OF_FABRICATED_METAL_PRODUCTS_EXCEPT_MACHINERY_AND_EQUIPMENT':
    'Manufacture of fabricated metal products except machinery and equipment',
  'industry_key.MANUFACTURE_OF_MACHINERY_AND_EQUIPMENT_N_E_C': 'Manufacture of machinery and equipment n e c',
  'industry_key.MANUFACTURE_OF_MOTOR_VEHICLES_TRAILERS_AND_SEMI_TRAILERS':
    'Manufacture of motor vehicles trailers and semi trailers',
  'industry_key.MANUFACTURE_OF_OTHER_TRANSPORT_EQUIPMENT': 'Manufacture of other transport equipment',
  'industry_key.REPAIR_AND_INSTALLATION_OF_MACHINERY_AND_EQUIPMENT':
    'Repair and installation of machinery and equipment',
  'industry_key.MANUFACTURE_OF_COMPUTER_ELECTRONIC_AND_OPTICAL_PRODUCTS':
    'Manufacture of computer electronic and optical products',
  'industry_key.MANUFACTURE_OF_ELECTRICAL_EQUIPMENT': 'Manufacture of electrical equipment',
  'industry_key.MANUFACTURE_OF_WOOD_AND_OF_PRODUCTS_OF_WOOD_AND_CORK_EXCEPT_FURNITURE_MANUFACTURE_OF_ARTICLES_OF_STRAW_AND_PLAITING_MATERIALS':
    'Manufacture of wood and of products of wood and cork except furniture manufacture of articles of straw and plaiting materials',
  'industry_key.MANUFACTURE_OF_PAPER_AND_PAPER_PRODUCTS': 'Manufacture of paper and paper products',
  'industry_key.PRINTING_AND_REPRODUCTION_OF_RECORDED_MEDIA': 'Printing and reproduction of recorded media',
  'industry_key.MANUFACTURE_OF_FURNITURE': 'Manufacture of furniture',
  'industry_key.OTHER_MANUFACTURING': 'Other manufacturing',
  'industry_key.MANUFACTURE_OF_TEXTILES': 'Manufacture of textiles',
  'industry_key.MANUFACTURE_OF_WEARING_APPAREL': 'Manufacture of wearing apparel',
  'industry_key.MANUFACTURE_OF_LEATHER_AND_RELATED_PRODUCTS': 'Manufacture of leather and related products',
  'industry_key.MANUFACTURE_OF_FOOD_PRODUCTS': 'Manufacture of food products',
  'industry_key.MANUFACTURE_OF_BEVERAGES': 'Manufacture of beverages',
  'industry_key.MANUFACTURE_OF_TOBACCO_PRODUCTS': 'Manufacture of tobacco products',
  'industry_key.LAND_TRANSPORT_AND_TRANSPORT_VIA_PIPELINES': 'Land transport and transport via pipelines',
  'industry_key.WATER_TRANSPORT': 'Water transport',
  'industry_key.AIR_TRANSPORT': 'Air transport',
  'industry_key.WAREHOUSING_AND_SUPPORT_ACTIVITIES_FOR_TRANSPORTATION':
    'Warehousing and support activities for transportation',
  'industry_key.POSTAL_AND_COURIER_ACTIVITIES': 'Postal and courier activities',
  'industry_key.TRAVEL_AGENCY_TOUR_OPERATOR_AND_OTHER_RESERVATION_SERVICE_AND_RELATED_ACTIVITIES':
    'Travel agency tour operator and other reservation service and related activities',
  'industry_key.FINANCIAL_LEASING': 'Financial leasing',
  'industry_key.BUYING_AND_SELLING_OF_OWN_RESIDENTIAL_REAL_ESTATE': 'Buying and selling of own residential real estate',
  'industry_key.ACTIVITIES_OF_HEAD_OFFICES': 'Activities of head offices',
  'industry_key.BUYING_AND_SELLING_OF_OWN_NON_RESIDENTIAL_REAL_ESTATE':
    'Buying and selling of own non residential real estate',
  'industry_key.SERVICES_TO_BUILDINGS_AND_LANDSCAPE_ACTIVITIES': 'Services to buildings and landscape activities',
  'industry_key.VETERINARY_ACTIVITIES': 'Veterinary activities',
  'industry_key.HUMAN_HEALTH_ACTIVITIES': 'Human health activities',
  'industry_key.RESIDENTIAL_CARE_ACTIVITIES': 'Residential care activities',
  'industry_key.SOCIAL_WORK_ACTIVITIES_WITHOUT_ACCOMMODATION': 'Social work activities without accommodation',
  'industry_key.RENTAL_AND_LEASING_ACTIVITIES': 'Rental and leasing activities',
  'industry_key.PRIVATE_NON_PROFIT_ORGANIZATIONS': 'Private Non-Profit Organizations',
  'salutation.MR': 'Mr.',
  'salutation.MS': 'Ms.',
  'salutation.DIV': 'Diverse',
  'title.DR': 'Dr.',
  'title.MAGISTER': 'Magister',
  'title.PROF': 'Prof.',
  'title.PROF_DR': 'Prof. Dr.',
  'employment_status.EMPLOYED': 'Employed',
  'employment_status.PUBLIC_SECTOR_EMPLOYEE': 'Public Sector Employee',
  'employment_status.PROFESSIONAL_SOLDIER': 'Professional Soldier',
  'employment_status.FREELANCER': 'Freelancer',
  'employment_status.HOUSEWORK': 'Housework',
  'employment_status.APPRENTICE': 'Apprentice',
  'employment_status.MANAGEMENT': 'Management',
  'employment_status.UNEMPLOYED': 'Unemployed',
  'employment_status.RETIRED': 'Retired',
  'employment_status.STUDENT': 'Student',
  'employment_status.SELF_EMPLOYED': 'Self employed',
  'employment_status.MILITARY_OR_COMMUNITY_SERVICE': 'Military or community service',
  'tax_assessment.NONE': 'None',
  'tax_assessment.SEPARATE': 'Separate',
  'tax_assessment.JOINT': 'Joint',
  'marital_status.DIVORCED': 'Divorced',
  'marital_status.MARRIED': 'Married',
  'marital_status.SINGLE': 'Single',
  'marital_status.WIDOWED': 'Widowed',
  'pageSectionTitle.birthDetails': 'Birth Details',
  'pageSectionTitle.taxInformation': 'Tax Information',
  'pageSectionTitle.personDetails': 'Person Details',
  'pageSectionTitle.personAccountDetails': 'Person Account Details',
  'pageSectionTitle.businessAccountDetails': 'Business Account Details',
  'pageSectionTitle.primaryAddress': 'Primary Address',
  'pageSectionTitle.contactAddress': 'Contact Address',
  'pageSectionTitle.createPerson': 'Create Person',
  'pageSectionTitle.allPersons': 'All Persons',
  'pageSectionTitle.allBusinesses': 'All Businesses',
  'pageSectionTitle.personIdentifications': 'Person Identifications',
  'pageSectionTitle.personIdentificationDetails': 'Person Identification Details',
  'pageSectionTitle.createAccount': 'Create Account',
  'pageSectionTitle.accounts': 'Accounts',
  'pageSectionTitle.createBusiness': 'Create Business',
  'pageSectionTitle.createPartner': 'Create Partner',
  'pageSectionTitle.createApplication': 'Create Application',
  'pageSectionTitle.createUser': 'Create User',
  'pageSectionTitle.updateApplication': 'Update Application',
  'pageSectionTitle.selectApplicationName': 'Select Application name',
  'pageSectionTitle.userDetails': 'User Details',
  'pageSectionTitle.updateUser': 'Update User',
  'pageSectionTitle.authorizedPersons': 'Authorized Persons',
  'pageSectionTitle.reservations': 'Reservations',
  'pageSectionTitle.businessIdentifications': 'Business Identifications',
  'pageSectionTitle.taxIdentifications': 'Tax Identifications',
  'pageSectionTitle.updateBusinessLegalRepresentative': 'Update Business Legal Representative',
  'pageSectionTitle.updateBusinessAccountAuthorizedPerson': 'Business Account Authorized Person Update',
  'columnLabel.decline_reason': 'Decline Reason',
  'columnLabel.amount': 'Amount',
  'columnLabel.expired_at': 'Expired At',
  'columnLabel.expiresAt': 'Expires At',
  'columnLabel.status': 'Status',
  'columnLabel.expiresAtMin': 'Expires At (min)',
  'columnLabel.expiresAtMax': 'Expires At (max)',
  'columnLabel.reservation_type': 'Reservation Type',
  'columnLabel.reference': 'Reference',
  'columnLabel.valuta_date': 'Valuta Date',
  'columnLabel.creation_date': 'Creation Date',
  'columnLabel.booking_date': 'Booking Date',
  'columnLabel.bookingDateMin': 'Booking Date (min)',
  'columnLabel.bookingDateMax': 'Booking Date (max)',
  'protectedForm.leaveComfirmation': 'You have made changes to this form. Do you want to leave anyway?',
  'columnLabel.booking_type': 'Booking Type',
  'columnLabel.id': 'Id',
  'columnLabel.resolved_at': 'Resolved At',
  'columnLabel.description': 'Description',
  'columnLabel.createdAt': 'Created At',
  'columnLabel.settlement_amount': 'Settlement Amount',
  'columnLabel.recipient_bic': 'Recipient BIC',
  'columnLabel.recipient_iban': 'Recipient IBAN',
  'columnLabel.recipient_name': 'Recipient Name',
  'columnLabel.sender_bic': 'Sender BIC',
  'columnLabel.sender_iban': 'Sender IBAN',
  'columnLabel.sender_name': 'Sender Name',
  'columnLabel.end_to_end_id': 'End to end Id',
  'columnLabel.creditor_identifier': 'Creditor Identifier',
  'columnLabel.mandate_reference': 'Mandate Reference',
  'columnLabel.transaction_id': 'Transaction Id',
  'columnLabel.authorization_amount': 'Auth. Amount',
  'columnLabel.authorization_response': 'Auth. Response',
  'columnLabel.authorization_datetime': 'Auth. Date',
  'columnLabel.expiry_date': 'Expiry Date',
  'columnLabel.merchant_country': 'Merchant Country',
  'columnLabel.merchant_name': 'Merchant Name',
  'columnLabel.original_amount': 'Original Amount',
  'columnLabel.original_currency': 'Original Currency',
  'columnLabel.transaction_date': 'Transaction Date',
  'columnLabel.transaction_type': 'Transaction Type',
  'columnLabel.pos_entry_mode': 'Pos Entry Mode',
  'columnLabel.person_name': 'Person Name',
  'columnLabel.valid_from': 'Valid From',
  'columnLabel.valid_until': 'Valid Until',
};

export default common;
