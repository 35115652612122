import { ResourceConfig } from '../../../types';

export const BUSINESS_DOCUMENT_RESOURCE_NAME = 'businessDocument';

const config: ResourceConfig = {
  resourceName: BUSINESS_DOCUMENT_RESOURCE_NAME,
  endpoints: {
    create: {
      path: '/v1/businesses/{business_id}/documents',
      httpMethod: 'post',
    },
  },
};

export default config;
