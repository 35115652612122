import * as React from 'react';
import { InjectedIntlProps, injectIntl, FormattedMessage } from 'react-intl';

import Frame from '../../../../components/frame';
import UnloadHandler from '../../../../components/unload-handler';
import Steps from '../../../../patterns/molecules/steps';
import Header from '../../../../patterns/molecules/header';
import defaultSteps from '../../../../models/steps';

interface IdentificationProps extends InjectedIntlProps {
  url: string;
}

class Identification extends React.PureComponent<IdentificationProps, {}> {
  private iframe: HTMLIFrameElement | null;
  componentDidMount() {
    if (this.iframe) {
      this.iframe.setAttribute('allow', 'camera; microphone');
    }
  }
  shouldComponentUpdate() {
    return false;
  }
  public render(): JSX.Element {
    const { url } = this.props;
    return (
      <Frame
        header={
          <Header
            sidebar={
              <p>
                <FormattedMessage id="pages.identification.header_text" />
              </p>
            }
          >
            <Steps active={4} steps={defaultSteps} />
          </Header>
        }
      >
        {url ? (
          // eslint-disable-next-line jsx-a11y/iframe-has-title
          <iframe
            src={url}
            style={{ width: '100%', height: '80vh' }}
            ref={node => {
              this.iframe = node;
            }}
          />
        ) : null}
        <UnloadHandler />
      </Frame>
    );
  }
}

export default injectIntl(Identification);
