import * as React from 'react';
import { addLocaleData, IntlProvider } from 'react-intl';
import * as messages from '../../messages';
import * as locale_en from 'react-intl/locale-data/en';
import * as locale_de from 'react-intl/locale-data/de';

addLocaleData([...locale_en, ...locale_de]);

export interface LanguageWrapperProps {
  children: React.ReactNode;
  language: string;
}

const LanguageWrapper: React.FunctionComponent<LanguageWrapperProps> = ({ children }: LanguageWrapperProps) => {
  const language = navigator.language === 'de' ? 'de' : 'en';
  return (
    <IntlProvider key={language} locale={language} messages={messages[language]}>
      {children}
    </IntlProvider>
  );
};

export default LanguageWrapper;
