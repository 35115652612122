import { ModuleConfig } from '../../models/module';
import Preloader from './containers/preloader';
import { PRELOADER_RESOURCE_NAME } from './models/preloader';

export const config: ModuleConfig = {
  moduleName: PRELOADER_RESOURCE_NAME,
  requires: [],
  redux: {},
  listenerSagas: [],
  routes: {
    preloader: {
      path: 'preloader',
      component: Preloader,
    },
  },
};
