import { ModuleConfig } from './../../models/module';
import InfoBeneficialOwner from './containers/info-beneficial-owner';
import InfoFaqBeneficialOwner from './containers/info-faq-beneficial-owner';
import InfoFaqLegalRepresentative from './containers/info-faq-legal-representative';
import { INFO_RESOURCE_NAME } from './models/info';

export const config: ModuleConfig = {
  moduleName: INFO_RESOURCE_NAME,
  requires: [],
  redux: {},
  listenerSagas: [],
  routes: {
    'info/faq-legal-representatives': {
      path: 'info/faq-legal-representatives',
      component: InfoFaqLegalRepresentative,
    },
    'info/faq-beneficial-owner': {
      path: 'info/faq-beneficial-owner',
      component: InfoFaqBeneficialOwner,
    },
    'info/beneficial-owner': {
      path: 'info/beneficial-owner',
      component: InfoBeneficialOwner,
    },
  },
};
