import { StandardAction } from '../../../types';

export const LOGIN_ACTION = 'welcome/LOGIN';
export const LOGIN_FAILED = 'login/FAILED';
export const LOGIN_SUCCEEDED = 'login/SUCCEEDED';

export type LoginActionType = StandardAction<{ type: typeof LOGIN_ACTION }>;

export function onLoginSubmitAction(): LoginActionType {
  return {
    type: LOGIN_ACTION,
  };
}

export interface LoginFailedAction extends StandardAction<{}> {
  readonly type: typeof LOGIN_FAILED;
}

export function loginFailedAction(payload: {}): LoginFailedAction {
  return {
    type: LOGIN_FAILED,
    payload,
  };
}

export interface LoginSuceededAction extends StandardAction<{}> {
  readonly type: typeof LOGIN_SUCCEEDED;
}

export function loginSuceededAction(): LoginSuceededAction {
  return {
    type: LOGIN_SUCCEEDED,
  };
}
