import { call } from 'redux-saga/effects';
import resourceConfig from '../models/business-document';
import { resourceCreate } from '../../../sagas/resource-requests';

export function* createBusinessDocument(businessId: string, documentType: string, file: File) {
  let formData = new FormData();
  formData.append('file', file);
  formData.append('document_type', documentType);

  const documentsResponse = yield call(
    resourceCreate,
    resourceConfig,
    { pathParams: { business_id: businessId } },
    formData,
  );

  if (documentsResponse.error) {
    throw new Error('Business document creation error');
  }

  return documentsResponse;
}
