import * as React from 'react';
import * as classnames from 'classnames';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

import { ReactComponent as CheckIcon } from './assets/check-icon.svg';

import './checkbox.css';

interface CheckboxProps {
  className?: string;
  textStyle?: string;
  label?: string;
  input: WrappedFieldInputProps;
  meta: WrappedFieldMetaProps<{}>;
}

export class Checkbox extends React.PureComponent<CheckboxProps> {
  render() {
    const { className, textStyle, label, input, meta } = this.props;
    const { error, touched } = meta;
    return (
      <label className={classnames('checkbox-wrap', className)}>
        <span className={classnames('checkbox__label', textStyle)}>{label}</span>
        <input type="checkbox" checked={input.value} {...input} />
        <span
          className={classnames('checkbox', {
            'checkbox--error': touched && error,
          })}
        >
          <CheckIcon />
        </span>
      </label>
    );
  }
}

export default Checkbox;
