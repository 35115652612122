import { take, call } from 'redux-saga/effects';
import { navigateTo } from '../../../sagas/navigate';
import { REVIEW_FORM_NAME } from '../models/review';
import { createBeneficialOwnersFromForm } from '../../beneficialOnwer/sagas/beneficial-owner';
import { createBusinessFromForm, createBusinessDocumentFromForm } from '../../businessInfo/sagas/business-info';
import { createBusinessLegalRepFromForm } from '../../businessLegalRepresentative/sagas/business-legal-representative';
import { createPersonsFromForm, updateNumberFromForm } from '../../personalInfo/sagas/persons';
import { createLegalRepresentativesFromForm } from '../../legalRepresentative/sagas/legalRepresentative';
import { createIdentificationFromForm } from '../../businessIdentification/sagas/businessIdentification';

export function* watchReviewSubmit() {
  while (true) {
    const {
      meta: { form },
    } = yield take('@@redux-form/SET_SUBMIT_SUCCEEDED');
    if (form === REVIEW_FORM_NAME) {
      yield call(submitData);
    }
  }
}

export function* submitData() {
  try {
    yield call(navigateTo, 'preloader');
    yield call(createBusinessFromForm);
    yield call(createBusinessDocumentFromForm);
    yield call(createPersonsFromForm);
    yield call(updateNumberFromForm);
    yield call(createBusinessLegalRepFromForm);
    yield call(createLegalRepresentativesFromForm);
    yield call(createBeneficialOwnersFromForm);
    yield call(createIdentificationFromForm);
    yield call(navigateTo, 'identification');
  } catch (error) {
    // tslint:disable-next-line:no-console
    console.error(error);
    yield call(navigateTo, 'form/failure');
  }
}
