import { ModuleConfig } from './../../models/module';
import PersonalInfoContainer from './containers/personal-info-container';
import { PERSONAL_INFO_RESOURCE_NAME } from './models/personal-info';
import { watchCreatePerson, watchUpdatePersonNumber } from './sagas/persons';
import { person } from './redux/persons-reducer';

export const config: ModuleConfig = {
  moduleName: PERSONAL_INFO_RESOURCE_NAME,
  requires: [],
  redux: {
    reducer: {
      person,
    },
  },
  listenerSagas: [watchCreatePerson, watchUpdatePersonNumber],
  routes: {
    'personal-info': {
      path: 'personal-info',
      component: PersonalInfoContainer,
    },
  },
};
