import { ModuleConfig } from './../../models/module';
import FormFailureContainer from './containers/form-failure-container';
import FormSuccessContainer from './containers/form-success-container';
import { FORM_RESOURCE_NAME } from './models/form';

export const config: ModuleConfig = {
  moduleName: FORM_RESOURCE_NAME,
  requires: [],
  redux: {},
  listenerSagas: [],
  routes: {
    'form.success': {
      path: 'form/success',
      component: FormSuccessContainer,
    },
    'form.failure': {
      path: 'form/failure',
      component: FormFailureContainer,
    },
  },
};
