import * as React from 'react';
import * as classnames from 'classnames';

import './fieldSide.css';

interface FieldSideProps {
  wide?: boolean;
  input?: boolean;
  column?: boolean;
  row?: boolean;
  btn?: boolean;
  onClick?: () => void;
}

class FieldSide extends React.PureComponent<FieldSideProps> {
  render() {
    const { children, wide, input, column, row, onClick } = this.props;
    return (
      <div
        className={classnames('field-side', {
          'field-side--wide': wide,
        })}
      >
        <div
          className={classnames('field-side--center', {
            'field-side--input': input,
            'field-side--btn': !!onClick,
          })}
          onClick={onClick}
        >
          <div
            className={classnames({
              'field-side--column': column,
              'field-side--row': row,
            })}
          >
            {children}
          </div>
        </div>
      </div>
    );
  }
}

export default FieldSide;
