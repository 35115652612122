import { Enum } from 'typescript-string-enums';
// NOTE THAT THE KEYS NEED TO MATCH UP WITH THE ENUM NAMES OR VALUES IN enums.js
// USE __unmatched IF YOU WANT TO RETURN A RESULT WHEN NO ENUM VALUES WERE MATCHED
import {
  sectors,
  legalForms,
  industryKeys,
  industries,
  countries,
  germanRegistrationIssuers,
} from '../../../lib/resource-enums';
import { NullableString } from '../../../types';

// NOTE: The sectors and industries rules (and related functions) have been revisited and it was decided
//       to settle for less complicated rules than the one in PASS or API documentation.
//
//       - Sectors are only: OTHER_COMPANIES, OTHER_COMPANIES_WORKMAN, FOREIGN_COMPANIES.
//       - For legal forms that share sectors (EK, GBR, LTD), default to sector OTHER_COMPANIES.
//       - Leave out sectors:
//          MUNICIPALITY_AND_MUNICIPALITY_ASSOCIATION, OTHER_PRIVATE_INDIVIDUAL, ECONOMIC_DEPENDENT, GERMAN_BANKS.
//       - Use all DE legal forms except SE (as it's not already in BOPI and not often used).

const Rulebook = {
  legalForm: {
    EK: sectors.OTHER_COMPANIES_WORKMAN,
    SELF_EMPLOYED: sectors.OTHER_COMPANIES_WORKMAN,
    SOLE_PROPRIETORSHIP: sectors.OTHER_COMPANIES_WORKMAN,
  },
  sector: {
    NON_PROFIT_ORGANIZATION: {
      industry: [
        industries.NON_PROFIT_ORGANIZATION,
        industries.NON_MARKET_PRODUCERS,
        industries.ORGANIZATION_WITHOUT_ACQUIRING_HOUSE_BUILDING,
      ],
      legalForm: {
        DE: [legalForms.EV, legalForms.NEV],
      },
    },
    OTHER_COMPANIES_WORKMAN: {
      industry: [industries.OTHER_SERVICES],
      legalForm: [
        legalForms.SELF_EMPLOYED,
        legalForms.SOLE_PROPRIETORSHIP,
        legalForms.EK,
        legalForms.LTD,
        legalForms.GBR,
      ],
    },
    OTHER_COMPANIES: {
      industry: [
        industries.BUILDING_INDUSTRY,
        industries.TRADE_MAINTENANCE_AND_REPAIR_OF_VEHICLES,
        industries.GASTRONOMY,
        industries.SCIENTIFIC_TECHNICAL_CONSULTING_SERVICES,
        industries.INFORMATION_AND_COMMUNICATION,
        industries.OTHER_ECONOMIC_SERVICES,
        industries.OTHER_SERVICES,
        industries.FARMER_FORESTRY_FISHING_AND_AQUACULTURE,
        industries.POWER_WATER_SUPPLY_DISPOSAL_MINING,
        industries.CHEMICAL_INDUSTRY_COKE_OVEN_MINERAL_OIL,
        industries.MANUFACTURE_PLASTICS_RUBBER_GOODS,
        industries.STONE_SOIL_CERAMICS_GLASS,
        industries.METAL_PRODUCTION_PROCESSING,
        industries.MECHANICAL_ENGINEERING_VEHICLE_MANUFACTURING,
        industries.MANUFACTURE_DATA_PROC_EQUIPMENT_ELECTRIC_OPTICS,
        industries.WOOD_PAPER_PRINT_INDUSTRY_FURNITURE_ETC,
        industries.LEATHER_TEXTILE_CLOTHES_INDUSTRY,
        industries.FOOD_INDUSTRY_TOBACCO_PRODUCTS,
        industries.TRANSPORT_AND_STORAGE_AND_NEWS_TRANSMISSION,
        industries.FINANCIAL_LEASING_COMPANIES,
        industries.HOUSING_COMPANIES,
        industries.HOLDING_COMPANIES,
        industries.OTHER_PROPERTY_MANAGEMENT,
        industries.HEALTH_CARE_VETERIN_A_WELFARE_FIRM_FREELANCE,
        industries.LEASING_OF_MOVABLE_OBJECTS,
        industries.FARMER_FORESTRY_FISHING_AND_AQUACULTURE,
      ],
      legalForm: [
        legalForms.OHG,
        legalForms.PARTG,
        legalForms.KG,
        legalForms.UG,
        legalForms.GMBH,
        legalForms.KGAA,
        legalForms.AG,
        legalForms.EG,
        legalForms.LTD,
        legalForms.GBR,
        legalForms.UG_I_GR,
        legalForms.GMBH_I_GR,
        legalForms.GMBH_CO_KG,
      ],
    },
    FOREIGN_COMPANIES: {
      industry: [
        industries.BUILDING_INDUSTRY,
        industries.TRADE_MAINTENANCE_AND_REPAIR_OF_VEHICLES,
        industries.GASTRONOMY,
        industries.SCIENTIFIC_TECHNICAL_CONSULTING_SERVICES,
        industries.INFORMATION_AND_COMMUNICATION,
        industries.OTHER_ECONOMIC_SERVICES,
        industries.OTHER_SERVICES,
        industries.FARMER_FORESTRY_FISHING_AND_AQUACULTURE,
        industries.POWER_WATER_SUPPLY_DISPOSAL_MINING,
        industries.CHEMICAL_INDUSTRY_COKE_OVEN_MINERAL_OIL,
        industries.MANUFACTURE_PLASTICS_RUBBER_GOODS,
        industries.STONE_SOIL_CERAMICS_GLASS,
        industries.METAL_PRODUCTION_PROCESSING,
        industries.MECHANICAL_ENGINEERING_VEHICLE_MANUFACTURING,
        industries.MANUFACTURE_DATA_PROC_EQUIPMENT_ELECTRIC_OPTICS,
        industries.WOOD_PAPER_PRINT_INDUSTRY_FURNITURE_ETC,
        industries.LEATHER_TEXTILE_CLOTHES_INDUSTRY,
        industries.FOOD_INDUSTRY_TOBACCO_PRODUCTS,
        industries.TRANSPORT_AND_STORAGE_AND_NEWS_TRANSMISSION,
        industries.HOUSING_COMPANIES,
        industries.HOLDING_COMPANIES,
        industries.OTHER_PROPERTY_MANAGEMENT,
        industries.HEALTH_CARE_VETERIN_A_WELFARE_FIRM_FREELANCE,
        industries.LEASING_OF_MOVABLE_OBJECTS,
      ],
      legalForm: [legalForms.FOREIGN_CORPORATION],
    },
  },
  industry: {
    // Mapping industry (Industrie) to industry key (Branchenschlüssel)
    BUILDING_INDUSTRY: {
      industryKey: [
        industryKeys.CONSTRUCTION_OF_BUILDINGS,
        industryKeys.CIVIL_ENGINEERING,
        industryKeys.SPECIALISED_CONSTRUCTION_ACTIVITIES,
      ],
    },
    TRADE_MAINTENANCE_AND_REPAIR_OF_VEHICLES: {
      industryKey: [
        industryKeys.WHOLESALE_AND_RETAIL_TRADE_AND_REPAIR_OF_MOTOR_VEHICLES_AND_MOTORCYCLES,
        industryKeys.WHOLESALE_TRADE_EXCEPT_OF_MOTOR_VEHICLES_AND_MOTORCYCLES,
        industryKeys.RETAIL_TRADE_EXCEPT_OF_MOTOR_VEHICLES_AND_MOTORCYCLES,
      ],
    },
    OTHER_SERVICES: {
      industryKey: [
        industryKeys.EDUCATION,
        industryKeys.CREATIVE_ARTS_AND_ENTERTAINMENT_ACTIVITIES,
        industryKeys.LIBRARIES_ARCHIVES_MUSEUMS_AND_OTHER_CULTURAL_ACTIVITIES,
        industryKeys.GAMBLING_AND_BETTING_ACTIVITIES,
        industryKeys.SPORTS_ACTIVITIES_AND_AMUSEMENT_AND_RECREATION_ACTIVITIES,
        industryKeys.OTHER_PERSONAL_SERVICE_ACTIVITIES,
      ],
    },
    GASTRONOMY: {
      industryKey: [industryKeys.ACCOMMODATION, industryKeys.FOOD_AND_BEVERAGE_SERVICE_ACTIVITIES],
    },
    SCIENTIFIC_TECHNICAL_CONSULTING_SERVICES: {
      industryKey: [
        industryKeys.ARCHITECTURAL_AND_ENGINEERING_ACTIVITIES_TECHNICAL_TESTING_AND_ANALYSIS,
        industryKeys.SCIENTIFIC_RESEARCH_AND_DEVELOPMENT,
        industryKeys.ADVERTISING_AND_MARKET_RESEARCH,
        industryKeys.OTHER_PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_ACTIVITIES,
        industryKeys.LEGAL_AND_ACCOUNTING_ACTIVITIES,
        industryKeys.MANAGEMENT_CONSULTANCY_ACTIVITIES,
      ],
    },
    INFORMATION_AND_COMMUNICATION: {
      industryKey: [
        industryKeys.PUBLISHING_ACTIVITIES,
        // tslint:disable-next-line:max-line-length
        industryKeys.MOTION_PICTURE_VIDEO_AND_TELEVISION_PROGRAMME_PRODUCTION_SOUND_RECORDING_AND_MUSIC_PUBLISHING_ACTIVITIES,
        industryKeys.PROGRAMMING_AND_BROADCASTING_ACTIVITIES,
        industryKeys.TELECOMMUNICATIONS,
        industryKeys.COMPUTER_PROGRAMMING_CONSULTANCY_AND_RELATED_ACTIVITIES,
        industryKeys.INFORMATION_SERVICE_ACTIVITIES,
      ],
    },
    OTHER_ECONOMIC_SERVICES: {
      industryKey: [
        industryKeys.EMPLOYMENT_ACTIVITIES,
        industryKeys.SECURITY_AND_INVESTIGATION_ACTIVITIES,
        industryKeys.OFFICE_ADMINISTRATIVE_OFFICE_SUPPORT_AND_OTHER_BUSINESS_SUPPORT_ACTIVITIES,
        industryKeys.OWN_ASSET_MANAGEMENT,
        industryKeys.ACTIVITIES_OF_BUSINESS_EMPLOYERS_AND_PROFESSIONAL_MEMBERSHIP_ORGANISATIONS,
      ],
    },
    FARMER_FORESTRY_FISHING_AND_AQUACULTURE: {
      industryKey: [
        industryKeys.CROP_AND_ANIMAL_PRODUCTION_HUNTING_AND_RELATED_SERVICE_ACTIVITIES,
        industryKeys.FORESTRY_AND_LOGGING,
        industryKeys.FISHING_AND_AQUACULTURE,
      ],
    },
    POWER_WATER_SUPPLY_DISPOSAL_MINING: {
      industryKey: [
        industryKeys.MINING_OF_COAL_AND_LIGNITE,
        industryKeys.EXTRACTION_OF_CRUDE_PETROLEUM_AND_NATURAL_GAS,
        industryKeys.MINING_OF_METAL_ORES,
        industryKeys.OTHER_MINING_AND_QUARRYING,
        industryKeys.ELECTRICITY_GAS_STEAM_AND_AIR_CONDITIONING_SUPPLY,
        industryKeys.WATER_COLLECTION_TREATMENT_AND_SUPPLY,
        industryKeys.SEWERAGE,
        industryKeys.WASTE_COLLECTION_TREATMENT_AND_DISPOSAL_ACTIVITIES_MATERIALS_RECOVERY,
        industryKeys.REMEDIATION_ACTIVITIES_AND_OTHER_WASTE_MANAGEMENT_SERVICES,
      ],
    },
    CHEMICAL_INDUSTRY_COKE_OVEN_MINERAL_OIL: {
      industryKey: [
        industryKeys.MANUFACTURE_OF_COKE_AND_REFINED_PETROLEUM_PRODUCTS,
        industryKeys.MANUFACTURE_OF_CHEMICALS_AND_CHEMICAL_PRODUCTS,
        industryKeys.MANUFACTURE_OF_BASIC_PHARMACEUTICAL_PRODUCTS_AND_PHARMACEUTICAL_PREPARATIONS,
      ],
    },
    MANUFACTURE_PLASTICS_RUBBER_GOODS: {
      industryKey: [industryKeys.MANUFACTURE_OF_RUBBER_AND_PLASTIC_PRODUCTS],
    },
    STONE_SOIL_CERAMICS_GLASS: {
      industryKey: [industryKeys.MANUFACTURE_OF_PRECIOUS_METALS_OR_STONES],
    },
    METAL_PRODUCTION_PROCESSING: {
      industryKey: [
        industryKeys.MANUFACTURE_OF_BASIC_METALS,
        industryKeys.MANUFACTURE_OF_FABRICATED_METAL_PRODUCTS_EXCEPT_MACHINERY_AND_EQUIPMENT,
      ],
    },
    MECHANICAL_ENGINEERING_VEHICLE_MANUFACTURING: {
      industryKey: [
        industryKeys.MANUFACTURE_OF_MACHINERY_AND_EQUIPMENT_N_E_C,
        industryKeys.MANUFACTURE_OF_MOTOR_VEHICLES_TRAILERS_AND_SEMI_TRAILERS,
        industryKeys.MANUFACTURE_OF_OTHER_TRANSPORT_EQUIPMENT,
        industryKeys.REPAIR_AND_INSTALLATION_OF_MACHINERY_AND_EQUIPMENT,
      ],
    },
    MANUFACTURE_DATA_PROC_EQUIPMENT_ELECTRIC_OPTICS: {
      industryKey: [
        industryKeys.MANUFACTURE_OF_COMPUTER_ELECTRONIC_AND_OPTICAL_PRODUCTS,
        industryKeys.MANUFACTURE_OF_ELECTRICAL_EQUIPMENT,
      ],
    },
    WOOD_PAPER_PRINT_INDUSTRY_FURNITURE_ETC: {
      industryKey: [
        // tslint:disable-next-line:max-line-length
        industryKeys.MANUFACTURE_OF_WOOD_AND_OF_PRODUCTS_OF_WOOD_AND_CORK_EXCEPT_FURNITURE_MANUFACTURE_OF_ARTICLES_OF_STRAW_AND_PLAITING_MATERIALS,
        industryKeys.MANUFACTURE_OF_PAPER_AND_PAPER_PRODUCTS,
        industryKeys.PRINTING_AND_REPRODUCTION_OF_RECORDED_MEDIA,
        industryKeys.MANUFACTURE_OF_FURNITURE,
        industryKeys.OTHER_MANUFACTURING,
      ],
    },
    LEATHER_TEXTILE_CLOTHES_INDUSTRY: {
      industryKey: [
        industryKeys.MANUFACTURE_OF_TEXTILES,
        industryKeys.MANUFACTURE_OF_WEARING_APPAREL,
        industryKeys.MANUFACTURE_OF_LEATHER_AND_RELATED_PRODUCTS,
      ],
    },
    FOOD_INDUSTRY_TOBACCO_PRODUCTS: {
      industryKey: [
        industryKeys.MANUFACTURE_OF_FOOD_PRODUCTS,
        industryKeys.MANUFACTURE_OF_BEVERAGES,
        industryKeys.MANUFACTURE_OF_TOBACCO_PRODUCTS,
      ],
    },
    TRANSPORT_AND_STORAGE_AND_NEWS_TRANSMISSION: {
      industryKey: [
        industryKeys.LAND_TRANSPORT_AND_TRANSPORT_VIA_PIPELINES,
        industryKeys.WATER_TRANSPORT,
        industryKeys.AIR_TRANSPORT,
        industryKeys.WAREHOUSING_AND_SUPPORT_ACTIVITIES_FOR_TRANSPORTATION,
        industryKeys.POSTAL_AND_COURIER_ACTIVITIES,
        industryKeys.TRAVEL_AGENCY_TOUR_OPERATOR_AND_OTHER_RESERVATION_SERVICE_AND_RELATED_ACTIVITIES,
      ],
    },
    FINANCIAL_LEASING_COMPANIES: {
      industryKey: [industryKeys.FINANCIAL_LEASING],
    },
    HOUSING_COMPANIES: {
      industryKey: [
        industryKeys.BUYING_AND_SELLING_OF_OWN_RESIDENTIAL_REAL_ESTATE,
        industryKeys.ACTIVITIES_OF_HEAD_OFFICES,
      ],
    },
    OTHER_PROPERTY_MANAGEMENT: {
      industryKey: [
        industryKeys.BUYING_AND_SELLING_OF_OWN_NON_RESIDENTIAL_REAL_ESTATE,
        industryKeys.SERVICES_TO_BUILDINGS_AND_LANDSCAPE_ACTIVITIES,
      ],
    },
    HEALTH_CARE_VETERIN_A_WELFARE_FIRM_FREELANCE: {
      industryKey: [
        industryKeys.VETERINARY_ACTIVITIES,
        industryKeys.HUMAN_HEALTH_ACTIVITIES,
        industryKeys.RESIDENTIAL_CARE_ACTIVITIES,
        industryKeys.SOCIAL_WORK_ACTIVITIES_WITHOUT_ACCOMMODATION,
      ],
    },
    LEASING_OF_MOVABLE_OBJECTS: {
      industryKey: [industryKeys.RENTAL_AND_LEASING_ACTIVITIES],
    },
    NON_PROFIT_ORGANIZATION: {
      industryKey: [industryKeys.PRIVATE_NON_PROFIT_ORGANIZATIONS],
    },
    NON_MARKET_PRODUCERS: {
      industryKey: [industryKeys.PRIVATE_NON_PROFIT_ORGANIZATIONS],
    },
    ORGANIZATION_WITHOUT_ACQUIRING_HOUSE_BUILDING: {
      industryKey: [industryKeys.PRIVATE_NON_PROFIT_ORGANIZATIONS],
    },
  },
};

export const businessDependentFields = {
  tax_country: ['legal_form', 'industry', 'industry_key'],
  legal_form: ['industry', 'industry_key'],
  industry: ['industry_key'],
};

export function getLegalFormOptions(taxCountryValue?: NullableString): string[] {
  if (taxCountryValue !== countries.DE) {
    return [legalForms.FOREIGN_CORPORATION];
  }
  return [
    legalForms.OHG,
    legalForms.LTD,
    legalForms.PARTG,
    legalForms.KG,
    legalForms.UG,
    legalForms.SOLE_PROPRIETORSHIP,
    legalForms.GMBH,
    legalForms.KGAA,
    legalForms.AG,
    legalForms.EG,
    legalForms.EK,
    legalForms.GBR,
    legalForms.GMBH_I_GR,
    legalForms.UG_I_GR,
    legalForms.GMBH_CO_KG,
  ];
}

export function getLegalFormOptionsForForm(taxCountryValue: NullableString, isLegalRepresentative: boolean): string[] {
  return isLegalRepresentative ? [legalForms.GMBH] : getLegalFormOptions(taxCountryValue);
}

export function getIndustryOptions(sectorValue?: NullableString): string[] {
  return sectorValue ? Rulebook.sector[sectorValue].industry : [];
}

export function getSector(taxCountryValue: string, legalForm: string): string {
  if (taxCountryValue !== countries.DE) {
    return sectors.FOREIGN_COMPANIES;
  }

  return Rulebook.legalForm[legalForm] ? Rulebook.legalForm[legalForm] : sectors.OTHER_COMPANIES;
}

export function getIndustryKeyOptions(industryValue?: NullableString): string[] {
  return industryValue ? Rulebook.industry[industryValue].industryKey : [];
}

export const businessesWithoutRegistrationData = [legalForms.GBR, legalForms.GMBH_I_GR, legalForms.UG_I_GR];

export const isBusinessWithoutRegistrationData = (legalForm?: NullableString) => {
  return legalForm ? businessesWithoutRegistrationData.indexOf(legalForms[legalForm]) !== -1 : false;
};

export const hasBusinessAsLegalRep = (legalForm?: NullableString) => {
  return legalForm === legalForms.GMBH_CO_KG;
};

export const legalFormRequiresShareholdersAgreement = (legalForm?: NullableString) => {
  if (!legalForm) {
    return false;
  }
  return [legalForms.GBR, legalForms.UG_I_GR, legalForms.GMBH_I_GR].indexOf(legalForm!) !== -1;
};

export function getCountryOptions(isLegalRepresentative: boolean): string[] {
  return isLegalRepresentative ? [countries.DE] : Enum.values(countries);
}

export function getGermanRegistrationIssuers(): string[] {
  return Enum.values(germanRegistrationIssuers);
}
