// tslint:disable:max-line-length
const info = {
  'info_beneficial_owner.page_title': 'Beneficial Owners',
  'info_beneficial_owner.page_subtitle': 'Legal definition',
  'info_beneficial_owner.section1.title': 'Definition of Ultimate Beneficial Ownership',
  'info_beneficial_owner.section1.p1':
    'The ultimate beneficial owner according to the German Money Laundering Act (Geldwäschegesetz - GwG) is the natural person,',
  'info_beneficial_owner.section1.li1': 'who ultimately owns or controls the contracting party, or ',
  'info_beneficial_owner.section1.li2':
    'on whose behalf a transaction is ultimately carried out or a business relationship is ultimately established.',
  'info_beneficial_owner.section2.title': 'This particularly includes:',
  'info_beneficial_owner.section2.li1':
    'In case of legal persons, foundations without legal capacity and, in the case of other companies, any natural person who directly or indirectly holds more than a 25% share of the capital, controls more than 25% of the voting rights or exercises control in any comparable manner(1). Establishing the identity of the beneficial owner can be waived, though, for companies that are listed in an organised market in the EU with in accordance with Section 2 para. 5 of the Securities Trading Act or, in case of listed companies from a third country, if they are subject to EU-equivalent transparency requirements regarding voting rights or equivalent international standards;',
  'info_beneficial_owner.section2.li2':
    'In case of foundations or other legal arrangements with legal capacity (or similar) used to manage or distribute assets or property as trustee (trust management), or through which third parties are instructed with the management or distribution of assets or property, the ultimate beneficial owner is:',
  'info_beneficial_owner.section2.li2.1':
    'any natural person who is a trustor/settlor, trustee or protector, if applicable ',
  'info_beneficial_owner.section2.li2.2': 'any natural person who is a member of the board of the foundation,',
  'info_beneficial_owner.section2.li2.3': 'any natural person designated as a beneficiary,',
  'info_beneficial_owner.section2.li2.4':
    'the group of natural persons in whose favour the assets are mainly to be administered or distributed, provided that the natural person who is to be the ultimate beneficial owner of the assets or property has not yet been determined,',
  'info_beneficial_owner.section2.li2.5':
    'any natural person who, by any other means, directly or indirectly exercises control over the asset management or property or the distribution of income.',
  'info_beneficial_owner.section2.li3':
    'In the event of acting on behalf of another party, the ultimate beneficial owner includes the party upon whose initiative the transaction is performed. If the contracting party acts as a trustee, he also acts on behalf of another party.',
};

export default info;
