// tslint:disable:max-line-length
const faq = {
  'faq_legal_representative.page_title': 'Legal Representatives',
  'faq_legal_representative.page_subtitle': 'Frequently Asked Questions',
  'faq_legal_representative.section1.title':
    'The company I am about to register is a „Gesellschaft mit beschränkter Haftung“ (GmbH) – who is my legal representative?',
  'faq_legal_representative.section1.p1':
    'The easiest and surest way to figure that out is to check your current register excerpt (Handelsregisterauszug). In there you’ll find all official legal representatives which you would need to tell us.',
  'faq_legal_representative.section1.p2':
    'As further indication, these people also need to be listed in the Imprint of your website (Impressum).',
  'faq_legal_representative.section1.p3':
    'Please make sure that you are submitting only the legal representatives of the entity which becomes the contractual partner. The acting persons might be the same but they would act on behalf of another entity.',
  'faq_legal_representative.section2.title':
    'The Legal Representative has sole representations rights – do I need to add the other legal representatives as well?',
  'faq_legal_representative.section2.p1':
    'Yes indeed! Even though in the next steps this legal representative could conclude the process on behalf of the company alone, we would need the full set of information for regulatory purposes. Please enter the information about all legal representatives – you don’t have to indicate who has the sole representation rights.',
  'faq_legal_representative.section3.title':
    'One of our legal representative is currently unavailable – do we need to reach him/her to complete the process?',
  'faq_legal_representative.section3.p1':
    'No. As long as one of your legal representatives is available and can submit as well as verify the information with the video-identification everything is good. The available person would prove his/her own information for instance with his ID-card or passport.',
  'faq_legal_representative.section5.title':
    'Our legal representatives are about to change soon – shall I include the new ones already?',
  'faq_legal_representative.section5.p1':
    'No, please submit the information as it is written currently in the official register. These are the ones we have to be checked against. If you already know that the persons are about to change, please inform your Onboarding manager accordingly and he/she will help to sort things out.',
  'faq_legal_representative.section6.title': 'Why do I have to submit all legal representatives here?',
  'faq_legal_representative.section6.p1':
    'As a bank we are obliged to keep record about the companies we are working with – as well as checking whether the provided information is correct. We try hard to keep the flow as smooth as possible, nevertheless the information requirements by law entail all legal representatives.',
};

export default faq;
