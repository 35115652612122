import common from './common';
import pages from './pages';
import forms from './forms';
import faqBeneficialOwner from './faqBeneficialOwner';
import faqLegalRepresentative from './faqLegalRepresentative';
import infoBeneficialOwner from './infoBeneficialOwner';

const i18n = {
  ...common,
  ...pages,
  ...forms,
  ...faqBeneficialOwner,
  ...faqLegalRepresentative,
  ...infoBeneficialOwner,
};

export default i18n;
