import { take, fork, select } from 'redux-saga/effects';
import { ON_ENTER, ON_CHANGE, RouterOnEnterAction } from '../lib/route-config-helper';
import { AppState } from '../types';
import { RoutesConfig } from '../routes-config';
import invariant from 'invariant';

/**
 * Effect to handle current policy rule
 */
// export function* getCurrentPolicy() {
//   return yield call(resourceShow, currentPolicyConfig);
// }

function* runSaga(action: RouterOnEnterAction) {
  const { payload } = action;

  const routes: RoutesConfig = yield select((state: AppState) => state.modules.routes);
  if (payload && payload.routeName) {
    invariant(routes[payload.routeName], `No configuration found for ${payload.routeName}.`);
    const saga = routes[payload.routeName].saga;
    if (saga) {
      yield fork(saga, {
        queryParams: payload.queryParams,
        pathParams: payload.pathParams,
      });
    }
  }
}

export function* listenToRouteHooks() {
  while (true) {
    let action: RouterOnEnterAction = yield take([ON_ENTER, ON_CHANGE]);
    yield fork(runSaga, action);
  }
}
