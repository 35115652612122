// tslint:disable:max-line-length
const faq =  {
  'faq_beneficial_owner.page_title': 'Wirtschaftlich Berechtigte',
  'faq_beneficial_owner.page_subtitle': 'Häufig gestellte Fragen',
  'faq_beneficial_owner.section1.title': 'Was ist ein “wirtschaftlich Berechtigter”?',
  'faq_beneficial_owner.section1.p1':
    'Ein “wirtschaftlich Berechtigter” ist eine natürliche Person, die unmittelbar oder mittelbar mehr als 25% der Stimmrechte eines Unternehmens hält. Letztendlich ist es die Person, die von der getroffenen Vereinbarung profitiert und letztendlich die Entscheidungsbefugnis besitzt. Ein wirtschaftlich Berechtigter ist stets eine natürliche Person und kann keine juristische Person sein.',
  'faq_beneficial_owner.section1.p2':
    'Auch hier gibt es eine detailliertere (gesetzlich vorgeschriebene) Definition. Sie kann insbesondere in Fällen helfen, in denen wirtschaftlich Berechtigte Unternehmen sind, die als Trusts oder andere nicht gewerbliche Unternehmenstypen gegründet wurden. ',
  'faq_beneficial_onwer.section1.a': 'Rechtliche Definition | Wirtschaftlich Berechtigter',
  'faq_beneficial_owner.section2.title':
    'Das Unternehmen hat keine wirtschaftlichen Eigentümer - wie soll ich vorgehen?',
  'faq_beneficial_owner.section2.p1':
    'Es könnte nach eingehender Prüfung der Fall sein, dass es niemanden gibt, der unmittelbar oder mittelbar genug Stimmrechtsanteile an dem Unternehmen hat. In diesem Fall sind wir verpflichtet, stattdessen die gesetzlichen Vertreter als wirtschaftlich Berechtigte zu verzeichnen.',
  'faq_beneficial_owner.section2.p2': 'Bitte geben Sie ihre Daten entsprechend ein.',
  'faq_beneficial_owner.section3.title':
    'Ich kenne die Eigentümerstruktur des Unternehmens nicht - wie soll ich vorgehen?',
  'faq_beneficial_owner.section3.p1':
    'Letztendlich wird die Eigentümerstruktur eines Unternehmens in dem Gesellschaftervertrag festgelegt, der zumindest bei der Gründung des Unternehmens unterzeichnet wurde. In der Zwischenzeit kann sich dies geändert haben, müsste aber in einer Änderung oder Aktualisierung dieses Vertrages vermerkt worden sein.',
  'faq_beneficial_owner.section4.title':
    'Das Unternehmen befindet sich im Besitz eines anderen Unternehmens - was soll ich jetzt tun?',
  'faq_beneficial_owner.section4.p1':
    'Bitte geben Sie die Informationen der natürlichen Person an, die der wirtschaftliche Berechtigte des Unternehmens ist, in dessen Besitz sich Ihr Unternehmen befindet. Wenn dies auch eine andere Gesellschaft (Holding- oder Unternehmensstruktur) ist, muss erneut ermittelt werden, wer wirtschaftlich Berechtigter dieses Unternehmens ist, bis Sie entweder eine natürliche Person finden oder die Beteiligung unter 25% (durch indirekte Beteiligung) verwässert wird.',
  'faq_beneficial_owner.section5.title': 'Was bedeutet "unmittelbar oder mittelbar"? Wie kann ich dies ermitteln?',
  'faq_beneficial_owner.section5.p1':
    'Unmittelbare Beteiligung bezieht sich auf eine natürliche Person, die selbst stimmberechtigte Anteile an der Gesellschaft besitzt.',
  'faq_beneficial_owner.section5.p2':
    'Mittelbare Beteiligung bezieht sich auf eine natürliche Person, die eine Gesellschaft besitzt, die wiederum einen bestimmten Unternehmensanteil hält. Eine mittelbare Beteiligung besteht auch, wenn die Gesellschaften über mehrere Ebenen miteinander verbunden sind. Auch wenn eine natürliche Person Stimmrechtsanteile über verschiedene Unternehmen hält, müssten Sie die gesamte mittelbare Beteiligung addieren, um zu ermitteln, ob der Gesamtanteil 25% übersteigt.',
  'faq_beneficial_owner.section5.p3':
    'Wenn eine Beteiligungsstruktur über mehrere Ebenen existiert, multiplizieren Sie die Unternehmensbeteiligung jedes Unternehmens, um die endgültige Quote für die jeweilige Person zu erhalten.',
  'faq_beneficial_owner.section5.p4': 'Beispiel:',
  'faq_beneficial_owner.section5.p5': 'Die Adello GmbH muss ihre wirtschaftlichen Berechtigte angeben.',
  'faq_beneficial_owner.section5.p6':
    'Die Anteilseigner von Adello sind: Peter mit 30% der Anteile, Susi mit 10% der Anteile, Anne mit 10% der Anteile und Toscana GmbH mit 50% der Anteile.',
  'faq_beneficial_owner.section5.p7':
    'Aktionär der Toscana GmbH: Hugo mit 75% der Anteile und Marie mit 25% der Anteile',
  'faq_beneficial_owner.section5.p8': 'Direkte wirtschaftlich Berechtigte von Adello: Peter',
  'faq_beneficial_owner.section5.p9':
    'Indirekte wirtschaftlich Berechtigte von Adello: Hugo mit 37,5% der Anteile (Toscana-Anteil an Adello 50% x Hugo-Anteil an Toscana 75%). Bitte beachten Sie, dass Marie keine wirtschaftlich Berechtigte ist, da sie indirekt nur 12,5% an Adello hält.',
  'faq_beneficial_owner.section6.title':
    'Wir haben verschiedene Arten von Aktien - welche Aktien sind die entscheidenden?',
  'faq_beneficial_owner.section6.p1':
    'Bitte beziehen Sie sich auf die stimmberechtigten Aktien, da der wirtschaftlich Berechtigte letztendlich die Person ist, die maßgeblich über den Kurs der Gesellschaft entscheidet.',
  'faq_beneficial_owner.section7.title':
    'Muss der wirtschaftlich Berechtigte etwas tun, z.B. etwas unterschreiben oder eine Video-Identifikation durchführen?',
  'faq_beneficial_owner.section7.p1':
    'Nein, bitte übermitteln Sie nur die Informationen dieser Person, die in dessen offiziellen Dokumenten (Personalausweis oder Reisepass) angegeben sind. Es gibt keine weitere Überprüfung dieser Informationen. Der wirtschaftlich Berechtigte muss während des Identifizierungsprozesses nichts unternehmen.',
};

export default faq;
