import * as React from 'react';
import { Field, FormProps, reduxForm } from 'redux-form';
import moment from 'moment';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { Row, Col } from 'react-flexbox-grid';
import { BusinessInfoFormProps } from '../../../types';
import FormInputWrap from '../../habitats/form-input';
import Select from '../../atoms/select';
import Input from '../../atoms/textInput';
import FileInput from '../../atoms/file-input';
import Textarea from '../../atoms/textarea';
import DatePicker from '../../atoms/datePicker';
import Collapse from '../../atoms/collapse';
import Remark from '../../atoms/remark';
import Tooltip from '../../atoms/tooltip';
import { requiredValidation, lengthValidation, customLengthValidation } from '../../../lib/validation';
import {
  isBusinessWithoutRegistrationData,
  getIndustryKeyOptions,
  legalFormRequiresShareholdersAgreement,
  getIndustryOptions,
  getSector,
  getLegalFormOptionsForForm,
  getCountryOptions,
  getGermanRegistrationIssuers,
} from '../../../modules/businessInfo/models/business-rulebook';
import * as qs from 'querystringify';

interface InitialValues {
  name?: string;
  tax_country: {
    value: string;
    label: string;
  };
}

interface BusinessInfoPageProps extends FormProps<BusinessInfoFormProps, {}, {}> {
  formValues: BusinessInfoFormProps;
  isLegalRepresentative: boolean;
  legalFormRequiresRegistryInfo?: boolean;
  isTaxCountryDe?: boolean;
  onSubmit: () => void;
}

class BusinessInfoForm extends React.PureComponent<BusinessInfoPageProps & InjectedIntlProps, {}> {
  static defaultProps = {
    formValues: {},
  };

  public render(): JSX.Element {
    const {
      intl: { formatMessage },
      formValues,
      isLegalRepresentative,
      isTaxCountryDe,
      legalFormRequiresRegistryInfo,
    } = this.props;

    const sector =
      formValues.tax_country && formValues.legal_form
        ? getSector(formValues.tax_country.value, formValues.legal_form.value)
        : null;

    const taxCountry = formValues.tax_country ? formValues.tax_country.value : null;
    const legalForm = formValues.legal_form ? formValues.legal_form.value : null;
    return (
      <div>
        <FormInputWrap>
          <Field
            name="name"
            label={formatMessage({ id: 'forms.business_info.business_name' })}
            component={Input}
            type="text"
            validate={[requiredValidation, lengthValidation]}
          />
        </FormInputWrap>
        <FormInputWrap>
          <Field
            name="tax_country"
            id="tax_country"
            label={formatMessage({ id: 'forms.business_info.tax_country' })}
            options={getCountryOptions(isLegalRepresentative).map(value => ({
              value,
              label: formatMessage({ id: `country.${value}` }),
            }))}
            onSelect={null}
            component={Select}
            placeholder={formatMessage({ id: 'forms.select_placeholder' })}
            searchable={false}
            wide={true}
            validate={requiredValidation}
          />
        </FormInputWrap>
        <Row>
          <Col md={6} xs={12}>
            <FormInputWrap>
              <Field
                name="legal_form"
                id="legal_form"
                label={formatMessage({ id: 'forms.business_info.legal_form' })}
                options={getLegalFormOptionsForForm(taxCountry, isLegalRepresentative).map(value => ({
                  value,
                  label: formatMessage({ id: `legal_form.${value}` }),
                }))}
                onSelect={null}
                component={Select}
                placeholder={formatMessage({ id: 'forms.select_placeholder' })}
                searchable={false}
                wide={true}
                validate={requiredValidation}
              />
            </FormInputWrap>
          </Col>
          <Col md={6} xs={12}>
            <FormInputWrap>
              <Field
                name="foundation_date"
                label={formatMessage({
                  id: 'forms.business_info.foundation_date',
                })}
                component={DatePicker}
                showlabel={true}
                maxDate={moment()}
                dateFormat="dd.MM.yyyy"
                placeholder="DD.MM.YYYY*"
                validate={requiredValidation}
              />
              <Tooltip
                tooltip={formatMessage({
                  id: 'forms.business_info.foundation_date_tip',
                })}
              />
            </FormInputWrap>
          </Col>
        </Row>
        <Row>
          <Col md={6} xs={12}>
            <FormInputWrap>
              <Field
                name="industry"
                id="industry"
                label={formatMessage({ id: 'forms.business_info.industry' })}
                options={getIndustryOptions(sector).map(value => ({
                  value,
                  label: formatMessage({ id: `industry.${value}` }),
                }))}
                onSelect={null}
                component={Select}
                placeholder={formatMessage({ id: 'forms.select_placeholder' })}
                searchable={false}
                wide={true}
                validate={requiredValidation}
              />
            </FormInputWrap>
          </Col>
          <Col md={6} xs={12}>
            <FormInputWrap>
              <Field
                name="industry_key"
                id="industry_key"
                label={formatMessage({
                  id: 'forms.business_info.industry_key',
                })}
                options={getIndustryKeyOptions(formValues.industry && formValues.industry!.value).map(value => ({
                  value,
                  label: formatMessage({ id: `industry_key.${value}` }),
                }))}
                onSelect={null}
                component={Select}
                placeholder={formatMessage({ id: 'forms.select_placeholder' })}
                searchable={false}
                wide={true}
                validate={requiredValidation}
              />
            </FormInputWrap>
          </Col>
        </Row>
        <FormInputWrap>
          <Field
            name="website"
            label={formatMessage({ id: 'forms.business_info.website' })}
            component={Input}
            type="text"
          />
        </FormInputWrap>
        {legalFormRequiresRegistryInfo && (
          <FormInputWrap>
            <Field
              name="registration_number"
              label={formatMessage({
                id: 'forms.business_info.registration_number',
              })}
              component={Input}
              type="text"
              validate={
                isBusinessWithoutRegistrationData(legalForm)
                  ? [lengthValidation]
                  : [requiredValidation, lengthValidation]
              }
            />
          </FormInputWrap>
        )}
        {legalFormRequiresRegistryInfo && (
          <FormInputWrap>
            {isTaxCountryDe ? (
              <Field
                name="german_registration_issuer"
                id="german_registration_issuer"
                label={formatMessage({
                  id: 'forms.business_info.registration_issuer',
                })}
                options={getGermanRegistrationIssuers().map(value => ({
                  value,
                  label: value.charAt(0).toUpperCase() + value.slice(1).toLowerCase(),
                }))}
                placeholder={formatMessage({ id: 'forms.select_placeholder' })}
                onSelect={null}
                component={Select}
                searchable={false}
                wide={true}
                validate={requiredValidation}
              />
            ) : (
              <Field
                name="registration_issuer"
                id="registration_issuer"
                label={formatMessage({
                  id: 'forms.business_info.registration_issuer',
                })}
                component={Input}
                type="text"
                validate={
                  isBusinessWithoutRegistrationData(legalForm)
                    ? [customLengthValidation(60)]
                    : [requiredValidation, customLengthValidation(60)]
                }
              />
            )}
          </FormInputWrap>
        )}
        {legalFormRequiresShareholdersAgreement(formValues.legal_form && formValues.legal_form.value) && (
          <FormInputWrap>
            <Field
              name="shareholders_agreement"
              label={formatMessage({
                id: 'forms.business_info.shareholders_agreement',
              })}
              component={FileInput}
              type="file"
              placeholder={formatMessage({
                id: 'forms.business_info.no_file_selected',
              })}
              validate={requiredValidation}
            />
          </FormInputWrap>
        )}
        {!isLegalRepresentative && (
          <FormInputWrap vertical={true}>
            <Collapse
              title={formatMessage({
                id: 'forms.business_info.business_purpose',
              })}
            >
              {formatMessage({
                id: 'forms.business_info.business_purpose_placeholder',
              })}
            </Collapse>
            <Field
              name="business_purpose"
              component={Textarea}
              type="text"
              placeholder={formatMessage({
                id: 'forms.business_info.business_purpose_example',
              })}
              validate={[requiredValidation, customLengthValidation(150)]}
            />
          </FormInputWrap>
        )}
        <Remark>{formatMessage({ id: 'forms.required_fields_note' })}</Remark>
      </div>
    );
  }
}

export function withQueryName(startValues: InitialValues): InitialValues {
  const queryValues: { business_name?: string } = qs.parse(window.location.search);

  const queryName = queryValues.business_name ? { name: queryValues.business_name } : {};

  return { ...queryName, ...startValues };
}

// FIXME This needs to be dynamic (after redux-form update)
function getTranslatedCountryName(): string {
  return navigator.language === 'de' ? 'Deutschland' : 'Germany';
}

const defaultValues: InitialValues = {
  tax_country: { value: 'DE', label: getTranslatedCountryName() },
};
const initialValues = withQueryName(defaultValues);

export default reduxForm({
  destroyOnUnmount: false,
  initialValues,
})(injectIntl(BusinessInfoForm));
