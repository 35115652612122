import { connect } from 'react-redux';
import { submit, formValueSelector, getFormValues } from 'redux-form';
import { BUSINESS_INFO_FORM_NAME } from '../models/business-info';
import { AppState } from '../../../types';
import BusinessInfoPageContainer from '../components/business-info-page';
import {
  selectHasBusinessAsLegalRepresentative,
  selectLegalFormRequiresRegistryInfo,
  selectTaxCountryIsDe,
} from '../selectors';

const formSelector = formValueSelector('aboutYou');
const mapStateToProps = (state: AppState) => ({
  first_name: formSelector(state, 'person.first_name'),
  formValues: getFormValues(BUSINESS_INFO_FORM_NAME)(state),
  hasBusinessAsLegalRep: selectHasBusinessAsLegalRepresentative(state),
  legalFormRequiresRegistryInfo: selectLegalFormRequiresRegistryInfo(state),
  isTaxCountryDe: selectTaxCountryIsDe(state),
});

export const mapDispatchToProps = {
  submit,
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessInfoPageContainer);
