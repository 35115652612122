import { ModuleConfig } from './../../models/module';
import BusinessLegalRepresentativeContainer from './containers/business-legal-representative-container';
import BusinessLegalRepresentativePersonsContainer from './containers/business-legal-representative-persons-container';
import { BUSINESS_LEGAL_REPRESENTATIVE_RESOURCE_NAME } from './models/business-legal-representative';
import { business as businessLegalRepresentative } from './redux/business-legal-representative-reducer';

export const config: ModuleConfig = {
  moduleName: BUSINESS_LEGAL_REPRESENTATIVE_RESOURCE_NAME,
  requires: [],
  redux: {
    reducer: {
      businessLegalRepresentative,
    },
  },
  listenerSagas: [],
  routes: {
    businessLegalRepresentative: {
      path: 'legal-representative/business',
      component: BusinessLegalRepresentativeContainer,
    },
    businessLegalRepresentativePersons: {
      path: 'legal-representative/business/persons',
      component: BusinessLegalRepresentativePersonsContainer,
    },
  },
};
