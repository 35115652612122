import * as React from 'react';
import Button from '../../atoms/button';
import { ReactComponent as SharesIcon } from '../../atoms/icons/beneficialo.svg';
import RadioTabs from '../../atoms/radio-tabs';
import './shareholder-form.css';

interface ShareholderFormProps {
  onConfirmClick: (index: number) => void;
  options: Array<string>;
  active: number;
  title: string;
  button: string;
}

interface ShareholderFormState {
  active: number;
}

class ShareholderForm extends React.Component<ShareholderFormProps, ShareholderFormState> {
  constructor(props: ShareholderFormProps) {
    super();
    this.state = {
      active: props.active,
    };
  }

  onTabSelect = (selected: number) => {
    this.setState({ active: selected });
  };

  onSaveClick = () => {
    this.props.onConfirmClick(this.state.active);
  };

  public render(): JSX.Element {
    const { options, title, button } = this.props;
    const { active } = this.state;

    return (
      <div className="shareholder-form">
        <SharesIcon />
        <h3>{title}</h3>
        <RadioTabs tabs={options} active={active} onClick={this.onTabSelect} />
        <Button variant="primary" handleClick={this.onSaveClick}>
          {button}
        </Button>
      </div>
    );
  }
}

export default ShareholderForm;
