import * as React from 'react';
import './tick-list.css';
import { ReactComponent as TickIcon } from '../../atoms/icons/markok.svg';

export interface TickListProps {
  items: Array<string>;
}

const TickList: React.StatelessComponent<TickListProps> = ({ items }) => {
  return (
    <ul className="tick-list">
      {items.map((item, i) => (
        <li key={i} className="tick-list__item">
          <TickIcon className="tick-list__icon" />
          {item}
        </li>
      ))}
    </ul>
  );
};

export default TickList;
