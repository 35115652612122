import * as React from 'react';

import { ReactComponent as TooltipIcon } from '../icons/qtooltip.svg';

import './tooltip.css';

const ReactTooltip = require('react-tooltip');

interface TooltipProps {
  tooltip: string;
}

class Tooltip extends React.PureComponent<TooltipProps> {
  render() {
    const { tooltip } = this.props;
    return (
      <div className="tooltip">
        <div className="tooltip__pointer" data-tip={tooltip}>
          <TooltipIcon />
        </div>
        <ReactTooltip className="tooltip__info" effect="solid" place="top" type="light" />
      </div>
    );
  }
}

export default Tooltip;
