import * as React from 'react';
import { InjectedIntlProps, injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col, Grid } from 'react-flexbox-grid';
import { ReactComponent as RedoIcon } from '../../atoms/icons/redo.svg';
import Button from '../../atoms/button';
import './footer.css';

interface FooterProps {
  startAgain?: boolean;
}

class Footer extends React.PureComponent<FooterProps & InjectedIntlProps, {}> {
  public render(): JSX.Element {
    const {
      startAgain,
      intl: { formatMessage },
    } = this.props;

    const menu = ['imprint', 'terms', 'privacy_policy'];

    return (
      <div className="footer">
        <Grid fluid={true}>
          <Row middle="md" center="xs">
            <Col md={2}>
              {startAgain && (
                <Button href="/" display="inline">
                  <RedoIcon />
                  <FormattedMessage id="pages.footer.redo" />
                </Button>
              )}
            </Col>
            <Col md={6}>
              © 2020 solarisBank AG
              <ul className="footer-menu">
                {menu &&
                  menu.map(item => (
                    <li className="footer-menu__item" key={item}>
                      <a href={formatMessage({ id: `pages.footer.${item}_url` })} target="_blank" rel="noreferrer">
                        {formatMessage({ id: `pages.footer.${item}` })}
                      </a>
                    </li>
                  ))}
              </ul>
            </Col>
            <Col md={4}>
              <FormattedMessage id="pages.footer.need_help" />
              <a href="mailto:support@solarisbank.com">support@solarisbank.com</a>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default injectIntl(Footer);
