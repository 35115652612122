import * as React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { Row, Col } from 'react-flexbox-grid';
import moment from 'moment';
import { BusinessInfoFormProps } from '../../../types';
import ValueBox from '../../atoms/value-box';

interface BusinessDisplayProps {
  business: BusinessInfoFormProps;
}

class BusinessDisplay extends React.PureComponent<BusinessDisplayProps & InjectedIntlProps, {}> {
  public render(): JSX.Element {
    const {
      intl: { formatMessage },
      business: {
        name,
        foundation_date,
        industry,
        registration_number,
        industry_key,
        registration_issuer,
        german_registration_issuer,
        business_purpose,
      },
    } = this.props;
    return (
      <div>
        <ValueBox
          title={name}
          value={formatMessage(
            { id: 'pages.review.form.founded_on' },
            { date: moment(foundation_date).format('DD.MM.YYYY') },
          )}
          variant="primary"
        />
        <Row>
          <Col sm={6} xs={12}>
            <ValueBox title={formatMessage({ id: 'pages.review.form.industry' })} value={industry!.label} />
            <ValueBox
              title={formatMessage({
                id: 'pages.review.form.registration_number',
              })}
              value={registration_number}
            />
          </Col>
          <Col sm={6} xs={12}>
            <ValueBox title={formatMessage({ id: 'pages.review.form.industry_key' })} value={industry_key!.label} />
            <ValueBox
              title={formatMessage({
                id: 'pages.review.form.registration_issuer',
              })}
              value={(german_registration_issuer && german_registration_issuer.label) || registration_issuer}
            />
          </Col>
        </Row>
        <ValueBox title={formatMessage({ id: 'pages.review.form.business_purpose' })} value={business_purpose} />
      </div>
    );
  }
}

export default injectIntl(BusinessDisplay);
