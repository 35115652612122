import * as React from 'react';
import { Row, Col, Grid } from 'react-flexbox-grid';
import { InjectedIntlProps, injectIntl, FormattedMessage } from 'react-intl';
import { Person } from '../../../../types';
import Page from '../../../../components/page';
import UnloadHandler from '../../../../components/unload-handler';
import Steps from '../../../../patterns/molecules/steps';
import Header from '../../../../patterns/molecules/header';
import LegalRepresentativeForm from '../../../../patterns/molecules/legal-representative-form';
import VerticalDivider from '../../../../patterns/atoms/vertical-divider';
import Sidebar from '../../../../patterns/molecules/sidebar';
import Button from '../../../../patterns/atoms/button';
import { ReactComponent as BackIcon } from '../../../../patterns/atoms/icons/back.svg';
import defaultSteps from '../../../../models/steps';
import SidebarCheckList from '../../../../patterns/atoms/sidebar-check-list';
import { LEGAL_REPRESENTATIVE_FORM } from '../../models/legal-representative';
import { config as businessInfoConfig } from '../../../businessInfo';
import { ReactComponent as LegalRepIcon } from '../../../../patterns/atoms/icons/legalrep.svg';

interface LegalRepresentativePageProps extends InjectedIntlProps {
  submit: Function;
  client: Person;
}

class LegalRepresentativePage extends React.PureComponent<LegalRepresentativePageProps, {}> {
  submitForm = () => {
    this.props.submit(LEGAL_REPRESENTATIVE_FORM);
  };

  public render(): JSX.Element {
    const {
      client,
      intl: { formatMessage },
    } = this.props;
    const previousUrl = businessInfoConfig.routes.businessInfo.path;

    return (
      <Page
        header={
          <Header
            sidebar={
              <Button fullWidth={true} variant="primary" handleClick={this.submitForm}>
                <FormattedMessage id="pages.next_step" />
              </Button>
            }
          >
            <Steps active={2} steps={defaultSteps} />
          </Header>
        }
      >
        <Grid fluid={true}>
          <Row between="xs">
            <Col sm={2}>
              <Button href={previousUrl} display="inline">
                <BackIcon />
                <FormattedMessage id="pages.back" />
              </Button>
            </Col>
            <Col sm={6}>
              <h2>{formatMessage({ id: 'forms.legal_representative.title' }, { name: client.first_name })}</h2>
              <LegalRepresentativeForm client={client} />
            </Col>
            <Col sm={1}>
              <VerticalDivider />
            </Col>
            <Col sm={3}>
              <Sidebar
                title={formatMessage({
                  id: 'pages.legal_representative.title',
                })}
                icon={<LegalRepIcon />}
                content={formatMessage({
                  id: 'pages.legal_representative.content',
                })}
              >
                <SidebarCheckList>
                  <FormattedMessage id="pages.legal_representative.point1" />
                </SidebarCheckList>
                <SidebarCheckList>
                  <FormattedMessage id="pages.legal_representative.point2" />
                </SidebarCheckList>
                <SidebarCheckList>
                  <FormattedMessage id="pages.legal_representative.point3" />
                </SidebarCheckList>
                <SidebarCheckList>
                  <FormattedMessage id="pages.legal_representative.point4" />
                </SidebarCheckList>
                <Button href="info/faq-legal-representatives" display="inline" variant="primary" bold={true}>
                  <FormattedMessage id="pages.legal_representative.legal_representatives_faq" />
                </Button>
              </Sidebar>
            </Col>
          </Row>
        </Grid>
        <UnloadHandler />
      </Page>
    );
  }
}

export default injectIntl(LegalRepresentativePage);
