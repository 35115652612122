import * as React from 'react';
import { Field, WrappedFieldArrayProps, FormSection } from 'redux-form';
import { InjectedIntlProps, injectIntl, FormattedMessage } from 'react-intl';
import { Person, BeneficialOwnerProps } from '../../../../types';
import { Enum } from 'typescript-string-enums';
import { countries } from '../../../../lib/resource-enums';
import Input from '../../../atoms/textInput';
import FormInputWrap from '../../../habitats/form-input';
import HorizontalDivider from '../../../../patterns/atoms/horizontal-divider';
import FormFieldDivider from '../../../atoms/formFieldDivider';
import Select from '../../../atoms/select';
import DatePicker from '../../../atoms/datePicker';
import FieldSide from '../../../atoms/fieldSide';

import AddIcon from '../../../atoms/icons/add';
import PersonIcon from '../../../atoms/icons/person';

import PersonForm from '../../../molecules/person-form';
import PersonFormDisplay from '../../../molecules/person-form-display';
import { randomId } from '../../../../lib/utils';

import { minBirthDate, maxBirthDate } from '../../../../modules/beneficialOnwer/models/beneficial-owner';

import {
  requiredValidation,
  lengthValidation,
  numberValidation,
  greaterThan,
  lessOrEqual,
  dateRangeValidation,
} from '../../../../lib/validation';

interface BeneficialOwnerFormArrayProps extends WrappedFieldArrayProps<BeneficialOwnerProps> {
  persons: Array<Person>;
  noBeneficialOwner: boolean;
}

const beneficialOwners = ({
  fields: { remove, push, map },
  meta: { error, submitFailed },
  persons,
  noBeneficialOwner,
  intl: { formatMessage },
}: BeneficialOwnerFormArrayProps & InjectedIntlProps) => (
  <div>
    {submitFailed && error && <span>{error}</span>}
    {map((person, index, fields) => {
      const referencedId = fields.get(index).referenced;
      return (
        <div key={index}>
          {index > 0 && (
            <FormInputWrap>
              <HorizontalDivider />
            </FormInputWrap>
          )}
          {referencedId && <PersonFormDisplay person={persons[index]} isClient={referencedId === 'client'} />}
          {!referencedId && (
            <div>
              <FormSection name={`${person}.person`}>
                <PersonForm
                  hideEmail={true}
                  side={
                    <FieldSide wide={true} input={true} btn={true} row={true} onClick={() => remove(index)}>
                      <FormattedMessage id="pages.remove" />
                      <PersonIcon variant="default" />
                    </FieldSide>
                  }
                />
              </FormSection>
            </div>
          )}
          {(!referencedId || referencedId !== 'client') && (
            <div>
              <FormInputWrap>
                <Field
                  name={`${person}.address`}
                  label={formatMessage({
                    id: 'forms.beneficial_owner.address',
                  })}
                  component={Input}
                  validate={[requiredValidation, lengthValidation]}
                />
              </FormInputWrap>
              <FormInputWrap>
                <Field
                  name={`${person}.postalCode`}
                  label={formatMessage({
                    id: 'forms.beneficial_owner.postal_code',
                  })}
                  component={Input}
                  validate={[requiredValidation, lengthValidation]}
                />
                <FormFieldDivider />
                <Field
                  name={`${person}.city`}
                  label={formatMessage({ id: 'forms.beneficial_owner.city' })}
                  component={Input}
                  validate={[requiredValidation, lengthValidation]}
                />
              </FormInputWrap>
              <FormInputWrap>
                <Field
                  name={`${person}.country`}
                  label={formatMessage({
                    id: 'forms.beneficial_owner.country',
                  })}
                  options={Enum.values(countries).map(value => ({
                    value,
                    label: formatMessage({ id: `country.${value}` }),
                  }))}
                  onSelect={null}
                  component={Select}
                  placeholder={formatMessage({ id: 'pages.please_select' })}
                  searchable={false}
                  wide={true}
                  validate={requiredValidation}
                />
                <FormFieldDivider />
                <Field
                  name={`${person}.dateOfBirth`}
                  label={formatMessage({
                    id: 'forms.beneficial_owner.date_of_birth',
                  })}
                  component={DatePicker}
                  showlabel={true}
                  dateFormat="dd.MM.yyyy"
                  placeholder="DD.MM.YYYY*"
                  validate={[requiredValidation, dateRangeValidation(minBirthDate, maxBirthDate)]}
                  minDate={minBirthDate}
                  maxDate={maxBirthDate}
                />
              </FormInputWrap>
            </div>
          )}
          {!noBeneficialOwner && (
            <FormInputWrap vertical={true}>
              <Field
                name={`${person}.percentageOfShares`}
                label={formatMessage({
                  id: 'forms.beneficial_owner.percentage_of_shares',
                })}
                component={Input}
                showlabel={true}
                validate={[requiredValidation, numberValidation, greaterThan(25), lessOrEqual(100)]}
              />
              <FormFieldDivider />
              <div className="form__field-placeholder" />
            </FormInputWrap>
          )}
        </div>
      );
    })}
    {!noBeneficialOwner && (
      <FormInputWrap>
        <FieldSide wide={true} row={true} btn={true} onClick={() => push({ id: randomId() })}>
          <FormattedMessage id="forms.beneficial_owner.add_person" />
          <AddIcon />
        </FieldSide>
        <HorizontalDivider />
      </FormInputWrap>
    )}
  </div>
);

export default injectIntl(beneficialOwners);
