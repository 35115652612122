import { connect } from 'react-redux';
import { AppState } from '../../../types';
import WelcomePage from '../components/welcome-page/welcome-page';
import { Action, Dispatch } from 'redux';
import { onLoginSubmitAction } from '../../login/redux/login-actions';

export const mapDispatchToProps = (dispatch: Dispatch<Action<{}>>) => {
  return {
    onLoginSubmit: () => {
      dispatch(onLoginSubmitAction());
    },
  };
};

export const mapStateToProps = (state: AppState) => {
  return {
    errors: state.login.errors,
    loggedIn: state.login.loggedIn,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WelcomePage);
