import * as React from 'react';
import './remark.css';

export interface RemarkProps {}

const Remark: React.StatelessComponent<RemarkProps> = ({ children }) => (
  <div className="remark">
    <span className="remark__text">{children}</span>
  </div>
);

export default Remark;
