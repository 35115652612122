import { connect } from 'react-redux';
import { submit } from 'redux-form';
import BeneficialOwnerContainer from '../components/beneficial-owner';
import { AppState } from '../../../types';
import { showModal } from '../../modals';
import { MODAL_TYPE_SHAREHOLDER } from '../../modalShareholder';
import {
  selectClientPerson,
  selectPersonsArray,
  selectHasShareholder,
  selectIsLegalRepsBeneficialOwner,
  selectHasBusinessAsLegalRepresentative,
  selectBeneficialOwnersIds,
} from '../../../selectors';

const mapStateToProps = (state: AppState) => ({
  client: selectClientPerson(state),
  persons: selectPersonsArray(state),
  beneficialOwnerIds: selectBeneficialOwnersIds(state),
  hasShareholder: selectHasShareholder(state),
  isLegalRepsBeneficialOwner: selectIsLegalRepsBeneficialOwner(state),
  hasBusinessAsLegalRep: selectHasBusinessAsLegalRepresentative(state),
});

export const mapDispatchToProps = {
  submit,
  showShareholderModal: () => showModal(MODAL_TYPE_SHAREHOLDER, {}),
};

export default connect(mapStateToProps, mapDispatchToProps)(BeneficialOwnerContainer);
