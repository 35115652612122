import * as React from 'react';
import Logo from '../../atoms/logo';
import { Row, Col, Grid } from 'react-flexbox-grid';
import './header.css';

interface HeaderProps {
  sidebar?: JSX.Element | string;
}

const Header: React.StatelessComponent<HeaderProps> = props => {
  return (
    <Grid fluid={true}>
      <Row middle="sm" className="header" center="xs">
        <Col sm={3} xs={6}>
          <div className="header__logo">
            <Logo />
          </div>
        </Col>
        <Col sm={6} xs={12}>
          {props.children}
        </Col>
        <Col sm={2}>{props.sidebar && <div>{props.sidebar}</div>}</Col>
      </Row>
    </Grid>
  );
};

export default Header;
