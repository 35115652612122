import * as React from 'react';
import * as classnames from 'classnames';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

import './baseField.css';

interface BaseFieldProps {
  input: WrappedFieldInputProps;
  meta: WrappedFieldMetaProps<{}>;
  active?: boolean;
  showlabel?: boolean;
  className?: string;
  label?: string;
  measure?: string;
  disabled?: boolean;
  hasShadow?: boolean;
  defaultValue?: string;
  short?: boolean;
}

class BaseField extends React.PureComponent<BaseFieldProps> {
  render() {
    const {
      input,
      meta,
      className,
      label,
      measure,
      disabled,
      children,
      hasShadow,
      defaultValue,
      showlabel,
      short,
    } = this.props;
    const { name } = input;

    const { active, error, form, touched, valid, warning, pristine } = meta;

    const id = `${form}-${name}`;
    return (
      <div
        className={classnames(className, 'field', {
          'field--invalid': touched && error,
          'field--valid': touched && valid,
          'field--active': active,
          'field--disabled': disabled,
          'field--pristine': pristine,
          'field--short': short,
        })}
      >
        {label && (
          <label
            className={classnames('field__label', {
              'field__label--hidden': !(input && input.value) && !defaultValue && !showlabel,
            })}
            htmlFor={id}
          >
            {label}
          </label>
        )}

        <div
          className={classnames('field__control', {
            'field__control--has-shadow': hasShadow,
            'field__control--error': touched && error,
          })}
        >
          <div className="field__wrap">{children}</div>
          {measure && <div className="field__measure">{measure}</div>}
        </div>
        {touched && (
          <div className="field__feedback">
            {error && <div className="field__error">{error}</div>}
            {warning && <div className="field__warning">{warning}</div>}
          </div>
        )}
      </div>
    );
  }
}

export default BaseField;
