import { formValueSelector, getFormValues } from 'redux-form';
import { AppState, BeneficialOwnerProps, BeneficialOwnerFormProps } from '../../../types';
import { BENEFICIAL_OWNER_FORM, BENEFICIAL_OWNER_FIELD_ARRAY } from '../models/beneficial-owner';

import { selectPersons } from '../../../modules/personalInfo/selectors';

const beneficialFormSelector = formValueSelector(BENEFICIAL_OWNER_FORM);
const beneficialOwnerFormSelector = getFormValues(BENEFICIAL_OWNER_FORM);

export const selectOtherBeneficialOwners = (state: AppState) => {
  return beneficialFormSelector(state, BENEFICIAL_OWNER_FIELD_ARRAY) || [];
};

export const selectBeneficialOwnersIds = (state: AppState) => {
  return selectOtherBeneficialOwners(state)
    .filter((obo: BeneficialOwnerProps) => obo.referenced)
    .map((obo: BeneficialOwnerProps) => obo.referenced);
};

export const selectDecoratedBeneficialOwners = (state: AppState): BeneficialOwnerFormProps => {
  const persons = selectPersons(state);
  const beneficialOwners: BeneficialOwnerFormProps = beneficialOwnerFormSelector(state) || {
    otherBeneficialOwners: [],
  };

  return {
    ...beneficialOwners,
    otherBeneficialOwners: beneficialOwners.otherBeneficialOwners!.map((bo: BeneficialOwnerProps) =>
      bo.referenced ? { ...bo, person: persons[bo.referenced] } : bo,
    ),
  };
};
