import { formValueSelector } from 'redux-form';
import { AppState, LegalRepresentativeProps, BeneficialOwnerProps } from '../../../types';
import {
  LEGAL_REPRESENTATIVE_FORM,
  LEGAL_REPRESENTATIVE_ARRAY_FIELD,
} from '../../legalRepresentative/models/legal-representative';
import { BENEFICIAL_OWNER_FORM, BENEFICIAL_OWNER_FIELD_ARRAY } from '../../beneficialOnwer/models/beneficial-owner';
import { PersonResponse } from '../models/personal-info';
import { merge } from 'lodash';
import { createPersonObjectWithCustomKey } from '../../../lib/formatters';

const aboutFormSelector = formValueSelector('aboutYou');
const legalFormSelector = formValueSelector(LEGAL_REPRESENTATIVE_FORM);
const beneficialFormSelector = formValueSelector(BENEFICIAL_OWNER_FORM);

export const selectClientPerson = (state: AppState) => {
  return aboutFormSelector(state, 'person') || {};
};

export const selectLegalRepsAsPersons = (state: AppState) => {
  const legalRepsAsPeople: {} = {};
  const legalReps = legalFormSelector(state, LEGAL_REPRESENTATIVE_ARRAY_FIELD) || [];
  legalReps.forEach((legalRep: LegalRepresentativeProps) => {
    if (legalRep.id) {
      const { person } = legalRep;
      Object.assign(legalRepsAsPeople, {
        [legalRep.id]: {
          ...person,
        },
      });
    }
  });
  return legalRepsAsPeople;
};

export const selectBeneficialOwnersAsPersons = (state: AppState) => {
  const beneficialOwnersAsPeople: {} = {};
  const beneficialOwners = beneficialFormSelector(state, BENEFICIAL_OWNER_FIELD_ARRAY) || [];
  beneficialOwners.forEach((beneficialOwner: BeneficialOwnerProps) => {
    if (beneficialOwner.referenced === 'client') {
      // Client is an edge case as most person details will be collected via IDnow
      return;
    }

    const keyOrReferenceKey = beneficialOwner.id || beneficialOwner.referenced;
    if (keyOrReferenceKey) {
      const { person, address, city, country, postalCode, dateOfBirth } = beneficialOwner;
      const personData = person
        ? {
            person,
            dateOfBirth,
            address,
            city,
            country,
            postalCode,
          }
        : { dateOfBirth, address, city, country, postalCode };
      Object.assign(beneficialOwnersAsPeople, createPersonObjectWithCustomKey(keyOrReferenceKey, personData));
    }
  });
  return beneficialOwnersAsPeople;
};

export const selectDeduplicatedPersonsFromEntities = (state: AppState) => {
  const legalRepsAsPeople = selectLegalRepsAsPersons(state);
  const beneficialOwnersAsPeople = selectBeneficialOwnersAsPersons(state);
  const deduplicatedEntities: {} = {};

  Object.keys(beneficialOwnersAsPeople).map(beneficialOwnerId => {
    // prioritze legalRep data when deduplicating as it is an enchanced set of collected legalRep data
    if (legalRepsAsPeople[beneficialOwnerId]) {
      const newObject = merge(beneficialOwnersAsPeople[beneficialOwnerId], legalRepsAsPeople[beneficialOwnerId]);
      return Object.assign(deduplicatedEntities, createPersonObjectWithCustomKey(beneficialOwnerId, newObject));
    }
    return Object.assign(
      deduplicatedEntities,
      createPersonObjectWithCustomKey(beneficialOwnerId, beneficialOwnersAsPeople[beneficialOwnerId]),
    );
  });

  return { ...legalRepsAsPeople, ...deduplicatedEntities };
};

export const selectPersons = (state: AppState) => {
  return {
    client: selectClientPerson(state),
    ...selectDeduplicatedPersonsFromEntities(state),
  };
};

export const selectPersonsArray = (state: AppState) => {
  const persons = selectPersons(state);
  return Object.keys(persons).map((id: string) => ({ id, ...persons[id] }));
};

export const selectLegalRepresentativePersonIds = (state: AppState) => {
  const legalRepresentatives = legalFormSelector(state, LEGAL_REPRESENTATIVE_ARRAY_FIELD) || [];

  return ['client', ...legalRepresentatives.map((lr: LegalRepresentativeProps) => lr.id)];
};

export const selectPersonApiIds = (state: AppState) => {
  const ids = {};
  state.person.forEach((p: PersonResponse) => {
    ids[p.personId] = p.id;
  });
  return ids;
};
