import * as React from 'react';
import { Field, FormProps, FieldArray, reduxForm } from 'redux-form';
import { hashHistory } from 'react-router';
import { InjectedIntlProps, injectIntl, FormattedMessage } from 'react-intl';
import { LegalRepresentativeFormProps, Person } from '../../../types';
import { LEGAL_REPRESENTATIVE_FORM } from '../../../modules/legalRepresentative/models/legal-representative';
import FormInputWrap from '../../habitats/form-input';
import Checkbox from '../../atoms/checkbox';
import Remark from '../../atoms/remark';
import LegalFormArray from './legal-representative-form-array/legal-representative-form-array';
import PersonFormDisplay from '../../molecules/person-form-display';

interface LegalRepresentativeProps extends FormProps<LegalRepresentativeFormProps, {}, {}> {
  client: Person;
}

class LegalRepresentativeForm extends React.PureComponent<LegalRepresentativeProps & InjectedIntlProps, {}> {
  public render(): JSX.Element {
    const {
      client,
      intl: { formatMessage },
    } = this.props;
    return (
      <div>
        <PersonFormDisplay person={client} isClient={true} />
        <FormInputWrap nospace={true}>
          <Field
            name="alsoBeneficialOwner"
            label={formatMessage({
              id: 'forms.legal_representative.me_also_beneficial_owner',
            })}
            component={Checkbox}
          />
        </FormInputWrap>
        <FieldArray name="otherLegalRepresentatives" component={LegalFormArray} />
        <Remark>
          <FormattedMessage id="forms.required_fields_note" />
        </Remark>
      </div>
    );
  }
}

export default reduxForm({
  form: LEGAL_REPRESENTATIVE_FORM,
  destroyOnUnmount: false,
  initialValues: { alsoBeneficialOwner: false },
  onSubmit: () => {
    hashHistory.push('beneficial-owner');
  },
})(injectIntl(LegalRepresentativeForm));
