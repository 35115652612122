import { StandardAction } from '../../../types';

export const UI_SHOW_MODAL_ACTION = 'ui/SHOW_MODAL';
export const UI_HIDE_MODAL_ACTION = 'ui/HIDE_MODAL';

export type ModalActionParams = { type: string; props: {} } & undefined;
export type ModalAction<T> = StandardAction<T>;

export interface ShowModalAction extends ModalAction<{ type: string; props: {} }> {
  readonly type: typeof UI_SHOW_MODAL_ACTION;
}

export function showModal(type: string, props: {}): ShowModalAction {
  return {
    type: UI_SHOW_MODAL_ACTION,
    payload: {
      type,
      props,
    },
  };
}

export interface HideModalAction extends ModalAction<undefined> {
  readonly type: typeof UI_HIDE_MODAL_ACTION;
}

export function hideModal(): HideModalAction {
  return {
    type: UI_HIDE_MODAL_ACTION,
  };
}
