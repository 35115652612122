import { fork } from 'redux-saga/effects';
import { Saga } from 'redux-saga';
import { listenToRouteHooks } from './router-saga';

export default function* root(childSagas?: Saga[]) {
  if (childSagas) {
    for (let key in childSagas) {
      if (childSagas[key]) {
        yield fork(childSagas[key]);
      }
    }
  }

  yield fork(listenToRouteHooks);
}
