import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { AppState } from '../../../types';
import PersonalInfoPageContainer from '../components/personal-info-page';

export const mapStateToProps = (state: AppState) => ({});

export const mapDispatchToProps = {
  submit,
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfoPageContainer);
