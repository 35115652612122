// tslint:disable:max-line-length
const forms = {
  'form.steps.you': 'You',
  'form.steps.your_business': 'Your business',
  'form.steps.legal_representative': 'Legal Representative',
  'form.steps.beneficial_owner': 'Beneficial Owner',
  'form.steps.verification': 'Verification',
  'forms.select_placeholder': '-Please Select-',
  'forms.required_fields_note': 'Fields marked with an asterisk (*) are required',
  'forms.business_info.title': '{name}, Tell us about your business',
  'forms.business_info.business_name': 'Business name*',
  'forms.business_info.legal_form': 'Legal form*',
  'forms.business_info.foundation_date': 'Foundation date*',
  'forms.business_info.foundation_date_tip': 'Please take the signature date of the shareholding agreement',
  'forms.business_info.industry': 'Industry*',
  'forms.business_info.industry_key': 'Industry key*',
  'forms.business_info.tax_country': 'Country of Incorporation*',
  'forms.business_info.registration_number': 'Registration number*',
  'forms.business_info.registration_issuer': 'Registration issuer*',
  'forms.business_info.business_purpose': 'Business purpose*',
  'forms.business_info.business_purpose_placeholder':
    'Under the roof develops, supports, and stewards legal and technical infrastructure that maximizes digital creativity, sharing, and innovation.',
  'forms.business_info.business_purpose_example': 'Describe what the purpose of the business is.',
  'forms.business_info.website': 'Website',
  'forms.business_info.shareholders_agreement': 'Shareholders agreement*',
  'forms.business_info.no_file_selected': 'No file selected',
  'forms.button.login': 'Login',
  'forms.legal_representative.title': '{name}, tell us about legal Representatives of your business',
  'forms.legal_representative.you': 'You',
  'forms.legal_representative.input_title': 'Title*',
  'forms.legal_representative.input_title_placeholder': 'Mr, Mrs...',
  'forms.legal_representative.first_name': 'First name*',
  'forms.legal_representative.last_name': 'Last name*',
  'forms.legal_representative.email_address': 'Email address*',
  'forms.legal_representative.me_also_beneficial_owner': 'I own more than 25% of the business.',
  'forms.legal_representative.also_beneficial_owner': 'This person owns more than 25% of the business.',
  'forms.legal_representative.beneficial_owner_note':
    'A Beneficial Owner is the person who owns more than 25% of the business.',
  'forms.legal_representative.add_person': 'Add person',
  'forms.personal_info.title': 'Tell us about yourself',
  'forms.personal_info.input_title': 'Title*',
  'forms.personal_info.input_title_placeholder': 'Mr, Ms',
  'forms.personal_info.first_name': 'First name*',
  'forms.personal_info.last_name': 'Last name*',
  'forms.personal_info.email_address': 'Email address*',
  'forms.personal_info.mobile_phone': 'Mobile phone number*',
  'forms.personal_info.also_legal_representative': 'I am a legal representative of the business*',
  'forms.personal_info.also_legal_representative_hint':
    'A legal representative is a person acting on behalf of the business and represents it legally.',
  'forms.beneficial_owner.address': 'Address*',
  'forms.beneficial_owner.postal_code': 'Postal code*',
  'forms.beneficial_owner.city': 'City*',
  'forms.beneficial_owner.country': 'Country*',
  'forms.beneficial_owner.date_of_birth': 'Date of birth*',
  'forms.beneficial_owner.percentage_of_shares': 'Percentage of shares*',
  'forms.beneficial_owner.add_person': 'Add Person',
  'forms.email': 'Email',
  'forms.error.login': 'The login failed. Error message: {error}',
  'forms.password': 'Password',
};

export default forms;
