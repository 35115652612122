import { StandardAction, ResourceResponse, ServerErrorResponse, Person } from '../../../types';
import { PersonResponse, PersonNumber } from '../models/personal-info';

export const CREATE_PERSON_ACTION = 'persons/CREATE_PERSON';
export const CREATE_PERSON_SUCCESS_ACTION = 'persons/CREATE_PERSON_SUCCESS';

export const UPDATE_PERSON_NUMBER_ACTION = 'persons/UPDATE_PERSON_NUMBER';
export const UPDATE_PERSON_NUMBER_SUCCESS_ACTION = 'persons/UPDATE_PERSON_SUCCESS_NUMBER';

export const PERSONS_REQUEST_FAILURE_ACTION = 'persons/PERSONS_REQUEST_FAILURE';

export type CreatePersonActionType = StandardAction<Person>;
export type CreatePersonSuccess = StandardAction<ResourceResponse<PersonResponse>>;
export type UpdatePersonNumberActionType = StandardAction<PersonNumber>;
export type UpdatePersonPhoneSuccess = StandardAction<ResourceResponse<PersonNumber>>;
export type PersonFailure = StandardAction<ServerErrorResponse>;

export type PersonsCreateResponse = ResourceResponse<PersonResponse>;

export type PersonsActionParams =
  | CreatePersonSuccess
  | UpdatePersonPhoneSuccess
  | StandardAction<ServerErrorResponse>
  | StandardAction<PersonNumber>;

export function createPerson(resource: Person): CreatePersonActionType {
  return {
    type: CREATE_PERSON_ACTION,
    payload: resource,
  };
}

export type CreatePersonSuccessAction = StandardAction<ResourceResponse<PersonResponse>>;

export function createPersonSuccess(payload: ResourceResponse<PersonResponse>): CreatePersonSuccessAction {
  return {
    type: CREATE_PERSON_SUCCESS_ACTION,
    payload,
  };
}

export function updatePersonNumber(resource: PersonNumber): UpdatePersonNumberActionType {
  return {
    type: UPDATE_PERSON_NUMBER_ACTION,
    payload: resource,
  };
}

export function updatePersonNumberSuccess(resource: PersonNumber): StandardAction<PersonNumber> {
  return {
    type: UPDATE_PERSON_NUMBER_SUCCESS_ACTION,
    payload: resource,
  };
}

export function personsRequestFailure(resource: ServerErrorResponse): StandardAction<ServerErrorResponse> {
  return {
    type: PERSONS_REQUEST_FAILURE_ACTION,
    payload: resource,
  };
}
