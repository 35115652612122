import * as React from 'react';
import { Field, FormProps } from 'redux-form';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { Enum } from 'typescript-string-enums';
import { salutations } from '../../../lib/resource-enums';
import FormInputWrap from '../../habitats/form-input';
import FormFieldDivider from '../../atoms/formFieldDivider';
import Input from '../../atoms/textInput';
import Select from '../../atoms/select';
import { emailValidation, requiredValidation, lengthValidation } from '../../../lib/validation';

interface PersonFormProps extends FormProps<{}, {}, {}> {
  hideEmail?: boolean;
  side?: JSX.Element;
}

class PersonForm extends React.PureComponent<PersonFormProps & InjectedIntlProps, {}> {
  public render(): JSX.Element {
    const {
      intl: { formatMessage },
      hideEmail,
      side,
    } = this.props;

    return (
      <div>
        <FormInputWrap>
          {side}
          <Field
            name="salutation"
            id="salutation"
            label={formatMessage({ id: 'forms.personal_info.input_title' })}
            options={Enum.values(salutations).map(value => ({
              value,
              label: formatMessage({ id: `salutation.${value}` }),
            }))}
            onSelect={null}
            component={Select}
            placeholder={formatMessage({
              id: 'forms.personal_info.input_title_placeholder',
            })}
            searchable={false}
            validate={requiredValidation}
          />
          <FormFieldDivider />
          <Field
            name="first_name"
            label={formatMessage({ id: 'forms.personal_info.first_name' })}
            component={Input}
            type="text"
            validate={[requiredValidation, lengthValidation]}
          />
        </FormInputWrap>
        <FormInputWrap>
          <Field
            name="last_name"
            label={formatMessage({ id: 'forms.personal_info.last_name' })}
            component={Input}
            type="text"
            validate={[requiredValidation, lengthValidation]}
          />
        </FormInputWrap>
        {!hideEmail && (
          <FormInputWrap>
            <Field
              name="email"
              label={formatMessage({ id: 'forms.personal_info.email_address' })}
              component={Input}
              type="email"
              validate={[requiredValidation, emailValidation, lengthValidation]}
            />
          </FormInputWrap>
        )}
      </div>
    );
  }
}

export default injectIntl(PersonForm);
