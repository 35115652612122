import * as React from 'react';
import { Row, Col, Grid } from 'react-flexbox-grid';
import { InjectedIntlProps, injectIntl, FormattedMessage } from 'react-intl';

import { Person } from '../../../../types';
import { BENEFICIAL_OWNER_FORM } from '../../models/beneficial-owner';
import Page from '../../../../components/page';
import UnloadHandler from '../../../../components/unload-handler';
import Steps from '../../../../patterns/molecules/steps';
import Header from '../../../../patterns/molecules/header';
import BeneficialOwnerForm from '../../../../patterns/molecules/beneficial-owner-form';
import VerticalDivider from '../../../../patterns/atoms/vertical-divider';
import Sidebar from '../../../../patterns/molecules/sidebar';
import Button from '../../../../patterns/atoms/button';
import { ReactComponent as BackIcon } from '../../../../patterns/atoms/icons/back.svg';
import defaultSteps from '../../../../models/steps';
import { ReactComponent as ChangeIcon } from '../../../../patterns/atoms/icons/change.svg';
import Alert from '../../../../patterns/atoms/alert';
import SidebarCheckList from '../../../../patterns/atoms/sidebar-check-list';
import { ReactComponent as BeneficialIcon } from '../../../../patterns/atoms/icons/beneficialo.svg';
import { config as legalRepresentativeConfig } from '../../../legalRepresentative';
import { config as businessLegalRepresentativeConfig } from '../../../businessLegalRepresentative';

interface BeneficialOwnerPageProps extends InjectedIntlProps {
  submit: Function;
  client: Person;
  persons: Array<Person>;
  beneficialOwnerIds: Array<string>;
  hasShareholder: boolean;
  isLegalRepsBeneficialOwner: boolean;
  showShareholderModal: () => void;
  hasBusinessAsLegalRep: boolean;
}

class BeneficialOwnerPage extends React.PureComponent<BeneficialOwnerPageProps, {}> {
  submitForm = () => {
    this.props.submit(BENEFICIAL_OWNER_FORM);
  };

  public render(): JSX.Element {
    const {
      client,
      persons,
      intl: { formatMessage },
      hasShareholder,
      isLegalRepsBeneficialOwner,
      beneficialOwnerIds,
      showShareholderModal,
      hasBusinessAsLegalRep,
    } = this.props;

    const previousUrl = hasBusinessAsLegalRep
      ? businessLegalRepresentativeConfig.routes.businessLegalRepresentativePersons.path
      : legalRepresentativeConfig.routes.legalRepresentative.path;

    // TW - 27-03-2020
    // Workaround till we have time to address this.
    const personsWhoAreBeneficialOwners = persons.filter(person => beneficialOwnerIds.some(id => id === person.id));

    return (
      <Page
        header={
          <Header
            sidebar={
              <Button fullWidth={true} variant="primary" handleClick={this.submitForm}>
                <FormattedMessage id="pages.next_step" />
              </Button>
            }
          >
            <Steps active={3} steps={defaultSteps} />
          </Header>
        }
      >
        <Grid fluid={true}>
          <Row between="xs">
            <Col sm={8} xs={12}>
              <Row>
                <Col sm={3} xs={12}>
                  <Button href={previousUrl} display="inline">
                    <BackIcon />
                    <FormattedMessage id="pages.back" />
                  </Button>
                </Col>
                <Col sm={9} xs={12}>
                  <h2>
                    <FormattedMessage
                      id="pages.beneficial_owner.form_title"
                      values={{ first_name: client.first_name }}
                    />
                  </h2>
                </Col>
              </Row>
              {!isLegalRepsBeneficialOwner && (
                <Row>
                  <Col sm={3} xs={12}>
                    <Button display="inline" handleClick={showShareholderModal}>
                      <ChangeIcon />
                      <FormattedMessage id="pages.beneficial_owner.change_answer" />
                    </Button>
                  </Col>
                  <Col sm={9} xs={12}>
                    {hasShareholder && (
                      <p>
                        <FormattedMessage id="pages.beneficial_owner.has_shareholder" />
                      </p>
                    )}
                    {!hasShareholder && (
                      <div>
                        <p>
                          <FormattedMessage id="pages.beneficial_owner.has_no_shareholder" />
                        </p>
                        <Alert>
                          <p>
                            <FormattedMessage id="pages.beneficial_owner.legal_representative_set_as_beneficial_owner" />
                          </p>
                        </Alert>
                      </div>
                    )}
                  </Col>
                </Row>
              )}
              <Row>
                <Col sm={3} xs={12} />
                <Col sm={9} xs={12}>
                  <BeneficialOwnerForm
                    persons={personsWhoAreBeneficialOwners}
                    noBeneficialOwner={!isLegalRepsBeneficialOwner && !hasShareholder}
                  />
                </Col>
              </Row>
            </Col>
            <Col sm={1} xs={12}>
              <VerticalDivider />
            </Col>
            <Col sm={3} xs={12}>
              <Sidebar
                title={formatMessage({
                  id: 'pages.beneficial_owner.sidebar.title',
                })}
                icon={<BeneficialIcon />}
              >
                <p className="sidebar__content">
                  <FormattedMessage id="pages.beneficial_owner.sidebar.content1" />
                </p>
                <p className="sidebar__content">
                  <FormattedMessage id="pages.beneficial_owner.sidebar.content2" />
                </p>
                <SidebarCheckList>
                  <FormattedMessage id="pages.beneficial_owner.sidebar.point1" />
                </SidebarCheckList>
                <SidebarCheckList>
                  <FormattedMessage id="pages.beneficial_owner.sidebar.point2" />
                </SidebarCheckList>
                <SidebarCheckList>
                  <FormattedMessage id="pages.beneficial_owner.sidebar.point3" />
                </SidebarCheckList>
                <SidebarCheckList>
                  <FormattedMessage id="pages.beneficial_owner.sidebar.point4" />
                </SidebarCheckList>
                <div>
                  <Button href="info/beneficial-owner" display="inline" variant="primary" bold={true}>
                    <FormattedMessage id="pages.beneficial_owner.sidebar.read_full_definition" />
                  </Button>
                </div>
                <div>
                  <Button href="info/faq-beneficial-owner" display="inline" variant="primary" bold={true}>
                    <FormattedMessage id="pages.beneficial_owner.sidebar.faq_link" />
                  </Button>
                </div>
              </Sidebar>
            </Col>
          </Row>
        </Grid>
        <UnloadHandler />
      </Page>
    );
  }
}

export default injectIntl(BeneficialOwnerPage);
