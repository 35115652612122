import { MODAL_TYPE_SHAREHOLDER, MODAL_SHAREHOLDER_RESOURCE_NAME } from './models/modalShareholder';
import { shareholder } from './redux/shareholder-reducer';
import { ModuleConfig } from './../../models/module';
import { watchSetShareholderAction } from './sagas/shareholders';

export { MODAL_TYPE_SHAREHOLDER };
export * from './redux/shareholder-actions';

export const config: ModuleConfig = {
  moduleName: MODAL_SHAREHOLDER_RESOURCE_NAME,
  requires: [],
  models: {},
  redux: {
    reducer: {
      shareholder,
    },
  },
  listenerSagas: [watchSetShareholderAction],
  routes: {},
};
