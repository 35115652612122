import { NullableString } from '../types';
import { logSentryException } from './api';
import { saveAs } from 'file-saver';
import { Enum } from 'typescript-string-enums';
import * as Enums from './resource-enums';
import { parse } from 'querystringify';
const flatten = require('flat');

export const parseEnumToForm = (enumName: string) =>
  Enum.values(Enums[enumName]).map(value => ({
    value,
    label: value,
  }));

function safelyParseJSON(json: string) {
  try {
    return JSON.parse(json);
  } catch (e) {
    // Invalid JSON
    logSentryException({
      title: 'invalid JSON',
      detail: `Invalid JSON ${json}`,
    });
    return { 'Invalid JSON data': '' };
  }
}

export function arrayContainsArray(superset: string[], subset: string[]) {
  if (0 === subset.length) {
    return false;
  }
  if (superset.length < subset.length) {
    return false;
  }
  return subset.every((value: string) => {
    return superset.indexOf(value) >= 0;
  });
}

export function getRawData(data: string): { label: string; value?: NullableString }[] {
  let rawData = [];
  const parsedJson = safelyParseJSON(data);
  const flattenData = flatten(parsedJson);

  for (var key in flattenData) {
    if (flattenData.hasOwnProperty(key)) {
      rawData.push({
        label: key,
        value: flattenData[key],
      });
    }
  }
  return rawData;
}

export function downloadBinaryDataAsAttachment(data: string, contentType: string, fileName: string) {
  var file = new File([data], fileName, {
    type: `${contentType};charset=utf-8`,
  });
  saveAs(file);
}

export function decorateList<T extends { id: string }, K>(decoratorParams: {
  listToDecorate: T[];
  resourceList: K[];
  matchFunction: (listToDecorateElement: T, resourceListElement: K) => boolean;
  decoratorFunction: (listToDecorateElement: T, resourceListElement: K) => T;
}) {
  const { listToDecorate, resourceList, matchFunction, decoratorFunction } = decoratorParams;
  return listToDecorate.map(listToDecorateElement => {
    let matchingResourceElement = resourceList.find(resourceListElement =>
      matchFunction(listToDecorateElement, resourceListElement),
    );
    if (matchingResourceElement) {
      return decoratorFunction(listToDecorateElement, matchingResourceElement);
    } else {
      logSentryException({
        code: 'model_not_found',
        status: 404,
        title: 'Model not found',
        detail: `Could not find matching element to decorate element with id: ${listToDecorateElement.id}
                and decorator function: ${decoratorFunction}`,
      });
      return listToDecorateElement;
    }
  });
}

export function decorateElement<T extends { id: string }, K>(decoratorParams: {
  elementToDecorate: T;
  resourceElement: K | undefined;
  decoratorFunction: (elementToDecorate: T, resourceElement: K) => T;
}) {
  const { resourceElement, elementToDecorate, decoratorFunction } = decoratorParams;
  if (resourceElement) {
    return decoratorFunction(elementToDecorate, resourceElement);
  } else {
    logSentryException({
      code: 'model_not_found',
      status: 404,
      title: 'Model not found',
      detail: `Could not find resource to decorate element with id: ${elementToDecorate.id}
              and decorator function: ${decoratorFunction}`,
    });
    return elementToDecorate;
  }
}

export function randomId() {
  return Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substr(2, 10);
}

export function getUrlParams(params: string[]): object {
  return getQueryStringParams(window.location.search, params);
}

export function getQueryStringParams(urlSearch: string, params: string[]): object {
  const queryValues = parse(urlSearch);

  return params.reduce((acc, current) => {
    if (queryValues.hasOwnProperty(current)) {
      acc[current] = queryValues[current];
    }
    return acc;
  }, {});
}

export function parseSalutationToParam(salutation: string): string | null {
  return salutation === Enums.salutations.DIV ? null : salutation;
}
