import * as React from 'react';
import { Field, FormProps, FormSection, reduxForm } from 'redux-form';
import { PersonalInfoFormProps } from '../../../types';
import { hashHistory } from 'react-router';
import { InjectedIntlProps, injectIntl, FormattedMessage } from 'react-intl';
import FormInputWrap from '../../habitats/form-input';
import Input from '../../atoms/textInput';
import Checkbox from '../../atoms/checkbox';
import Remark from '../../atoms/remark';
import Tooltip from '../../atoms/tooltip';
import PersonForm from '../../molecules/person-form';
import { required, mobileValidation, stringLimitValidation } from '../../../lib/validation';

interface PersonalInfoPageProps extends FormProps<PersonalInfoFormProps, {}, {}> {}

const validate = (values: PersonalInfoFormProps) => {
  const errors: PersonalInfoFormProps = {};
  const requiredFields = ['salutation', 'first_name', 'last_name', 'email', 'number', 'isLegalRepresentative'];
  const stringLimit = ['first_name', 'last_name', 'email', 'number'];
  const requireErrors = required(values, requiredFields);
  const stringLimitErrors = stringLimitValidation(values, stringLimit);
  errors.number = mobileValidation(values.number);
  return { ...errors, ...stringLimitErrors, ...requireErrors };
};

class PersonalInfoForm extends React.PureComponent<PersonalInfoPageProps & InjectedIntlProps, {}> {
  public render(): JSX.Element {
    const {
      intl: { formatMessage },
    } = this.props;
    return (
      <div>
        <FormSection name="person">
          <PersonForm />
        </FormSection>
        <FormInputWrap>
          <Field
            name="number"
            label={formatMessage({ id: 'forms.personal_info.mobile_phone' })}
            component={Input}
            type="text"
            placeholder="+49123456789"
          />
        </FormInputWrap>
        <FormInputWrap nospace={true}>
          <Field
            name="isLegalRepresentative"
            label={formatMessage({
              id: 'forms.personal_info.also_legal_representative',
            })}
            component={Checkbox}
          />
          <Tooltip
            tooltip={formatMessage({
              id: 'forms.personal_info.also_legal_representative_hint',
            })}
          />
        </FormInputWrap>
        <Remark>
          <FormattedMessage id="forms.required_fields_note" />
        </Remark>
      </div>
    );
  }
}

export default reduxForm({
  form: 'aboutYou',
  validate,
  destroyOnUnmount: false,
  onSubmit: () => {
    hashHistory.push('business-info');
  },
})(injectIntl(PersonalInfoForm));
