import * as React from 'react';
import * as classnames from 'classnames';
import { Link } from 'react-router';
import './button.css';

type ButtonDisplay = 'default' | 'bordered' | 'inline';
type ButtonVariant = 'default' | 'primary';

export interface ButtonProps {
  children?: React.ReactNode;
  className?: string;
  // tslint:disable-next-line:no-any
  handleClick?: (...args: any[]) => any;
  href?: string;
  fullWidth?: boolean;
  display?: ButtonDisplay;
  variant?: ButtonVariant;
  external?: boolean;
  bold?: boolean;
}

class Button extends React.Component<ButtonProps, {}> {
  static defaultProps = {
    handleClick: () => {
      return null;
    },
    variant: 'default',
    display: 'default',
    external: false,
    fullWidth: false,
  };

  handleClick = (event: React.SyntheticEvent<HTMLElement>): void => {
    if (this.props.handleClick) {
      this.props.handleClick(event, this.props);
    }
  };

  public render(): JSX.Element {
    const { className, variant, fullWidth, href, display, bold, external } = this.props;
    const buttonClassName: string = classnames(className, 'btn', `btn--${variant}`, `btn--${display}`, {
      'btn--fullwidth': fullWidth,
      'btn--bold': bold,
    });

    if (external && href) {
      return (
        <a className={buttonClassName} href={href}>
          {this.props.children}
        </a>
      );
    }

    if (href) {
      return (
        <Link className={buttonClassName} to={href}>
          {this.props.children}
        </Link>
      );
    }

    return (
      <button className={buttonClassName} onClick={this.handleClick}>
        {this.props.children}
      </button>
    );
  }
}

export default Button;
