import { Saga } from 'redux-saga';
import { RoutesConfig } from './routes-config';
import { ModuleConfig } from './models/module';
import { config as welcomeModuleConfig } from './modules/welcome';
import { config as personalInfoConfig } from './modules/personalInfo';
import { config as businessInfoConfig } from './modules/businessInfo';
import { config as legalRepresentativeConfig } from './modules/legalRepresentative';
import { config as loginConfig } from './modules/login';
import { config as formModuleConfig } from './modules/form';
import { config as infoModuleConfig } from './modules/info';
import { config as reviewModuleConfig } from './modules/review';
import { config as beneficialOnwerConfig } from './modules/beneficialOnwer';
import { config as modalsConfig } from './modules/modals';
import { config as modalShareholderConfig } from './modules/modalShareholder';
import { config as identificationConfig } from './modules/identification';
import { config as businessIdentificationConfig } from './modules/businessIdentification';
import { config as preloaderConfig } from './modules/preloader';
import { config as businessLegalRepresentativeConfig } from './modules/businessLegalRepresentative';
import { config as businessDocumentConfig } from './modules/businessDocument';

import { Dictionary } from './types';

export { defaultHomeRoute } from './routes-config';

export const modules: Dictionary<ModuleConfig> = {
  [welcomeModuleConfig.moduleName]: welcomeModuleConfig,
  [personalInfoConfig.moduleName]: personalInfoConfig,
  [legalRepresentativeConfig.moduleName]: legalRepresentativeConfig,
  [loginConfig.moduleName]: loginConfig,
  [businessInfoConfig.moduleName]: businessInfoConfig,
  [formModuleConfig.moduleName]: formModuleConfig,
  [infoModuleConfig.moduleName]: infoModuleConfig,
  [reviewModuleConfig.moduleName]: reviewModuleConfig,
  [beneficialOnwerConfig.moduleName]: beneficialOnwerConfig,
  [modalsConfig.moduleName]: modalsConfig,
  [modalShareholderConfig.moduleName]: modalShareholderConfig,
  [identificationConfig.moduleName]: identificationConfig,
  [businessIdentificationConfig.moduleName]: businessIdentificationConfig,
  [preloaderConfig.moduleName]: preloaderConfig,
  [businessLegalRepresentativeConfig.moduleName]: businessLegalRepresentativeConfig,
  [businessDocumentConfig.moduleName]: businessDocumentConfig,
};

export function getRootSagas(): Saga[] {
  let sagas: Saga[] = [];
  for (let key in modules) {
    if (modules[key]) {
      sagas = sagas.concat(modules[key].listenerSagas);
    }
  }
  return sagas;
}

export function getReducers(): Dictionary<{}> {
  let reducers = {};
  for (let key in modules) {
    if (modules[key]) {
      let reducer;

      if (modules[key].redux.reducer) {
        reducer = modules[key].redux.reducer;
      }

      reducers = {
        ...reducers,
        ...reducer,
      };
    }
  }
  return reducers;
}

export function getRoutes(): RoutesConfig {
  let routes: RoutesConfig = {};
  for (let key in modules) {
    if (modules[key]) {
      routes = {
        ...routes,
        ...modules[key].routes,
      };
    }
  }
  return {
    ...routes,
  };
}
