import * as React from 'react';
import { Field, WrappedFieldArrayProps, FormSection } from 'redux-form';
import { InjectedIntlProps, injectIntl, FormattedMessage } from 'react-intl';
import FormInputWrap from '../../../habitats/form-input';
import HorizontalDivider from '../../../../patterns/atoms/horizontal-divider';
import PersonForm from '../../../molecules/person-form';
import Checkbox from '../../../atoms/checkbox';
import FieldSide from '../../../atoms/fieldSide';
import AddIcon from '../../../atoms/icons/add';
import PersonIcon from '../../../atoms/icons/person';
import { randomId } from '../../../../lib/utils';

const renderLegalRepresentatives = ({
  fields: { remove, push, map },
  meta: { error, submitFailed },
  intl: { formatMessage },
}: WrappedFieldArrayProps<{}> & InjectedIntlProps) => (
  <div>
    {map((person, index) => (
      <div key={index}>
        <FormInputWrap>
          <HorizontalDivider />
        </FormInputWrap>
        <FormSection name={`${person}.person`}>
          <PersonForm
            side={
              <FieldSide wide={true} row={true} input={true} onClick={() => remove(index)}>
                <FormattedMessage id="pages.remove" />
                <PersonIcon variant="default" />
              </FieldSide>
            }
          />
        </FormSection>
        <FormInputWrap nospace={true}>
          <Field
            name={`${person}.alsoBeneficialOwner`}
            label={formatMessage({
              id: 'forms.legal_representative.also_beneficial_owner',
            })}
            component={Checkbox}
          />
        </FormInputWrap>
      </div>
    ))}
    <FormInputWrap>
      <FieldSide wide={true} row={true} onClick={() => push({ id: randomId() })}>
        <FormattedMessage id="forms.legal_representative.add_person" />
        <AddIcon />
      </FieldSide>
      <HorizontalDivider />
    </FormInputWrap>
  </div>
);

export default injectIntl(renderLegalRepresentatives);
