import { call, put, select } from 'redux-saga/effects';
import { LegalRepresentativeCreateResponse } from '../redux/legal-representative-actions';
import { createLegalRepresentativeSuccess, createLegalRepresentativeFailure } from '../redux/actions-index';
import legalRepresentativeConfig from '../models/legal-representative';
import { resourceCreate } from '../../../sagas/resource-requests';
import {
  selectLegalRepresentativePersonIds,
  selectPersonApiIds,
  selectBusinessId,
  selectBusinessLegalRepresentativeId,
} from '../../../selectors';

export function* createLegalRepresentative(businessId: string, legalRepresentativeId: string) {
  const legalRepresentativeResponse: LegalRepresentativeCreateResponse = yield call(
    resourceCreate,
    legalRepresentativeConfig,
    {
      pathParams: {
        id: businessId,
      },
    },
    {
      legal_representative_id: legalRepresentativeId,
      type_of_representation: 'ALONE',
    },
  );

  if (legalRepresentativeResponse && legalRepresentativeResponse.data) {
    yield put(createLegalRepresentativeSuccess(legalRepresentativeResponse));
  } else if (legalRepresentativeResponse.error) {
    const { apiErrors } = legalRepresentativeResponse.error;
    const notUniqueErrorDetail =
      'Could not save ‘LegalRepresentative’. ‘person name’ must be unique for ‘LegalRepresentative’.';

    if (apiErrors) {
      const notUniqueError = apiErrors.find(error => !!error.detail && error.detail.includes(notUniqueErrorDetail));
      if (notUniqueError) {
        return legalRepresentativeResponse;
      }
    } else {
      yield put(createLegalRepresentativeFailure(legalRepresentativeResponse.error));
      throw new Error('Legal representative creation error');
    }
  }
  return legalRepresentativeResponse;
}

export function* createLegalRepresentativesFromForm() {
  const legalPersonIds = yield select(selectLegalRepresentativePersonIds);
  const businessId = yield select(selectBusinessId);
  const businessLegalRepId = yield select(selectBusinessLegalRepresentativeId);
  const apiIds = yield select(selectPersonApiIds);

  const legalPersonApiIds = legalPersonIds.map((id: string) => apiIds[id]);

  return yield* [
    ...legalPersonApiIds.map((id: string) => call(createLegalRepresentative, businessId, id)),
    ...(businessLegalRepId
      ? legalPersonApiIds.map((id: string) => call(createLegalRepresentative, businessLegalRepId, id))
      : []),
  ];
}
