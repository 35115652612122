import { StandardAction, ResourceResponse, ServerErrorResponse, BusinessInfoFormProps } from '../../../types';
import { Business } from '../../businessInfo/models/business-info';

export const CREATE_BUSINESS_LR_ACTION = 'business/CREATE_BUSINESS_LR_ACTION';
export const CREATE_BUSINESS_LR_SUCCESS_ACTION = 'business/CREATE_BUSINESS_LR_SUCCESS_ACTION';

export const BUSINESS_LR_CREATE_FAILURE_ACTION = 'business/BUSINESS_LR_CREATE_FAILURE_ACTION';

export type CreateBusinessLegalRepActionType = StandardAction<BusinessInfoFormProps>;
export type CreateBusinessLegalRepSuccess = StandardAction<ResourceResponse<Business>>;
export type BusinessLegalRepFailure = StandardAction<ServerErrorResponse>;

export type BusinessLegalRepCreateResponse = ResourceResponse<Business>;

export type BusinessLegalRepActionParams = CreateBusinessLegalRepSuccess | StandardAction<ServerErrorResponse>;

export function createBusinessLegalRep(payload: BusinessInfoFormProps): CreateBusinessLegalRepActionType {
  return {
    type: CREATE_BUSINESS_LR_ACTION,
    payload,
  };
}

export function createBusinessLegalRepSuccess(
  payload: ResourceResponse<Business>,
): StandardAction<BusinessLegalRepCreateResponse> {
  return {
    type: CREATE_BUSINESS_LR_SUCCESS_ACTION,
    payload,
  };
}

export function businessCreateLegalRepRequestFailure(
  resource: ServerErrorResponse,
): StandardAction<ServerErrorResponse> {
  return {
    type: BUSINESS_LR_CREATE_FAILURE_ACTION,
    payload: resource,
  };
}
