import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import * as reducers from './redux/reducers';
import RoutesContainer from './containers/routes-container';
import root from './sagas/index';
import * as Raven from 'raven-js';
import { modules, getRoutes, getRootSagas, getReducers, defaultHomeRoute } from './config';
import { setRoutes } from './redux/module/module-actions';
import LanguageWrapper from './components/language-wrapper/language-wrapper';

Raven.config(process.env.SENTRY_DSN).install();

// We're doing this to avoid typescript complaining about injecting the path on window from html.
// This might not be the perfect permanent solution.
declare global {
  interface Window {
    language?: string;
  }
}

const sagaMiddleware = createSagaMiddleware();
const applicationReducers = {
  ...reducers,
  ...getReducers(),
};
// setup redux reducer
// tslint:disable-next-line:no-string-literal
const composeEnhancer = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] || compose;
const reducer = combineReducers(applicationReducers);
export const store = createStore(reducer, composeEnhancer(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(root, getRootSagas());

const allRoutes = getRoutes();
store.dispatch(
  setRoutes({
    modules: modules,
    routes: allRoutes,
  }),
);

ReactDOM.render(
  <Provider store={store}>
    <LanguageWrapper>
      <RoutesContainer routes={allRoutes} defaultHomeRoute={defaultHomeRoute} />
    </LanguageWrapper>
  </Provider>,
  document.getElementById('root') as HTMLElement,
);
