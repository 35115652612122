import { RouteComponent } from 'react-router';
import { RouterHookParams } from './types';

export interface RouteConfig {
  component: RouteComponent;
  path: string;
  saga?: (params: RouterHookParams) => void;
}

export interface RoutesConfig {
  [key: string]: RouteConfig;
}

export const defaultHomeRoute: string = 'welcome';
