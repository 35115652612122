import * as React from 'react';
import AppHabitat from '../../patterns/habitats/app';
import ModalContainer from '../../modules/modals/containers/modal-container';

export interface AppProps {}

class App extends React.Component<AppProps, {}> {
  public render(): JSX.Element {
    const { children } = this.props;
    return (
      <AppHabitat>
        {children}
        <ModalContainer />
      </AppHabitat>
    );
  }
}

export default App;
