import * as React from 'react';
import { InjectedIntlProps, injectIntl, FormattedMessage } from 'react-intl';

import FaqPage from '../../../../components/faq-page';
import UnloadHandler from '../../../../components/unload-handler';
import DecoratedHeader from '../../../../patterns/atoms/decorated-header';
import Button from '../../../../patterns/atoms/button';

interface InfoFaqBeneficialOwnerProps extends InjectedIntlProps {}

class InfoFaqBeneficialOwner extends React.PureComponent<InfoFaqBeneficialOwnerProps, {}> {
  public render(): JSX.Element {
    const { formatMessage } = this.props.intl;
    return (
      <FaqPage
        title={formatMessage({ id: 'faq_beneficial_owner.page_title' })}
        subtitle={formatMessage({ id: 'faq_beneficial_owner.page_subtitle' })}
      >
        <DecoratedHeader level="h3">
          <FormattedMessage id="faq_beneficial_owner.section1.title" />
        </DecoratedHeader>
        <p>
          <FormattedMessage id="faq_beneficial_owner.section1.p1" />
        </p>
        <p>
          <FormattedMessage id="faq_beneficial_owner.section1.p2" />
          <Button href="info/beneficial-owner" display="inline" variant="default" bold={true}>
            <FormattedMessage id="faq_beneficial_onwer.section1.a" />
          </Button>
        </p>
        <DecoratedHeader level="h3">
          <FormattedMessage id="faq_beneficial_owner.section2.title" />
        </DecoratedHeader>
        <p>
          <FormattedMessage id="faq_beneficial_owner.section2.p1" />
        </p>
        <p>
          <FormattedMessage id="faq_beneficial_owner.section2.p2" />
        </p>
        <DecoratedHeader level="h3">
          <FormattedMessage id="faq_beneficial_owner.section3.title" />
        </DecoratedHeader>
        <p>
          <FormattedMessage id="faq_beneficial_owner.section3.p1" />
        </p>
        <DecoratedHeader level="h3">
          <FormattedMessage id="faq_beneficial_owner.section4.title" />
        </DecoratedHeader>
        <p>
          <FormattedMessage id="faq_beneficial_owner.section4.p1" />
        </p>
        <DecoratedHeader level="h3">
          <FormattedMessage id="faq_beneficial_owner.section5.title" />
        </DecoratedHeader>
        <p>
          <FormattedMessage id="faq_beneficial_owner.section5.p1" />
        </p>
        <p>
          <FormattedMessage id="faq_beneficial_owner.section5.p2" />
        </p>
        <p>
          <FormattedMessage id="faq_beneficial_owner.section5.p3" />
        </p>
        <p>
          <strong>
            <FormattedMessage id="faq_beneficial_owner.section5.p4" />
          </strong>
        </p>
        <p>
          <FormattedMessage id="faq_beneficial_owner.section5.p5" />
        </p>
        <p>
          <FormattedMessage id="faq_beneficial_owner.section5.p6" />
        </p>
        <p>
          <FormattedMessage id="faq_beneficial_owner.section5.p7" />
        </p>
        <p>
          <FormattedMessage id="faq_beneficial_owner.section5.p8" />
        </p>
        <p>
          <FormattedMessage id="faq_beneficial_owner.section5.p9" />
        </p>
        <DecoratedHeader level="h3">
          <FormattedMessage id="faq_beneficial_owner.section6.title" />
        </DecoratedHeader>
        <p>
          <FormattedMessage id="faq_beneficial_owner.section6.p1" />
        </p>
        <DecoratedHeader level="h3">
          <FormattedMessage id="faq_beneficial_owner.section7.title" />
        </DecoratedHeader>
        <p>
          <FormattedMessage id="faq_beneficial_owner.section7.p1" />
        </p>
        <UnloadHandler />
      </FaqPage>
    );
  }
}

export default injectIntl(InfoFaqBeneficialOwner);
