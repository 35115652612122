import * as React from 'react';
import './overlay.css';

export interface OverlayProps {
  onClick?: () => void;
}

const Overlay: React.StatelessComponent<OverlayProps> = ({ onClick }) => <div className="overlay" onClick={onClick} />;

export default Overlay;
