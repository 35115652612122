import * as React from 'react';
import * as classnames from 'classnames';
import './value-box.css';

type ValueBoxVariant = 'default' | 'primary';

export interface ValueBoxProps {
  title?: string;
  value?: string;
  variant?: ValueBoxVariant;
}

const ValueBox: React.StatelessComponent<ValueBoxProps> = ({ title, value, variant }) =>
  value ? (
    <div className={classnames('value-box', `value-box--${variant}`)}>
      <div className="value-box__title">{title}</div>
      <div className="value-box__value">{value}</div>
    </div>
  ) : null;

ValueBox.defaultProps = {
  variant: 'default',
};

export default ValueBox;
