import * as React from 'react';
import { InjectedIntlProps, injectIntl, FormattedMessage } from 'react-intl';

import FaqPage from '../../../../components/faq-page';
import UnloadHandler from '../../../../components/unload-handler';

interface InfoBeneficialOwnerProps extends InjectedIntlProps {}

class InfoBeneficialOwner extends React.PureComponent<InfoBeneficialOwnerProps, {}> {
  public render(): JSX.Element {
    const { formatMessage } = this.props.intl;
    return (
      <FaqPage
        title={formatMessage({ id: 'info_beneficial_owner.page_title' })}
        subtitle={formatMessage({ id: 'info_beneficial_owner.page_subtitle' })}
      >
        <p>
          <strong>
            <FormattedMessage id="info_beneficial_owner.section1.title" />
          </strong>
        </p>
        <p>
          <FormattedMessage id="info_beneficial_owner.section1.p1" />
        </p>
        <ul>
          <li>
            <FormattedMessage id="info_beneficial_owner.section1.li1" />
          </li>
          <li>
            <FormattedMessage id="info_beneficial_owner.section1.li2" />
          </li>
        </ul>
        <p>
          <strong>
            <FormattedMessage id="info_beneficial_owner.section2.title" />
          </strong>
        </p>
        <ul>
          <li>
            <FormattedMessage id="info_beneficial_owner.section2.li1" />
          </li>
          <li>
            <FormattedMessage id="info_beneficial_owner.section2.li2" />
            <ul>
              <li>
                <FormattedMessage id="info_beneficial_owner.section2.li2.1" />
              </li>
              <li>
                <FormattedMessage id="info_beneficial_owner.section2.li2.2" />
              </li>
              <li>
                <FormattedMessage id="info_beneficial_owner.section2.li2.3" />
              </li>
              <li>
                <FormattedMessage id="info_beneficial_owner.section2.li2.4" />
              </li>
              <li>
                <FormattedMessage id="info_beneficial_owner.section2.li2.5" />
              </li>
            </ul>
          </li>
          <li>
            <FormattedMessage id="info_beneficial_owner.section2.li3" />
          </li>
        </ul>
        <UnloadHandler />
      </FaqPage>
    );
  }
}

export default injectIntl(InfoBeneficialOwner);
