import {
  CREATE_BENEFICIAL_OWNER_ACTION,
  CREATE_BENEFICIAL_OWNER_FAILURE_ACTION,
  CREATE_BENEFICIAL_OWNER_SUCCESS_ACTION,
  CreateBeneficialOwnerSuccess,
  BeneficialOwnerParams,
} from './beneficial-owner-actions';

export const BeneficialOwnerDefaultState = {
  beneficialOwners: [],
};

export const BeneficialOwner = (state = BeneficialOwnerDefaultState, action: BeneficialOwnerParams) => {
  if (!action.payload) {
    return state;
  }
  switch (action.type) {
    case CREATE_BENEFICIAL_OWNER_ACTION:
      return state;
    case CREATE_BENEFICIAL_OWNER_SUCCESS_ACTION:
      action = action as CreateBeneficialOwnerSuccess;
      if (!action.payload || !action.payload.data) {
        return state;
      }

      return {
        ...state,
        beneficialOwners: [...state.beneficialOwners, action.payload.data],
      };
    case CREATE_BENEFICIAL_OWNER_FAILURE_ACTION:
      return {
        ...state,
        errors: action.payload,
      };
    default:
      return state;
  }
};
