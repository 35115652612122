import { ModuleConfig } from './../../models/module';
import WelcomePageContainer from './containers/welcome-page-container';
import { WELCOME_RESOURCE_NAME } from './models/welcome';

export const config: ModuleConfig = {
  moduleName: WELCOME_RESOURCE_NAME,
  requires: [],
  redux: {},
  listenerSagas: [],
  routes: {
    welcome: {
      path: 'welcome',
      component: WelcomePageContainer,
    },
  },
};
