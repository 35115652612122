import { PersonsActionParams, CreatePersonSuccess } from './persons-actions';
import { CREATE_PERSON_SUCCESS_ACTION, PERSONS_REQUEST_FAILURE_ACTION } from '../redux/persons-actions';

export const personsDefaultState = [];

export const person = (state = personsDefaultState, action: PersonsActionParams) => {
  if (!action.payload) {
    return state;
  }
  switch (action.type) {
    case CREATE_PERSON_SUCCESS_ACTION:
      action = action as CreatePersonSuccess;
      if (!action.payload || !action.payload.data) {
        return state;
      }

      return [...state, { ...action.payload.data }];
    case PERSONS_REQUEST_FAILURE_ACTION:
      return {
        errors: action.payload,
      };
    default:
      return state;
  }
};
